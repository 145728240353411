import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';

export const LOG_URL = 'qrcodelogs';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  constructor(private http: HttpClient) {}

  getLogsByFilter(filter: any) {
    return this.http.get<any>(`${environment.apiRoot}${LOG_URL}`, { params: filter }).pipe(
      tap(res => {
        if (res.groupBy) {
          res.list
            .sort((a, b) => {
              if (a._id < b._id) {
                return -1;
              }
              if (a._id > b._id) {
                return 1;
              }
              return 0;
            })
            .forEach(item => {
              item.key = item._id;
            });
        }
      })
    );
  }
}
