import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { ConsultComponent } from './consult/consult.component';
import { DispensationRoutingModule } from './dispensation-routing.module';
import { RegisterComponent } from './register/register.component';
import { ConsultNotFound } from './consultnotfound/consult-not-found.component';
import { ConsultEmpty } from './consultempty/consult-empty.component';

@NgModule({
  declarations: [ConsultComponent, RegisterComponent, ConsultNotFound, ConsultEmpty],
  imports: [
    CommonModule,
    SharedModule,
    DispensationRoutingModule,
    NgxMaskModule.forRoot(),
    NgxLoadingModule.forRoot({})
  ]
})
export class DispensationModule {}
