import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cid } from '@app/shared/models/cid';
import { environment } from '@env/environment';

export const CID_API_URL = `${environment.apiRoot}cid`;

@Injectable({
  providedIn: 'root'
})
export class CidService {
  constructor(private http: HttpClient) {}

  findAllByQuery(query: string) {
    return this.http.get<Cid[]>(`${CID_API_URL}?query=${query}`);
  }
}
