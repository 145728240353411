import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formattedMapaSipeagro'
})
export class FormattedMapaSipeagroPipe implements PipeTransform {
  transform(value: string): string {
    return value && value.replace(/(\d{5})(\d{4})/g, '$1/$2');
  }
}
