import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnInit
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { ColorSetting } from '@app/shared/models/color-setting';

@Component({
  selector: 'app-auto-width-input-number',
  templateUrl: './auto-width-input-number.component.html',
  styleUrls: ['./auto-width-input-number.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: AutoWidthInputNumberComponent, multi: true }]
})
export class AutoWidthInputNumberComponent implements AfterViewInit, ControlValueAccessor {
  @Input() id: string;
  @Input() min = 1;
  @Input() max: number;
  @Input() step = 1;
  @Input() label = 'Quantidade';
  @Input() disabled: boolean;
  @Input() colorSetting: ColorSetting;

  mobile = false;
  form: FormGroup;

  constructor(
    @Inject(DOCUMENT) private document,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      quantity: [null]
    });
    this.getScreenSize();
  }

  _inputSize: number;

  get inputSize(): number {
    return this._inputSize;
  }

  set inputSize($event) {
    this._inputSize = 26 + parseFloat(this.calculateTextWidth($event.toString()));
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  private getScreenSize(event?) {
    const { innerWidth: width, innerHeight: height } = window;
    this.mobile = width < 1025;
  }

  calculateTextWidth(value: string) {
    const canvas = this.document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    return ctx.measureText(value).width;
  }

  @HostListener('input', ['$event.target'])
  public onInput($event): void {
    this.inputSize = $event.value;
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(value => fn(value.quantity));
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {}

  writeValue(value: any): void {
    if (value) {
      this.form.setValue({ quantity: value });
    }
  }

  minus() {
    const qty = this.form.get('quantity');
    if (qty.value > this.min) {
      qty.setValue(qty.value - 1);
    }
  }

  plus() {
    const qty = this.form.get('quantity');
    if (this.max && qty.value === this.max) {
      return;
    }
    qty.setValue(qty.value + 1);
  }
}
