import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DocumentModule } from '@app/modules/document/document.module';
import { SharedModule } from '@app/shared';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { FormFilterComponent } from './form-filter/form-filter.component';
import { HistoricRoutingModule } from './historic-routing.module';
import { HistoricComponent } from './historic.component';
import { ModalCancelRetentionComponent } from './modal-cancel-retention/modal-cancel-retention.component';

@NgModule({
  declarations: [HistoricComponent, FormFilterComponent, ModalCancelRetentionComponent],
  exports: [HistoricComponent, ModalCancelRetentionComponent],
  imports: [
    CommonModule,
    HistoricRoutingModule,
    SharedModule,
    NgxLoadingModule.forRoot({}),
    NgxMaskModule.forRoot(),
    DocumentModule
  ]
})
export class HistoricModule {}
