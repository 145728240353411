import { Theme } from './symbols';

export const themeReceitaDigital: Theme = {
  title: 'Receita Digital',
  name: 'theme-receita-digital',
  favicon: 'assets/icons/favicon.ico',
  properties: {
    '--color-white': '#FFFFFF',
    '--color-black': '#000000',
    '--color-alert': '#e94a3c',
    '--color-icon-menu': '#0B6054',
    '--color-title': '#1b403a',
    '--color-hover-item-menu': '#5ce1c2',
    '--bg-color-primary': '#0B6054',
    '--on-bg-color-primary': '#FFFFFF',
    '--bg-color-secondary': '#17C6AC',
    '--on-bg-color-secondary': '#0B6054',
    '--bg-color-cinza-escuro': '#494949',
    '--on-bg-color-cinza-escuro': '#FFFFFF',
    '--bg-color-cinza-intermediario': '#797979',
    '--on-bg-color-cinza-intermediario': '#FFFFFF',
    '--bg-color-cinza-claro': '#EBEBEB',
    '--on-bg-color-cinza-claro': '#4B4945',
    '--bg-footer': '#0B6054',

    '--background-header': '#17C6AC',
    '--on-background-header': '#0B6054',
    '--background-section': '#0B6054',
    '--background-card-prescricao': '#17C6AC',
    '--background-card-historico': '#4D4D4D',
    '--background-card-pacientes': '#EBEBEB',
    '--color-card-pacientes': '#4d4d4d',
    '--background-card-meus-dados': '#363636',
    '--background-card-tele-consulta': '#363636',
    '--backgorund-card-agenda-virtual': '#17C6AC',
    '--background-card-modelo-favorito': '#5ce1c2',

    '--color-label-input': '#0b6054',
    '--color-input-border-hover': '#bcfdec',
    '--bg-input-text': '#f1fffc',

    '--bg-color-btn-success': '#00d3b2',
    '--bd-color-btn-success': '#00d3b2',
    '--color-btn-success': '#FFF',
    '--bg-color-btn-save': '#17C6AC',

    '--bg-select': 'rgba(101, 242, 218, 0.5)',
    '--bd-select': '#17c6ac',
    '--color-select-placeholder': '#0b6054',

    '--bg-btn-menu': '#0B6054',

    '--bg-data-profile': '#5ce1c2',
    '--bg-sub-header-pacientes': '#5ce1c2',

    '--bg-modal-header': '#4cbca3',

    '--color-primary': '#4cbca3'
  }
};
