import { Injectable } from '@angular/core';

interface RequestOptions {
  method: string;
  url: string;
  json?: boolean;
  headers?: Object;
  body?: any;
}

@Injectable({
  providedIn: 'root'
})
export class XmlHttpClient {
  constructor() {}

  makeRequest(opts: RequestOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(opts.method, opts.url);
      xhr.onload = function() {
        if (this.status >= 200 && this.status < 300) {
          const json = opts.json || true;
          if (json) {
            resolve(JSON.parse(xhr.response));
          } else {
            resolve(xhr.response);
          }
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function() {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      if (opts.headers) {
        Object.keys(opts.headers).forEach(function(key) {
          xhr.setRequestHeader(key, opts.headers[key]);
        });
      }
      let body = opts.body;
      // We'll need to stringify if we've been given an object
      // If we have a string, this is skipped.
      if (body && typeof body === 'object') {
        body = Object.keys(body)
          .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key]))
          .join('&');
      }
      xhr.send(body);
    });
  }
}
