import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

export const PRESCRIPTION_MODEL_URL = 'prescription-model';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionModelService {
  constructor(private http: HttpClient) {}

  getListByPrescriberId(prescriberId: any): any {
    const params = { prescriberId };
    return this.http.get(`${environment.apiRoot}${PRESCRIPTION_MODEL_URL}`, { params }).toPromise();
  }

  getById(modelId: string, prescriberId = '') {
    const params = prescriberId ? { prescriberId } : {};
    return this.http
      .get(`${environment.apiRoot}${PRESCRIPTION_MODEL_URL}/${modelId}`, { params })
      .toPromise();
  }

  create(prescriptionModel: any, prescriberId = '') {
    const params = prescriberId ? { prescriberId } : {};
    return this.http
      .post(`${environment.apiRoot}${PRESCRIPTION_MODEL_URL}`, prescriptionModel, { params })
      .toPromise();
  }

  update(prescriptionModel: any, modelId: string, prescriberId = '') {
    const params = prescriberId ? { prescriberId } : {};
    return this.http
      .put(`${environment.apiRoot}${PRESCRIPTION_MODEL_URL}/${modelId}`, prescriptionModel, { params })
      .toPromise();
  }

  deleteById(modelId: string, prescriberId = '') {
    const params = prescriberId ? { prescriberId } : {};
    return this.http
      .delete(`${environment.apiRoot}${PRESCRIPTION_MODEL_URL}/${modelId}`, { params })
      .toPromise();
  }
}
