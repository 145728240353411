import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsuarioInativoComponent } from './usuario-inativo/usuario-inativo.component';

@NgModule({
  imports: [CommonModule],
  declarations: [UsuarioInativoComponent],
  exports: [UsuarioInativoComponent]
})
export class CoreModule {}
