import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation
} from '@angular/core';
import { ColorSetting } from '@app/shared/models/color-setting';
import { DocumentModel } from '@app/shared/models/documet-model';
import { CrService } from '../../services/cr.service';
import { AppToastService } from '@app/shared/services/app-toast.service';

@Component({
  selector: 'app-model-list-favorite-prescription',
  templateUrl: './model-list-favorite-document.component.html',
  styleUrls: ['./model-list-favorite-document.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModelListFavoriteDocumentComponent {
  @Input() documentsTemplate: DocumentModel[];
  @Input() documentType: string;
  @Input() colorSetting: ColorSetting;
  @Input() hideEdit = false;
  @Output() selectEvent = new EventEmitter<DocumentModel>();
  @Output() editEvent = new EventEmitter<DocumentModel>();
  @Output() deleteEvent = new EventEmitter<string>();

  @ViewChild('modalHeader') modalHeader: HTMLDivElement;

  typesOfDocuments = ['Prescrição', 'Exame', 'Atestado', 'Outros'];

  loadingSearchMedicament = false;

  constructor(
    public modalRef: BsModalRef,
    private crService: CrService,
    private toast: AppToastService
  ) {}

  activeChange(item: DocumentModel) {
    item.active = !item.active;
  }

  async onSelect(item: DocumentModel) {
    if (item.document === 'prescription') {
      await this.updateMedicaments(item);
    }
    this.selectEvent.emit(item);
    this.modalRef.hide();
  }

  async onEdit(item: DocumentModel) {
    if (item.document === 'prescription') {
      await this.updateMedicaments(item);
    }
    this.editEvent.emit(item);
    this.modalRef.hide();
  }

  onDelete(item: DocumentModel) {
    this.deleteEvent.emit(item.id);
  }

  getText(arrayText: any[]) {
    return arrayText.reduce((acc, val) => (acc ? `${acc}\n${val}` : val), '');
  }

  async updateMedicaments(prescription: DocumentModel) {
    for await (const medicament of prescription.medicaments) {
      if (!!medicament.ean) {
        this.loadingSearchMedicament = true;
        try {
          const medicamentsCR = await this.crService.searchMedicament(medicament.ean).toPromise();
          if (medicamentsCR.length > 0) {
            const medicamentCR = medicamentsCR[0];
            medicament.name = medicamentCR.nome;
            medicament.prescriptionTypeId = medicamentCR.prescriptionTypeId;
            medicament.typeCr = medicamentCR.typeCr;
            medicament.type = 'industrialized';
            medicament.laboratory = medicamentCR.laboratorio;
            medicament.description = medicamentCR.apresentacaoCustomizada;
            medicament.medicamentId = medicamentCR.id;
            medicament.principle = medicamentCR.principio;
          }
        } catch (error) {
          console.log(error);
          this.toast.notify('error', 'Erro ao consultar dados do medicamento.');
        } finally {
          this.loadingSearchMedicament = false;
        }
      } else {
        medicament.type = 'free-text';
      }
    }
  }
}
