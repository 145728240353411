import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PdfService } from '@app/modules/document/services/pdf.service';
import { PdfData } from '@app/shared/models/pdf-data';
import { Retention } from '@app/shared/models/retention';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

const RETENTION_URL = 'retention';

@Injectable({ providedIn: 'root' })
export class RetentionService {
  constructor(private http: HttpClient, private pdfService: PdfService) {}

  public getRetentionByCode(dispensationCode: string): Observable<Retention> {
    return this.http.get<Retention>(`${environment.apiRoot}${RETENTION_URL}/find/${dispensationCode}`);
  }
  public getRetentions(): Observable<Retention[]> {
    return this.http.get<Retention[]>(`${environment.apiRoot}${RETENTION_URL}`);
  }

  getRetentionsByFilter(filter: any): Observable<Retention[]> {
    return this.http.get<Retention[]>(`${environment.apiRoot}${RETENTION_URL}/filter`, {
      params: filter
    });
  }

  getRetentionOfThirdPartiesHistory(userId: string) {
    return this.http.get<any[]>(`${environment.apiRoot}${RETENTION_URL}/third-parties/${userId}`);
  }

  getRetentionOfThirdPartiesHistoryByFilter(userId: string, filter: any) {
    return this.http.get<any>(`${environment.apiRoot}${RETENTION_URL}/third-parties/filter/${userId}`, {
      params: filter
    });
  }

  saveRetentionOfThirdParties(retention: any) {
    return this.http.post(`${environment.apiRoot}${RETENTION_URL}/third-parties`, retention).toPromise();
  }

  cancel(data, isRetentionsThirdParties: boolean): Observable<any> {
    const payload = { userId: data.userId, reason: data.reason };
    return this.http.put(
      `${environment.apiRoot}${RETENTION_URL}/third-parties/${data.retentionId}/cancel?retentionsThirdParties=${isRetentionsThirdParties}`,
      {
        payload
      }
    );
  }

  async downloadThirdPartyPdf(id: string) {
    const pdf = await this.http
      .get<PdfData>(`${environment.apiRoot}${RETENTION_URL}/third-parties/${id}/pdf`)
      .toPromise();
    this.pdfService.openPdf(pdf);
  }
}
