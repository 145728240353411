import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsultComponent } from './consult/consult.component';
import { DispensationGuard } from './guards/dispensation.guard';
import { RegisterComponent } from './register/register.component';
import { ConsultEmpty } from './consultempty/consult-empty.component';
import { ConsultNotFound } from './consultnotfound/consult-not-found.component';

const routes: Routes = [
  { path: 'consult', component: ConsultComponent, canActivate: [DispensationGuard] },
  { path: 'consult-empty', component: ConsultEmpty, canActivate: [DispensationGuard] },
  { path: 'consult-not-found', component: ConsultNotFound, canActivate: [DispensationGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [DispensationGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [DispensationGuard],
  exports: [RouterModule]
})
export class DispensationRoutingModule {}
