import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ColorSetting } from '@app/shared/models/color-setting';
import { Infusion } from '@app/shared/models/infusion';
import { Protocol } from '@app/shared/models/protocol';

@Component({
  selector: 'app-infusion-protocol',
  templateUrl: './infusion-protocol.component.html',
  styleUrls: ['./infusion-protocol.component.scss']
})
export class InfusionProtocolComponent implements OnInit, OnChanges {
  @Input() colorSetting: ColorSetting;
  @Input() protocolSelected: any;

  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  form: FormGroup;
  constructor(private readonly fb: FormBuilder) {
    this.form = this.fb.group({
      protocols: this.fb.array([], Validators.required)
    });
  }

  ngOnInit() {
    this.formEmitter.emit(this.form);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['protocolSelected']) {
      const protocolSelected: Protocol = changes['protocolSelected'].currentValue;
      if (protocolSelected) {
        this.addProtocol(protocolSelected);
      }
    }
  }

  @Input()
  set infusion(infusion: Infusion) {
    if (infusion) {
      infusion.protocols.forEach(protocol => this.addProtocol(protocol));
    }
  }

  get protocols() {
    return this.form.controls['protocols'] as FormArray;
  }

  addProtocol(protocol: Protocol) {
    const phasesGroups = protocol.phases.map((phase: any) =>
      this.fb.group({
        name: [phase.name],
        dosage: [phase.dosage, Validators.required],
        observation: [phase.observation]
      })
    );

    const protocolGroup = this.fb.group({
      name: [protocol.name],
      code: [protocol.code],
      phases: this.fb.array([...phasesGroups])
    });

    this.protocols.push(protocolGroup);
  }

  deleteProtocol(protocolIndex: number) {
    this.protocols.removeAt(protocolIndex);
  }
}
