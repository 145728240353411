import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from './app.service';
import { ThemeService } from './theme';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  route: string;
  loading = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private _location: Location,
    private router: Router,
    private themeService: ThemeService,
    private titleService: Title,
    private readonly _appService: AppService,
    private cdf: ChangeDetectorRef,
    public _authService: AuthService
  ) {
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(ev => {
      if (this._location.path() === '') {
        this.route = 'home';
      } else {
        this.route = this._location.path();
      }

      if (ev instanceof NavigationStart) {
        this.loading = true;
        this.cdf.detectChanges();
      }

      if (
        ev instanceof NavigationEnd ||
        ev instanceof NavigationCancel ||
        ev instanceof NavigationError
      ) {
        this.loading = false;
        this.cdf.detectChanges();
      }
    });

    const favicon: HTMLLinkElement = document.querySelector('#favicon');
    const theme = this.themeService.getActiveTheme();
    favicon.href = theme.favicon;
    this.titleService.setTitle(theme.title);
  }
  ngOnInit(): void {
    this._appService.iniciaVerificacaoVersao();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
