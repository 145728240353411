import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@app/shared/models/decodedLoginToken';
import { DataBigboostConsult } from '@app/shared/models/paciente';
import { environment } from '@env/environment';
import { CroppedEvent } from 'ngx-photo-editor';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
export const USER_URL = 'user';
const ACCEPT_MANIPULATED_ACCEPTANCE_TERM = 'user/accept-manipulated-acceptance-term';
const ACCEPTED_MANIPULATED_TERM = 'user/accepted-manipulated-term';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {}

  private profileAvatar$: Observable<string>;

  /*
   * ------------------------
   *  Cache da Imagem de Perfil
   * ------------------------
   */

  getAvatar(): Observable<string> {
    if (!this.profileAvatar$) {
      this.profileAvatar$ = this.http.get<any>(`${environment.apiRoot}${USER_URL}/avatar`).pipe(
        shareReplay(1),
        map(({ avatar }) => `data:image/jpeg;base64,` + avatar),
        catchError(_ => {
          return of('');
        })
      );
    }
    return this.profileAvatar$;
  }

  updateAvatar({ file, base64 }: CroppedEvent): Observable<any> {
    const data = new FormData();

    data.append('avatar', file);
    return this.http.put<any>(`${environment.apiRoot}${USER_URL}/avatar`, data).pipe(
      map(res => {
        this.profileAvatar$ = of(base64);
      })
    ); // on success
  }

  clearAvatar(): void {
    this.profileAvatar$ = null;
  }

  getQuantityUsers(tenantId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${USER_URL}/quantities`, { params: { tenantId } });
  }

  async acceptedManipulatedTerm(userId) {
    const response = await this.http
      .get<any>(`${environment.apiRoot}${ACCEPTED_MANIPULATED_TERM}/${userId}`)
      .toPromise();
    return response.manipulatedAccepranceTerm;
  }

  update(userId, data): Observable<any> {
    return this.http.put<any>(`${environment.apiRoot}${USER_URL}/${userId}`, data);
  }

  acceptManipulatedAcceptanceTerm(userId): Observable<any> {
    return this.http.put<any>(
      `${environment.apiRoot}${ACCEPT_MANIPULATED_ACCEPTANCE_TERM}/${userId}`,
      {}
    );
  }

  getUser(params: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${USER_URL}`, { params });
  }

  checkEmailExists(email: string, tenantId?: string): Observable<any> {
    const params = { email };
    if (tenantId) {
      params['tenantId'] = tenantId;
    }
    return this.http.get<any>(`${environment.apiRoot}${USER_URL}/exists`, { params });
  }

  checkWomensMonth(): Observable<any> {
    return this.http.put<any>(`${environment.apiRoot}${USER_URL}/women`, {});
  }

  checkUi({
    doNotShowAgainModalSuccessPrescription,
    doNotShowAgainModalNewPortalDigitalSignatures
  }: any): Observable<any> {
    return this.http.patch<any>(`${environment.apiRoot}${USER_URL}/ui`, {
      doNotShowAgainModalSuccessPrescription,
      doNotShowAgainModalNewPortalDigitalSignatures
    });
  }

  getControllerUserUi(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${USER_URL}/${id}/ui`);
  }

  revalidateCpf({ id, userType }) {
    return this.http.post<{ user: User; consult: DataBigboostConsult }>(
      `${environment.apiRoot}revalidate/cpf`,
      { id, userType }
    );
  }
}
