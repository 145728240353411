import { Component } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { UserService } from '@app/modules/user/user.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { User } from '@app/shared/models/decodedLoginToken';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-new-portal-digital-signatures',
  templateUrl: './modal-new-portal-digital-signatures.component.html',
  styleUrls: ['./modal-new-portal-digital-signatures.component.scss']
})
export class ModalNewPortalDigitalSignaturesComponent {
  doNotShowAgain = false;
  constructor(
    private modalRef: BsModalRef,
    private appToastService: AppToastService,
    private userService: UserService,
    private authService: AuthService
  ) {}

  get user(): User {
    return this.authService.user();
  }
  @nativeAsync
  async onClose() {
    try {
      await this.userService
        .checkUi({
          ...this.user.ui,
          doNotShowAgainModalNewPortalDigitalSignatures: this.doNotShowAgain
        })
        .toPromise();

      this.modalRef.hide();
    } catch (error) {
      this.appToastService.notify('error', 'Erro ao marcar não mostrar novamente');
    }
  }
}
