import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrescriptionService } from '@app/modules/document/services/prescription.service';

@Component({
  selector: 'app-consult-empty',
  templateUrl: './consult-empty.component.html',
  styleUrls: ['./consult-empty.component.scss']
})
export class ConsultEmpty implements OnInit {
  loading = false;
  prescription: any;
  showDispensations = true;
  pharmacy: any;

  constructor(private router: Router, private prescriptionService: PrescriptionService) {
    this.prescription = this.prescriptionService.prescription;
  }

  ngOnInit() {}

  register(): void {
    this.prescriptionService.prescription = {
      thirdParties: true,
      medicaments: [
        {
          hidden: true,
          checked: true
        }
      ]
    };
    this.router.navigate([`/dispensation/register`]);
  }

  get medicaments() {
    return this.prescription.medicaments;
  }
}
