import { DocumentGuard } from './guards/document.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { RetentionConfirmationComponent } from './pages/retention-confirmation/retention-confirmation.component';
import { ListingComponent } from './pages/listing/listing.component';
import { UploadPdfComponent } from './pages/prescription/upload-pdf.component';
import { DocumentComponent } from './document.component';

const routes: Routes = [
  { path: 'new', component: DocumentComponent, canActivate: [DocumentGuard] },
  { path: ':id/new', component: DocumentComponent, canActivate: [DocumentGuard] },
  { path: ':id/edit', component: DocumentComponent, canActivate: [DocumentGuard] },
  {
    path: 'model/:modelId/:documentType/edit',
    component: DocumentComponent,
    canActivate: [DocumentGuard]
  },
  { path: 'confirmation/:param', component: ConfirmationComponent },
  { path: 'confirmation/successful/:dispensationCode', component: RetentionConfirmationComponent },
  { path: 'listing-prescriptions/:param', component: ListingComponent },
  { path: 'upload/:code/:id', component: UploadPdfComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentRoutingModule {}
