import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ThemeService } from '@app/theme';
import { environment } from '@env/environment';

@Component({
  selector: 'app-account-type',
  templateUrl: './account-type.component.html',
  styleUrls: ['./account-type.component.scss']
})
export class AccountTypeComponent implements OnInit {
  accountTypeForm: FormGroup = new FormGroup({
    account: new FormControl(null, Validators.required)
  });
  constructor(private router: Router, private themeService: ThemeService) {}

  ngOnInit() {
    const maintenance = environment.maintenance;
    if (maintenance) {
      this.router.navigate([`/update`]);
    }
  }

  submitForm($event): void {
    $event.preventDefault();
    const accountCtrl = this.accountTypeForm.get('account');
    accountCtrl.markAsDirty();
    accountCtrl.updateValueAndValidity();
    if (this.accountTypeForm.valid) {
      this.router.navigate(['/entry/register', accountCtrl.value]);
    }
  }

  get isThemeActiveReceitaDigital() {
    const active = this.themeService.getActiveTheme();
    return active.name === 'theme-receita-digital';
  }
}
