import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-personal-data-form',
  templateUrl: './personal-data-form.component.html'
})
export class PersonalDataFormComponent {
  @Input() form: FormGroup;
  @Input() isResponsible = false;
  @Input() showAlertMsg = true;
}
