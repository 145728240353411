import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import DocumentUtil from '@app/modules/document-v2/utils/Document.util';
import { Patient } from '@app/shared/models';

@Component({
  selector: 'app-model-form-pre-preenchido',
  templateUrl: './model-form-pre-preenchido.component.html',
  styleUrls: ['./model-form-pre-preenchido.component.scss']
})
export class ModelFormPrePreenchidoComponent implements OnInit {
  @Input() patient: Patient;
  @Input() form: FormGroup;
  @Input() attestationDays: string = null;
  @Input() typeOfActivity: string = null;

  @Output() onClickRemove = new EventEmitter<void>();
  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  attestationsDays = DocumentUtil.getAttestationsDays();
  typesOfActivity = DocumentUtil.getTypesOfActivity();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form.addControl('attestationDays', this.fb.control(this.attestationDays, Validators.required));
    this.form.addControl('typeOfActivity', this.fb.control(this.typeOfActivity, Validators.required));
    this.formEmitter.emit(this.form);
  }

  remove() {
    this.form.removeControl('attestationDays');
    this.form.removeControl('typeOfActivity');
    this.onClickRemove.next();
  }

  get cpfFormatado() {
    if (this.patient) {
      return this.patient.cpf.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/, '$1.$2.$3-$4');
    }
    return '';
  }
}
