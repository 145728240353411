import { FormGroup, FormArray, AbstractControl } from '@angular/forms';

export function logInvalidForm(form: AbstractControl): void {
  Object.entries((form as FormGroup | FormArray).controls)
    .filter(([_, ctrl]) => ctrl.invalid)
    .forEach(([key, ctrl]) => {
      if (ctrl instanceof FormArray) {
        logInvalidForm(ctrl);
      } else {
        console.log(key, ctrl.errors);
      }
    });
}
