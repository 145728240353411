import { PrescriptionService } from '@app/modules/document/services/prescription.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class DispensationGuard implements CanActivate {
  constructor(private router: Router, private prescriptionService: PrescriptionService) {}

  canActivate(): boolean {
    if (!this.prescriptionService.prescription) {
      this.prescriptionService.pdfBase64 = null;
      this.router.navigate(['']);
      return false;
    }

    return true;
  }
}
