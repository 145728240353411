import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formattedCnpj'
})
export class FormattedCnpjPipe implements PipeTransform {
  transform(value: string): string {
    if (value && value !== '') {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
    }
  }
}
