import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PrescriptionService } from '@app/modules/document/services/prescription.service';
import * as moment from 'moment';

@Component({
  selector: 'app-consult',
  templateUrl: './consult.component.html',
  styleUrls: ['./consult.component.scss']
})
export class ConsultComponent {
  prescription: any;
  showDispensations = true;
  private _allChecked = false;

  constructor(private router: Router, private prescriptionService: PrescriptionService) {
    this.prescription = this.prescriptionService.prescription;
  }

  get patient() {
    return this.prescription.patient;
  }

  get healthProfessional() {
    return this.prescription.healthProfessional;
  }

  get medicaments() {
    return this.prescription.medicaments;
  }

  get allChecked() {
    return this.medicaments.every(m => m.checked);
  }

  set allChecked(val: boolean) {
    this._allChecked = val;
  }

  get someChecked() {
    return this.medicaments.some(m => m.checked);
  }

  get indeterminate() {
    const medicamentsChecked = this.medicaments.reduce((acc, m) => acc + !!m.checked, 0);
    return medicamentsChecked > 0 && medicamentsChecked < this.medicaments.length;
  }

  updateAllChecked(): void {
    this.medicaments.forEach(m => (m.checked = this._allChecked));
  }

  getPatientAge() {
    return this.getAge(this.patient.dateOfBirth);
  }

  getAge(dateStr: string) {
    const now = moment(new Date());
    const date = moment(dateStr);
    return date.isValid() ? now.diff(date, 'years') : '';
  }

  getSpecialties(specialties: any[]) {
    return specialties.map(s => s.title).join(', ');
  }

  get hasDispensation(): boolean {
    return this.medicaments.find(m => m.dispensations && m.dispensations.length);
  }

  register(): void {
    this.router.navigate([`/dispensation/register`]);
  }
}
