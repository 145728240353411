import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';
import { ValidationService } from '@app/core/services/validation.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { BrazilState, HealthProfessional, Patient } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { DataBigboostConsult } from '@app/shared/models/paciente';

@Component({
  selector: 'app-responsible-patient-form',
  templateUrl: './responsible-patient-form.component.html',
  styleUrls: ['./responsible-patient-form.component.scss']
})
export class ResponsiblePatientFormComponent implements OnInit {
  @Input() prescriber: HealthProfessional;
  @Input() patient: DataBigboostConsult;
  @Input() registeredByResponsible: boolean;
  @Output() formChange = new EventEmitter<FormGroup>();
  @Output() updateList = new EventEmitter<any>();

  @ViewChild('repeatName', { static: true })
  repeatNameCheckbox: any;

  loading = false;
  states: BrazilState[] = BrazilianStates;
  dependents: Patient[];
  showDependentForm = false;
  private patientsCache = new Map<string, boolean>();

  private _form = this.fb.group({
    responsible: this.fb.group(
      {
        _id: [null],
        name: ['', [Validators.required, ValidationService.nomeValidator]],
        fullname: ['', [Validators.required, ValidationService.nomeValidator]],
        cpf: ['', Validators.required],
        sex: ['', Validators.required],
        dateOfBirth: [null, Validators.required],
        cellphone: ['', Validators.required],
        telephone: [''],
        email: ['', [Validators.required, Validators.email]],
        emailConfirmation: ['', [Validators.required, Validators.email]],
        membership: this.fb.group({
          mothersName: ['']
        }),
        address: this.fb.group({
          uf: ['', Validators.required],
          city: ['', Validators.required],
          complement: [''],
          number: ['', Validators.required],
          street: ['', Validators.required],
          neighborhood: ['', Validators.required],
          cep: ['', [Validators.required, ValidationService.cepValidator]]
        }),
        pendency: [null]
      },
      { validator: ValidationService.emailMatch }
    )
  });

  constructor(
    private fb: FormBuilder,
    private cepService: CepService,
    private authService: AuthService,
    private patientsService: PatientsService,
    private healthProfessionalService: HealthProfessionalService
  ) {}

  @nativeAsync
  async ngOnInit() {
    this.loading = true;
    this.initForm();
    const patient = await this.getPatientByCpf();
    if (patient) {
      this.patchForm(patient);
      this.dependents = patient.dependents;
      this.showDependentForm = !this.dependents.length;
      this.dependents.forEach(({ _id }) => this.cacheDependentId(_id));
    } else {
      this.setForm(this.patient);
    }

    if (this.hasAccount) {
      this.disableForm();
    }
    this.formChange.emit(this._form);
    this.loading = false;
  }

  get user(): User {
    return this.authService.user();
  }

  get form() {
    return <FormGroup>this._form.get('responsible');
  }

  private initForm() {
    this.form.get('fullname').valueChanges.subscribe(fullname => {
      if (this.repeatNameCheckbox && this.repeatNameCheckbox.nzChecked) {
        if (!fullname) {
          this.repeatNameCheckbox.nzChecked = false;
          this.form.get('name').enable();
        }
        this.form.get('name').setValue(fullname);
      }
    });
  }

  private patchForm(patient: Patient) {
    this.form.patchValue(patient);
    if (patient.user) {
      this.form.get('email').setValue(patient.user.email);
    }
    const date = new Date(patient.dateOfBirth);
    this.form.get('dateOfBirth').setValue(date.toISOString().substring(0, 10));
    if (!patient.fullname) {
      this.form.get('fullname').setValue(patient.name);
    }
  }

  private setForm(consult: DataBigboostConsult) {
    if (consult.status === 1) {
      this.form.get('cpf').setValue(consult.cpf);
      this.form.get('fullname').setValue(consult.nome);
      this.form.get('sex').setValue(consult.genero);
      this.form.get('membership.mothersName').setValue(consult.mae);
      const date = consult.nascimento.split('/');
      const dateOfBirth = new Date(`${date[1]}/${date[0]}/${date[2]}`);
      this.form.get('dateOfBirth').setValue(dateOfBirth.toISOString().substring(0, 10));

      this.form.get('cpf').disable();
      this.form.get('fullname').disable();
      this.form.get('dateOfBirth').disable();
      this.form.get('sex').disable();
    } else {
      this.form.get('pendency').setValue(consult);
    }
  }

  private disableForm() {
    this.form.get('name').disable();
    this.form.get('fullname').disable();
    this.form.get('cpf').disable();
    this.form.get('dateOfBirth').disable();
    this.form.get('sex').disable();

    // remove required
    this.form.setValidators(null);
    this.form.get('email').setValidators([Validators.email]);
    this.form.get('emailConfirmation').setValidators([Validators.email]);
    this.form.get('name').clearValidators();
    this.form.get('fullname').clearValidators();
    this.form.get('cpf').clearValidators();
    this.form.get('dateOfBirth').clearValidators();
    this.form.get('sex').clearValidators();
  }

  @nativeAsync
  private async getPatientByCpf() {
    try {
      return await this.patientsService.getPatientByCpf(this.patient.cpf).toPromise();
    } catch (_) {
      return null;
    }
  }

  get hasAccount() {
    return !!this.form.get('_id').value;
  }

  get currentDate() {
    const now = new Date();
    const dd = String(now.getDate()).padStart(2, '0');
    const mm = String(now.getMonth() + 1).padStart(2, '0');
    const yyyy = now.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  checkRepeatName(value: boolean) {
    if (value) {
      this.form.get('name').setValue(this.form.get('fullname').value);
      this.form.get('name').disable();
    } else {
      this.form.get('name').enable();
    }
  }

  checkEmail(disable: boolean) {
    if (disable) {
      this.form.get('email').reset();
      this.form.get('email').disable();
    } else {
      this.form.get('email').enable();
    }
  }

  @nativeAsync
  async onKey(event: any) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        this.form.controls['address'].patchValue({
          uf: data.uf,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          number: null,
          cep: data.cep
        });
      }
    }
  }

  addUnderageForm(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      const control = form.controls[key];
      this._form.addControl(key, control);
    });
  }

  addPatient(patient: Patient) {
    this.healthProfessionalService.addPatient(this.prescriber._id, patient._id).subscribe(_ => {
      this.cacheDependentId(patient._id);
      this.updateList.emit();
    });
  }

  removePatient(patient: Patient) {
    this.healthProfessionalService.removePatient(this.prescriber._id, patient._id).subscribe(_ => {
      this.cacheDependentId(patient._id);
      this.updateList.emit();
    });
  }

  hasDependent(patient: Patient) {
    return this.patientsCache.get(patient._id);
  }

  private cacheDependentId(id: string) {
    this.healthProfessionalService.getPatientsById(id, this.user._id).subscribe(
      p => this.patientsCache.set(id, true),
      err => {
        if (err.status === 404) {
          this.patientsCache.set(id, false);
        } else {
          console.error(err);
        }
      }
    );
  }
}
