import { Injectable } from '@angular/core';
import { XmlHttpClient } from '@app/core/services/xml-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class CepService {
  constructor(private request: XmlHttpClient) {}

  consult(cep: string): Promise<any> {
    return this.request
      .makeRequest({ method: 'GET', url: `https://viacep.com.br/ws/${cep}/json` })
      .then();
  }
}
