import { Component, HostListener } from '@angular/core';
import { CoreService } from '@app/core/services/core.service';
import { ThemeService } from '@app/theme';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  actualDate = new Date();
  isMobile = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = this.coreService.isViewPortMobile();
  }

  constructor(private themeService: ThemeService, private coreService: CoreService) {}

  isThemeActiveReceitaDigital() {
    const active = this.themeService.getActiveTheme();
    return active.name === 'theme-receita-digital';
  }
}
