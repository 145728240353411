import { NgModule } from '@angular/core';

import { EntryRoutingModule } from '@app/modules/entry/entry-routing.module';
import { LoginComponent } from '@app/modules/entry/pages/login/login.component';
import { RegisterComponent } from '@app/modules/entry/pages/register/register.component';
import { SharedModule } from '@app/shared';
import { AccountTypeComponent } from '@app/modules/entry/pages/register/account-type.component';
import { WelcomeComponent } from '@app/modules/entry/pages/welcome/welcome.component';
import { ResetPasswordComponent } from '@app/modules/entry/pages/reset-password/reset-password.component';
import { PatientFormComponent } from '@app/modules/entry/pages/register/patient-form/patient-form.component';
import { PharmacyFormComponent } from '@app/modules/entry/pages/register/pharmacy-form/pharmacy-form.component';
import { ChangePasswordComponent } from '@app/modules/entry/pages/change-password/change-password.component';
import { NgxMaskModule } from 'ngx-mask';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { TermsAndConditionsComponent } from './pages/register/terms-and-conditions/terms-and-conditions.component';
import { ActivateAccountComponent } from './pages/activate-account/activate-account.component';
import { NgxLoadingModule } from 'ngx-loading';
import { ModalTermsAndConditionsComponent } from './pages/register/modal-terms-and-conditions/modal-terms-and-conditions.component';
import { HealthProfessionalFormComponent } from './pages/register/health-professional-form/health-professional-form.component';
import { PasswordStrengthComponent } from './pages/password-strength/password-strength.component';
import { PharmacyOptionComponent } from './pages/pharmacy-option/pharmacy-option.component';
import { ResponsibleFormComponent } from './pages/register/underage-patient-form/responsible-form/responsible-form.component';
import { UnderagePatientFormComponent } from './pages/register/underage-patient-form/underage-patient-form.component';
import { ResponsiblePatientFormComponent } from './pages/register/responsible-patient-form/responsible-patient-form.component';

@NgModule({
  imports: [
    SharedModule,
    EntryRoutingModule,
    NzDatePickerModule,
    NzModalModule,
    NgxLoadingModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    LoginComponent,
    RegisterComponent,
    AccountTypeComponent,
    WelcomeComponent,
    ResetPasswordComponent,
    PatientFormComponent,
    PharmacyFormComponent,
    ChangePasswordComponent,
    TermsAndConditionsComponent,
    ActivateAccountComponent,
    ModalTermsAndConditionsComponent,
    HealthProfessionalFormComponent,
    PasswordStrengthComponent,
    PharmacyOptionComponent,
    ResponsibleFormComponent,
    UnderagePatientFormComponent,
    ResponsiblePatientFormComponent
  ],
  exports: [PatientFormComponent, UnderagePatientFormComponent, ResponsiblePatientFormComponent]
})
export class EntryModule {}
