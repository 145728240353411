import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProtocolGroup } from '@app/shared/models/protocol';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VictaProtocolService {
  constructor(private http: HttpClient) {}

  getProtocols(professionalType: string, params = {}) {
    return this.http
      .get<ProtocolGroup[]>(`${environment.apiRoot}victa-protocols`, { params })
      .pipe(
        map(groups => {
          groups.forEach(group => {
            group.protocols = group.protocols.filter(({ professionals }) =>
              professionals.includes(professionalType)
            );
          });
          return groups.filter(({ protocols }) => protocols.length);
        })
      )
      .toPromise();
  }

  import(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${environment.apiRoot}victa-protocols/import`, formData).toPromise();
  }
}
