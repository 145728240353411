import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { DataBigboostConsult } from '../models/paciente';
import { ResponseSearchCnpj } from '../models/reponse-search-cnpj';

const API_UL = `${environment.apiRoot}bigboost`;

@Injectable({
  providedIn: 'root'
})
export class BigboostService {
  constructor(private http: HttpClient) {}

  consultCpf(cpf: string, birthdate?: string): Observable<DataBigboostConsult> {
    const params = birthdate ? { birthdate } : {};
    return this.http.get<DataBigboostConsult>(`${API_UL}/cpf/${cpf}`, { params });
  }

  consultCnpj(cnpj: string): Promise<ResponseSearchCnpj> {
    return this.http.get<ResponseSearchCnpj>(`${API_UL}/cnpj/${cnpj}`).toPromise();
  }
}
