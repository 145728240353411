import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { version } from '../../../../package.json';

@Injectable({ providedIn: 'root' })
export class CoreService {
  private _backendVersion = '';

  readonly SMALL_LENGTH_VIEW_PORT = 474;
  readonly MEDIUM_LENGTH_VIEW_PORT = 768;

  constructor(private http: HttpClient) {}

  get isMrd() {
    return window.location.origin.toLowerCase().includes('meureceituario');
  }

  get frontendVersion(): string {
    return version;
  }

  getBackendVersion(): Observable<string> {
    if (this._backendVersion) {
      return of(this._backendVersion);
    }
    return this.http.get<any>(`${environment.apiRoot}info`).pipe(
      map(({ version: v }) => v),
      tap(v => (this._backendVersion = v))
    );
  }

  isViewPortMobile(): boolean {
    return window.innerWidth <= this.SMALL_LENGTH_VIEW_PORT;
  }

  isViewPortTablet(): boolean {
    return window.innerWidth <= this.MEDIUM_LENGTH_VIEW_PORT;
  }
}
