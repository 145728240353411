import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rd-buttom-group-icon',
  templateUrl: './rd-buttom-group-icon.component.html',
  styleUrls: ['./rd-buttom-group-icon.component.scss']
})
export class RdButtomGroupIconComponent implements OnInit {
  @Input() label: string;
  @Input() icon: string;

  @Output() onClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
