import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ColorSetting } from '@app/shared/models/color-setting';

@Component({
  selector: 'app-medicament-form',
  templateUrl: './medicament-form.component.html',
  styleUrls: ['./medicament-form.component.scss']
})
export class MedicamentFormComponent implements AfterViewInit {
  @Input() index: number;
  @Input() form: FormGroup;
  @Input() signed = false;
  @Input() colorSetting: ColorSetting;

  @Output() onDelete = new EventEmitter<number>();
  @Output() onSelect = new EventEmitter<any>();

  unitsOfMeasurement = [
    { label: 'Embalagem(ns)', value: 'PACKING' },
    { label: 'Caixa(s)', value: 'BOX' },
    { label: 'Frasco(s)', value: 'BOTTLE' },
    { label: 'Cápsula(s)', value: 'CAPSULE' },
    { label: 'Comprimido(s)', value: 'PILL' },
    { label: 'Glóbulo(s)', value: 'BLOOD_CELL' },
    { label: 'Sachê(s)', value: 'SACHET' },
    { label: 'Bisnaga(s)', value: 'TUBE' },
    { label: 'Ampola(s)', value: 'AMPOULE' },
    { label: 'Flaconete(s)', value: 'VIAL' },
    { label: 'ML(s)', value: 'ML' },
    { label: 'MG(s)', value: 'MG' },
    { label: 'UI(s)', value: 'UI' }
  ];

  ngAfterViewInit(): void {
    if (this.signed) {
      this.form.disable();
    }
  }

  minusPrescribedQuantity() {
    const value = this.form.get('prescribedQuantity').value;
    if (value > 1) {
      this.form.get('prescribedQuantity').setValue(value - 1);
    }
  }

  plusPrescribedQuantity() {
    const value = this.form.get('prescribedQuantity').value;
    this.form.get('prescribedQuantity').setValue(value + 1);
  }

  onEditing() {
    this.form.get('editing').setValue(true);
  }

  cancelEditing() {
    this.form.get('editing').setValue(false);
  }
}
