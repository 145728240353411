import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { UserService } from '@app/modules/user/user.service';
import { Patient } from '@app/shared/models';
import { DataBigboostConsult } from '@app/shared/models/paciente';
import moment from 'moment';

@Component({
  selector: 'app-simplified-underage-patient-form',
  templateUrl: './simplified-underage-patient-form.component.html'
})
export class SimplifiedUnderagePatientFormComponent implements OnInit {
  @Input() patient: DataBigboostConsult | Patient;
  @Input() isResponsible = false;
  @Input() existingPatient = false;

  @Output() formChange = new EventEmitter<FormGroup>();

  form: FormGroup;
  consultResponsible = false;

  constructor(private fb: FormBuilder, private userService: UserService) {}

  ngOnInit() {
    this.buildForm();
    if (this.existingPatient && this.isResponsible) {
      this.patchFormResponsible({
        patientExists: true,
        data: this.patient
      });
    } else {
      this.patchValueForm(this.patient);
      this.disabledFields(this.patient);
    }

    this.form.get('responsible.emailContact').valueChanges.subscribe(email => {
      this.form
        .get('responsible.emailContact')
        .setValue(email && email.toLowerCase(), { emitEvent: false });
    });

    this.formChange.emit(this.form);
  }

  get formResponsible(): FormGroup {
    return <FormGroup>this.form.get('responsible');
  }

  buildForm() {
    this.form = this.fb.group({
      _id: [null],
      cpf: ['', [ValidationService.cpfValidator]],
      fullname: ['', Validators.required],
      name: [''],
      sex: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      membership: this.fb.group({
        mothersName: ['']
      }),
      responsible: this.fb.group({
        _id: [null],
        cpf: ['', [Validators.required, ValidationService.cpfValidator]],
        fullname: ['', Validators.required],
        name: ['', Validators.required],
        sex: ['', Validators.required],
        dateOfBirth: ['', Validators.required],
        membership: this.fb.group({
          mothersName: ['']
        }),
        cellphone: [''],
        telephone: [''],
        email: ['', Validators.email],
        emailContact: ['', Validators.email],
        address: this.fb.group({
          uf: [''],
          city: [''],
          complement: [''],
          number: [''],
          street: [''],
          neighborhood: [''],
          cep: ['']
        }),
        pendency: [null]
      })
    });
  }

  patchValueForm(patient: DataBigboostConsult) {
    if (patient.status === 1 || patient.status === 200) {
      const nascimento = moment(patient.nascimento, 'DD/MM/YYYY');

      const valuesDisableds = {
        _id: patient.id,
        cpf: patient.cpf,
        fullname: patient.nome,
        name: patient.nome,
        dateOfBirth: nascimento.format('YYYY-MM-DD')
      };

      if (this.isResponsible) {
        this.formResponsible.patchValue(valuesDisableds);
      } else {
        this.form.patchValue(valuesDisableds);
      }
    }
  }

  patchFormResponsible({ patientExists, data }) {
    if (data) {
      if (patientExists) {
        this.patchFormResponsiblePatientExists(data);
      } else {
        this.patchFormResponsibleNotPatientExists(data);
      }

      this.consultResponsible = true;
      this.disabledFieldsForm(patientExists);
      this.formResponsible.get('cpf').disable();
    }
  }

  private patchFormResponsiblePatientExists(data: Patient) {
    this.formResponsible.patchValue(data);
    if (data.dateOfBirth) {
      const dateStr = String(data.dateOfBirth);
      const dateOfBirth = moment(dateStr.substring(0, 10)).format('YYYY-MM-DD');
      this.formResponsible.get('dateOfBirth').setValue(dateOfBirth);
    }

    if (data && data.user && data.user.email) {
      this.formResponsible.get('email').setValue(data.user.email);
    }
  }

  private patchFormResponsibleNotPatientExists(data: DataBigboostConsult) {
    const nascimento = moment(data.nascimento, 'DD/MM/YYYY');
    const patientValues = {
      cpf: data.cpf,
      fullname: data.nome,
      name: data.nome,
      dateOfBirth: nascimento.format('YYYY-MM-DD')
    };
    this.formResponsible.patchValue(patientValues);
  }

  disabledFields(patient: DataBigboostConsult) {
    if (patient.status === 1 || patient.status === 200) {
      this.disabledFieldsForm(patient);
    }
  }

  disabledFieldsForm(patient: DataBigboostConsult) {
    if (!this.isResponsible) {
      this.form.get('cpf').disable();
      this.form.get('dateOfBirth').disable();
      if (patient.status === 1) {
        this.form.get('fullname').disable();
      }
    }
    this.formResponsible.get('fullname').disable();
    this.formResponsible.get('dateOfBirth').disable();
  }
}
