import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormattedTelPipe } from '@app/shared/pipes/formatted-tel.pipe';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTelephone]'
})
export class TelephoneDirective {
  @Input()
  telephone: string;

  constructor(private element: ElementRef, private ngControl: NgControl) {}

  private formattedTel = new FormattedTelPipe();
  @HostListener('input', ['$event'])
  onInputChange(event: Event | any) {
    this.tel(event.target.value);
  }

  tel(value: string): void {
    this.ngControl.control.setValue(this.formattedTel.transform(value));
  }
}
