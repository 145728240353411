import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { AdditionalFeatureService } from '@app/modules/admin/listing/features-listing/additional-feature.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { AdditionalFeatureAccept } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { AppToastService } from '@app/shared/services/app-toast.service';

@Component({
  selector: 'app-modal-cip-acceptance',
  templateUrl: './modal-cip-acceptance.component.html',
  styleUrls: ['./modal-cip-acceptance.component.scss']
})
export class ModalCipAcceptanceComponent implements OnInit {
  @Input() code: number;
  @Input() isVisible = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() userChange = new EventEmitter<User>();

  title =
    'Termo de Consentimento para Tratamento de Dados <br> Projeto Piloto - Sistema Eletrônico de Registro de Prescrições';
  refuseLoading = false;
  acceptLoading = false;

  constructor(
    private authService: AuthService,
    private additionalFeatureService: AdditionalFeatureService,
    private notification: AppToastService
  ) {}

  ngOnInit() {
    this.setHeight(window.innerHeight);
  }

  get user(): User {
    return this.authService.user();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setHeight(window.innerHeight);
  }

  setHeight(height: number) {
    const heightValue = `${height * 0.6}px`;
    document.documentElement.style.setProperty('--modal-height', heightValue);
  }

  get isAnswered() {
    return (
      this.user &&
      this.user.features.find(f => f.code === this.code).accept !== AdditionalFeatureAccept.NONE
    );
  }

  close() {
    this.isVisibleChange.emit(false);
  }

  @nativeAsync
  async refuse() {
    try {
      this.refuseLoading = true;
      await this.additionalFeatureService.refuseFeature(this.code);
      await this.authService.refreshToken().toPromise();
      this.userChange.emit(this.user);
      this.close();
      if (this.isAnswered) {
        this.notification.notify('error', 'Termo revogado', '');
      } else {
        this.notification.notify(
          'error',
          'Termo rejeitado',
          'Para alterar sua resposta acesse "Meus Dados"'
        );
      }
    } catch (err) {
      this.notification.notify('error', 'Erro', 'Ocorreu um erro, tente novamente');
    }
    this.refuseLoading = false;
  }

  @nativeAsync
  async accept() {
    try {
      this.acceptLoading = true;
      await this.additionalFeatureService.acceptFeature(this.code);
      await this.authService.refreshToken().toPromise();
      this.userChange.emit(this.user);
      this.close();
      if (this.isAnswered) {
        this.notification.notify('success', 'Termo aceito', '');
      } else {
        this.notification.notify(
          'success',
          'Termo aceito com sucesso',
          'Para alterar sua resposta acesse "Meus Dados"'
        );
      }
    } catch (err) {
      this.notification.notify('error', 'Erro', 'Ocorreu um erro, tente novamente');
    }
    this.acceptLoading = false;
  }
}
