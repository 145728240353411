import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { CoreService } from '@app/core/services/core.service';
import { PharmacistService } from '@app/modules/pharmacist/services/pharmacist.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { ModalSearchComponent } from '@app/shared/modals/modal-search/modal-search.component';
import { Permission } from '@app/shared/models/permission.enum';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss', '../../../modules/document/document.component.scss']
})
export class MenuComponent implements OnInit {
  modalRef: BsModalRef;
  visible = false;
  userId: string;
  userType: string;
  user: any;
  completeUser: any;
  functionalities = [];
  Permission = Permission;
  backendVersion$: Observable<string>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private modalService: BsModalService,
    private coreService: CoreService,
    private authService: AuthService,
    private pharmacistService: PharmacistService
  ) {
    this.backendVersion$ = this.coreService.getBackendVersion();
  }

  @nativeAsync
  async ngOnInit() {
    this.user = this.authService.user();
    this.userId = localStorage.getItem('userId');
    this.userType = localStorage.getItem('userType');
    await this.getCompleteUser(this.user);
  }

  @nativeAsync
  private async getCompleteUser(user) {
    if (user.userType === 'pharmacist') {
      this.getPharmacistData();
    }
  }

  @nativeAsync
  async getPharmacistData() {
    this.completeUser = await this.pharmacistService.getPharmacistByUserId(this.user._id).toPromise();
  }

  get frontendVersion(): string {
    return this.coreService.frontendVersion;
  }

  open(): void {
    this.visible = true;
    setTimeout(() => {
      this.renderer.setStyle(this.document.body, 'padding-right', '0px');
    }, 1);
  }

  close(): void {
    this.visible = false;
  }

  changeRoute(route: string, queryParams: any) {
    this.router.navigate(['/admin', route], { queryParams });
  }

  openSearch() {
    this.modalRef = this.modalService.show(ModalSearchComponent, {
      backdrop: 'static',
      keyboard: false
    });
  }

  hasAccessToManipulatedFunctionality() {
    return this.user && this.user.features.find(f => f.name === 'Manipulados');
  }
}
