import { Directive, ElementRef, HostBinding, HostListener, Renderer2 } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { IsCpfPipe } from '@app/shared/pipes/is-cpf.pipe';
import * as CPF from '@fnando/cpf';
import { IsCompleteNamePipe, OnlyNumbersPipe } from '@app/shared/pipes';

@Directive({
  selector: '[appPatientSearch]',
  providers: [
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: PatientSearchDirective
    }
  ]
})
export class PatientSearchDirective implements Validator {
  private inputMaxLength: number;
  private onChange: (value: string) => void;
  private isCPF = new IsCpfPipe();
  private isCompleteName = new IsCompleteNamePipe();
  private onlyNumbers = new OnlyNumbersPipe();

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostBinding('attr.maxlength')
  get maxlength() {
    return this.inputMaxLength;
  }

  validate(c: AbstractControl): { [key: string]: any } {
    if (this.onlyNumbers.transform(c.value)) {
      const cpf = this.isCPF.transform(c.value);
      return cpf ? null : { cpf: true };
    }
    return this.isCompleteName.transform(c.value) ? null : { nome: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onChange = fn;
  }

  @HostListener('input', ['$event.target.value'])
  private _inputChange(text: string) {
    const { nativeElement } = this.el;
    if (this.isCPF.transform(text)) {
      nativeElement.value = CPF.format(nativeElement.value);
      this.inputMaxLength = 11;
    } else {
      this.renderer.removeAttribute(nativeElement, 'maxlength');
    }

    if (this.onChange) {
      this.onChange(text);
    }
  }
}
