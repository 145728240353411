import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-terms-and-conditions',
  templateUrl: './modal-terms-and-conditions.component.html',
  styleUrls: ['./modal-terms-and-conditions.component.scss']
})
export class ModalTermsAndConditionsComponent implements OnInit {
  @Input()
  isVisible = false;
  constructor() {}

  ngOnInit() {}

  openModal() {
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
  }
}
