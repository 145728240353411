import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { OnlyNumbersPipe } from '@app/shared/pipes/only-numbers.pipe';
import { numeroEspecial } from './helpers';

const INITIAL_LEN_TEL = 4;

@Pipe({
  name: 'formattedCel'
})
@Injectable()
export class FormattedCelPipe implements PipeTransform {
  transform(value): string {
    if (value && value !== '') {
      let cel;
      const receivedValue = value ? new OnlyNumbersPipe().transform(value) : value;
      if (numeroEspecial(receivedValue) && receivedValue.length === 8) {
        cel = receivedValue.substring(0, 4) + '-' + receivedValue.substring(4, receivedValue.length - 4);
      } else if (numeroEspecial(receivedValue) && receivedValue.length > 8) {
        cel =
          receivedValue.substring(0, 4) +
          '-' +
          receivedValue.substring(4, receivedValue.length - 4) +
          '-' +
          receivedValue.substring(receivedValue.length - 4, receivedValue.length);
      } else if (receivedValue.length > 2 && receivedValue.length <= 7) {
        cel = `(${receivedValue.substring(0, 2)}) ${receivedValue.substring(2)}`;
      } else if (receivedValue.length <= 2) {
        cel = receivedValue.substring(0, 7);
      } else if (receivedValue.length === 9) {
        cel = `(${receivedValue.substring(0, 2)}) ${receivedValue.substring(
          2,
          8
        )}-${receivedValue.substring(8, receivedValue.length)}`;
      } else {
        cel = `(${receivedValue.substring(0, 2)}) ${receivedValue.substring(
          2,
          7
        )}-${receivedValue.substring(7, receivedValue.length)}`;
      }
      return cel;
    }
  }

  transform2(value): string {
    if (value && value !== '') {
      let cel;
      const receivedValue = value ? new OnlyNumbersPipe().transform(value) : value;
      if (numeroEspecial(receivedValue) && receivedValue.length === 8) {
        cel = receivedValue.substring(0, 4) + '-' + receivedValue.substring(4, receivedValue.length - 4);
      } else if (numeroEspecial(receivedValue) && receivedValue.length > 8) {
        cel =
          receivedValue.substring(0, 4) +
          '-' +
          receivedValue.substring(4, receivedValue.length - 4) +
          '-' +
          receivedValue.substring(receivedValue.length - 4, receivedValue.length);
      } else if (receivedValue.length > 2 && receivedValue.length <= 7) {
        cel = `(${receivedValue.substring(0, 2)}) ${receivedValue.substring(2)}`;
      } else if (receivedValue.length <= 2) {
        cel = receivedValue.substring(0, 7);
      } else {
        cel = `(${receivedValue.substring(0, 2)}) ${receivedValue.substring(
          2,
          7
        )}-${receivedValue.substring(7, receivedValue.length)}`;
      }
      return cel;
    }
  }
}
