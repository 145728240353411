import { Patient } from './patient';

export class PetPatient {
  _id?: string;
  id?: string;
  name?: string;
  sex?: string;
  race?: string;
  isApproximateAge?: boolean;
  approximateAge?: number;
  dateOfBirth?: Date | string;
  weight?: number;
  fur?: string;
  species?: string;
  castration?: string;
  img?: string;
  temperament?: string;
  microchipNumber?: string;
  responsible?: Patient;
  doNotShowAgainPetDataConfirmationModal?: boolean;
}
