import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { SpecialtiesService } from '@app/modules/admin/listing/features-listing/specialties.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { CfmService } from '@app/modules/entry/services/cfm.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { Specialty } from '@app/shared/models';
import { BrazilState } from '@app/shared/models/brazil-state';
import { HealthProfessional } from '@app/shared/models/health-professional';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { BigboostService } from '@app/shared/services/bigboost.service';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { NzI18nService, pt_BR } from 'ng-zorro-antd/i18n';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-health-professional-form',
  templateUrl: './health-professional-form.component.html',
  styleUrls: ['./health-professional-form.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        group([animate(300, style({ height: 0 })), animate('400ms ease-in-out', style({ opacity: 0 }))])
      ]),
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        group([
          animate(300, style({ height: '*' })),
          animate('400ms ease-in-out', style({ opacity: 1 }))
        ])
      ])
    ])
  ]
})
export class HealthProfessionalFormComponent implements OnInit, OnDestroy {
  @Input() professionalType: string;
  @Output() formChange = new EventEmitter<FormGroup>();

  specialtiesOptions: Specialty[] = [];
  selectedSpecialties: string[] = [];
  loading: boolean;
  states: BrazilState[] = BrazilianStates;
  form: FormGroup;

  private lastCpf: string;
  private lastDateOfBirth: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject();

  constructor(
    private fb: FormBuilder,
    private cepService: CepService,
    private specialtyService: SpecialtiesService,
    private notification: AppToastService,
    private bigboostService: BigboostService,
    private i18n: NzI18nService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.getSpecialties();

    // add specialty after init
    this.addSpecialty();

    this.form.get('professionalType').setValue(this.professionalType);
    this.form.get('regionalCouncilNumber.label').setValue(this.regionalCouncilLabel);

    this.form.get('cpf').valueChanges.subscribe(cpf => {
      if (this.lastCpf !== cpf && !this.hasPendency) {
        this.verifiedCpf = false;
      }
    });

    this.form.get('birthdate').valueChanges.subscribe(birthdate => {
      const dateOfBirth = moment(birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      if (this.lastDateOfBirth !== dateOfBirth) {
        if (!this.hasPendency) {
          this.verifiedCpf = false;
        }
        this.form.get('dateOfBirth').setValue(dateOfBirth);
      }
    });

    this.form.get('email').valueChanges.subscribe(email => {
      this.form.get('email').setValue(email.toLowerCase(), { emitEvent: false });
    });

    this.form.get('emailConfirmation').valueChanges.subscribe(emailConfirmation => {
      this.form.get('emailConfirmation').setValue(emailConfirmation.toLowerCase(), { emitEvent: false });
    });

    this.i18n.setLocale(pt_BR);

    this.formChange.emit(this.form);
  }

  private buildForm() {
    this.form = this.fb.group(
      {
        professionalType: ['', Validators.required],
        name: ['', [Validators.required, ValidationService.nomeValidator]],
        fullname: ['', [Validators.required, ValidationService.nomeValidator]],
        cpf: ['', [Validators.required, ValidationService.cpfValidator]],
        sex: [null, Validators.required],
        birthdate: [
          '',
          [
            Validators.required,
            ValidationService.dateValidator(),
            ValidationService.birthdateValidator()
          ]
        ],
        dateOfBirth: [
          '',
          [Validators.required, ValidationService.dateValidator({ format: 'YYYY-MM-DD' })]
        ],
        membership: this.fb.group({
          mothersName: ['']
        }),
        regionalCouncilNumber: this.fb.group({
          number: ['', Validators.required],
          uf: ['', Validators.required],
          label: ['', Validators.required]
        }),
        mapaSipeagro: [''],
        cellphone: ['', Validators.required],
        telephone: [''],
        email: ['', [Validators.required, Validators.email]],
        emailConfirmation: ['', [Validators.required, Validators.email]],
        specialties: this.fb.array([], Validators.required),
        commercialData: this.fb.array([this.buildCommercialForm()]),
        pendency: [null],
        verifiedCpf: [false]
      },
      { validator: ValidationService.emailMatch }
    );
  }

  get isDentist() {
    return this.professionalType === 'dentist';
  }

  get isVeterinarian(): boolean {
    return this.professionalType === 'veterinarian';
  }

  get currentDate() {
    const now = new Date();
    const dd = String(now.getDate()).padStart(2, '0');
    const mm = String(now.getMonth() + 1).padStart(2, '0');
    const yyyy = now.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  get hasPendency() {
    return this.form.value.pendency;
  }

  get verifiedCpf() {
    return this.form.get('verifiedCpf').value;
  }

  set verifiedCpf(value: boolean) {
    this.form.get('verifiedCpf').setValue(value);
  }

  get cpfCtrl() {
    return this.form.get('cpf');
  }

  get dateCtrl() {
    return this.form.get('birthdate');
  }

  get commercialForm(): FormArray {
    return <FormArray>this.form.get('commercialData');
  }

  get specialtiesForm(): FormArray {
    return <FormArray>this.form.get('specialties');
  }

  get regionalCouncilLabel() {
    return HealthProfessional.getRegionalCouncilLabel(this.professionalType);
  }

  private getSpecialties() {
    this.specialtyService.getSpecialtiesByType(this.professionalType).subscribe(specialties => {
      specialties.forEach(function(item, i) {
        if (item.code === 1) {
          specialties.splice(i, 1);
          specialties.unshift(item);
        }
      });
      this.specialtiesOptions = specialties;
    });
  }

  addSpecialties() {
    if (this.selectedSpecialties.length <= 3) {
      const specialtiesArray = this.selectedSpecialties.map(id => this.buildSpecialtyForm(id));
      this.form.setControl('specialties', this.fb.array(specialtiesArray, Validators.required));
    } else if (this.selectedSpecialties.length > 3) {
      this.selectedSpecialties.pop();
      this.notification.notify('warning', 'Aviso', 'Você pode escolher no máximo 3 especialidades');
    }
  }

  @nativeAsync
  async consultCpf() {
    const { cpf, birthdate: date } = this.form.value;
    // A data que vem no formulário não vem no formato para que possa ser formatada.
    // Essa linha vai fazer com que a data fique no formato 00/00/0000.
    const dateOfBirth = moment(date, 'DDMMYYYY').format('YYYY-MM-DD');

    const age = moment().diff(moment(date, 'DDMMYYYY'), 'years', false);
    if (age < 18) {
      this.notification.notify('warning', 'Cadastro proibido para menores');
      this.form.get('birthdate').setErrors({ invalidBirthdate: true });
      return;
    }

    if (
      this.cpfCtrl.valid &&
      this.dateCtrl.valid &&
      (this.lastCpf !== cpf || this.lastDateOfBirth !== dateOfBirth || !this.verifiedCpf)
    ) {
      try {
        this.loading = true;
        const consult = await this.bigboostService.consultCpf(cpf, dateOfBirth).toPromise();
        this.loading = false;
        this.handleConsultCpf(consult);
      } catch (err) {
        console.error(err);
        this.loading = false;
        if (err.status === 404) {
          this.notification.notify(
            'error',
            'Error ao consultar CPF',
            'CPF não localizado na Receita Federal. Verifique as informações digitadas e tente novamente. Em caso de dúvidas, entre em contato com nosso atendimento',
            8000
          );
          return;
        }

        if (err.status === 500) {
          this.notification.notify(
            'error',
            'Error ao consultar CPF',
            'Plataforma de consulta de CPF com erro ao tentar realizar consulta do CPF',
            8000
          );
          return;
        }
        const consult = {
          status: -1,
          cpf,
          erroCodigo: err.status,
          erro: 'A importação de dados do cpf está com instabilidade'
        };
        this.form.get('pendency').setValue(consult);
        if (err.status === 401) {
          this.notification.notify(
            'warning',
            'Aviso',
            'Data de nascimento não corresponde ao CPF informado'
          );
          this.form.get('birthdate').setErrors({ nonMatchingCpf: true });
          this.verifiedCpf = false;
        } else {
          this.handleConsultCpf(consult);
        }
      }
      this.lastCpf = cpf;
      this.lastDateOfBirth = dateOfBirth;
    }
  }

  private handleConsultCpf(consult: any) {
    this.form.get('name').reset();
    this.form.get('name').enable();

    if (consult.status === 1) {
      this.form.get('cpf').disable();
      this.form.get('birthdate').disable();
      this.form.get('fullname').disable();
      this.form.get('pendency').setValue(null);
      this.setForm(consult);
    } else {
      this.form.get('fullname').enable();
      this.form.get('pendency').setValue(consult);
      this.resetForm();
      if (consult.erro) {
        this.notification.notify(
          'warning',
          'Aviso',
          'A importação de dados do CPF está com instabilidade, mas você poderá se cadastrar preenchendo os campos manualmente.'
        );
      }
    }
    this.verifiedCpf = true;
  }

  private setForm(consult: any) {
    this.form.get('fullname').setValue(consult.nome);
    this.form.get('name').setValue(consult.nome);
    this.form.get('sex').setValue(consult.genero);
    this.form.get('membership.mothersName').setValue(consult.mae);

    this.lastCpf = this.form.value.cpf;
    this.lastDateOfBirth = this.form.value.dateOfBirth;
  }

  private resetForm() {
    this.form.get('fullname').reset();
    this.form.get('sex').reset();
    this.form.get('membership.mothersName').reset();
  }

  @nativeAsync
  async onKey(event: any, addressGroup: FormGroup) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        addressGroup.patchValue({
          uf: data.uf,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          number: null,
          cep: data.cep
        });
      }
    }
  }

  trackById(index, specialty: Specialty) {
    return specialty ? specialty.code : null;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  addSpecialty() {
    this.specialtiesForm.push(this.buildSpecialtyForm());
  }

  addComercial() {
    this.commercialForm.push(this.buildCommercialForm());
  }

  private buildSpecialtyForm(id: string = null) {
    if (this.isDentist || this.isVeterinarian) {
      return this.fb.group({
        specialty: [id, Validators.required]
      });
    }
    return this.fb.group({
      specialty: [id, Validators.required],
      rqe: [null, this.isDentist || this.isVeterinarian ? null : Validators.required],
      notExists: [null]
    });
  }

  private buildCommercialForm() {
    const commercialForm = this.fb.group({
      email: ['', Validators.email],
      name: ['', Validators.required],
      logo: [''],
      telephone: ['', Validators.required],
      cellphone: [''],
      address: this.fb.group({
        uf: [null, Validators.required],
        city: ['', Validators.required],
        complement: [''],
        number: ['', Validators.required],
        street: ['', Validators.required],
        neighborhood: ['', Validators.required],
        cep: ['', [Validators.required, ValidationService.cepValidator]]
      })
    });

    commercialForm.get('email').valueChanges.subscribe(email => {
      commercialForm.get('email').setValue(email.toLowerCase(), { emitEvent: false });
    });

    return commercialForm;
  }

  checkRQE(rqeForm: FormGroup, disable: boolean) {
    if (disable) {
      rqeForm.setValidators(null);
      rqeForm.setValue(null);
      rqeForm.disable();
    } else {
      rqeForm.setValidators(Validators.required);
      rqeForm.markAsPristine();
      rqeForm.enable();
    }
  }

  cancel() {
    this.buildForm();
    this.formChange.emit(this.form);
  }
}
