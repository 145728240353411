import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { User } from '@app/shared/models/decodedLoginToken';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProfessionalType } from '../models/professional-type.enum';
import { UserType } from '../models/user-type.enum';

@Injectable({
  providedIn: 'root'
})
export class IsVeterinarianResolver implements Resolve<boolean> {
  constructor(
    private authService: AuthService,
    private healthProfessionalService: HealthProfessionalService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.user.userType === UserType.HEALTH_PROFESSIONAL) {
      return this.healthProfessionalService
        .getHealthProfessional(this.user._id)
        .pipe(
          switchMap(prescriber =>
            of(prescriber && prescriber.professionalType === ProfessionalType.VETERINARIAN)
          )
        );
    }
    return of(false);
  }

  get user(): User {
    return this.authService.user();
  }
}
