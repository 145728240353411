import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { PetPatient } from '@app/shared/models/pet-patient';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalAddPatientComponent } from '../modal-add-patient/modal-add-patient.component';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';

@Component({
  selector: 'app-modal-select-pet',
  templateUrl: './modal-select-pet.component.html',
  styleUrls: ['./modal-select-pet.component.scss']
})
export class ModalSelectPetComponent implements OnInit {
  @Input() pets: any;
  @Input() prescriber: any;
  @Input() responsible: any;
  @Output() submit = new EventEmitter<any>();

  private prescriberPets: PetPatient[] = [];

  constructor(
    public modalRef: BsModalRef,
    private healthProfessionalService: HealthProfessionalService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.healthProfessionalService
      .getPetsByResponsible(this.responsible._id)
      .subscribe((pets: PetPatient[]) => (this.prescriberPets = pets));
  }
  @nativeAsync
  async selectOrAddPatient(pet: PetPatient) {
    if (!this.hasDependent(pet)) {
      await this.addPetPatient(pet);
    }
    this.submit.emit({
      pet,
      isAdding: false
    });
  }

  @nativeAsync
  async addPetPatient(pet: PetPatient) {
    try {
      await this.healthProfessionalService.addPetPatient(pet._id).toPromise();
    } catch (err) {
      console.error(err);
    }
  }

  hasDependent(pet: PetPatient): boolean {
    const pets = this.prescriberPets;
    return pets.some(p => p._id === pet._id);
  }

  showModalAddPatient() {
    const initialState = {
      prescriber: this.prescriber,
      patient: this.responsible,
      isResponsible: true,
      existingPatient: true
    };
    const modal = this.modalService.show(ModalAddPatientComponent, {
      class: 'modal-lg',
      initialState,
      backdrop: 'static',
      keyboard: false
    });

    modal.content.updateListPatientEvent.subscribe((pet: PetPatient) => {
      modal.hide();
      this.modalRef.hide();
      this.submit.emit({
        pet,
        isAdding: true
      });
    });
  }
}
