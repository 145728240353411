import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { OnlyNumbersPipe } from '@app/shared/pipes/only-numbers.pipe';

@Pipe({
  name: 'isTel'
})
@Injectable()
export class IsTelPipe implements PipeTransform {
  transform(value): boolean {
    if (!!value) {
      const onlyNumbers = new OnlyNumbersPipe();
      const tel = onlyNumbers.transform(value);
      const ddd = parseInt(tel.substring(0, 2), 10);
      const telWithoutDDD = tel.substring(2, tel.length);
      if (ddd <= 10 || ddd >= 100) {
        return false;
      }
      return !(
        (telWithoutDDD.length !== 8 && telWithoutDDD.length !== 9) ||
        (telWithoutDDD.length === 9 && [7, 8, 9].indexOf(parseInt(telWithoutDDD.charAt(0), 10)) === -1)
      );
    }
  }
}
