import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { first } from 'rxjs/operators';
import { AppToastService } from './shared/services/app-toast.service';
import { SweetalertService } from './shared/services/sweetalert.service';
import { SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  // this will be replaced by actual hash post-build.js
  private _versaoAtual = '{{VERSAO_ATUAL}}';

  /** 5 MINUSTOS */
  private readonly _TEMPO_VERICACAO_VERSAO = 30000;

  constructor(
    private http: HttpClient,
    private notification: AppToastService,
    private _sweetalertService: SweetalertService
  ) {}

  /**
   * Checks in every set frequency the version of frontend application
   */

  public iniciaVerificacaoVersao() {
    const env = environment;
    if (env && env.local) {
      return;
    }
    this.verificaVersao();
    setInterval(() => this.verificaVersao(), this._TEMPO_VERICACAO_VERSAO);
  }

  verificaVersao() {
    const url = `${window.location.origin}/version.json?t=${new Date().getTime()}`;
    this.http
      .get(url)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (!response || (response && !response.hash)) {
            return;
          }
          const versao = response.hash;
          const versaoIsAlteradao: boolean = this.versaoIsAlterada(this._versaoAtual, versao);
          if (versaoIsAlteradao) {
            console.warn('NOVA VERSÃO DETECTADA: ', response.hash);

            this._sweetalertService
              .confirmDialog(
                'NOVA VERSÃO DETECTADA',
                `Uma nova versão do sistema foi disponibilizada, a página precisa ser recarregada!`,
                'warning'
              )
              .then(({ isConfirmed }: SweetAlertResult) => {
                if (isConfirmed) {
                  location.reload();
                } else {
                  this.notification.notify(
                    'warning',
                    'Você optou por cancelar a atualização da plataforma.',
                    'Esta ação poderá acarretar problemas na aplicação. Salve seus documentos e atualize a página clicando na tecla F5 no computador ou deslize o dedo para baixo de estiver navegando em um dispositivo mobile.',
                    50000
                  );
                }
              });
          }
          this._versaoAtual = versao;
        },
        err => console.error('não foi possível obter a versão', err)
      );
  }

  private versaoIsAlterada(versaoAtual: string, novaVersao: string): boolean {
    if (!versaoAtual || versaoAtual === '{{VERSAO_ATUAL}}') {
      return false;
    }
    return versaoAtual !== novaVersao;
  }
}
