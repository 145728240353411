import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-icon-menu',
  templateUrl: './icon-menu.component.html',
  styleUrls: ['./icon-menu.component.scss']
})
export class IconMenuComponent implements OnInit {
  static activeMenu = false;
  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}

  ngOnInit() {}

  toggleMenu() {
    IconMenuComponent.activeMenu = !IconMenuComponent.activeMenu;
    if (IconMenuComponent.activeMenu) {
      this.renderer.setStyle(this.document.getElementById('siderbar-menu'), 'left', '0');
      this.renderer.setStyle(this.document.getElementById('siderbar-menu-blur'), 'left', '0');
    } else {
      this.renderer.setStyle(this.document.getElementById('siderbar-menu'), 'left', '-330px');
      this.renderer.setStyle(this.document.getElementById('siderbar-menu-blur'), 'left', '-100%');
    }
  }
}
