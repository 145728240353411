import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { UserService } from '@app/modules/user/user.service';
import { AdditionalFeatureAccept } from '@app/shared/models';
import { ThemeService } from '@app/theme';

@Component({
  selector: 'app-alternative-navbar',
  templateUrl: './alternative-navbar.component.html',
  styleUrls: ['./alternative-navbar.component.scss']
})
export class AlternativeNavbarComponent implements OnInit {
  @Input() title: string;
  @Input() titleIcon: string;
  completeUser: any;
  avatarB64: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private themeService: ThemeService,
    private pharmacyService: PharmacyService,
    private userService: UserService
  ) {}

  ngOnInit() {
    if (this.user.userType === 'pharmacy') {
      this.pharmacyService
        .getPharmacyByUserId(this.user._id)
        .subscribe(res => (this.completeUser = res));
    }

    this.userService.getAvatar().subscribe(avatar => (this.avatarB64 = avatar));
  }

  get user() {
    return this.authService.user();
  }

  get hasCipFeature() {
    const cipFeature = this.user && this.user.features.find(f => f.name === 'CIP');
    return cipFeature && cipFeature.accept === AdditionalFeatureAccept.YES;
  }

  redirect() {
    const { permission, userType } = this.user;
    const url = permission === 'basic' || userType !== 'admin' ? '/dashboard' : '/admin';
    window.location.href = url;
  }

  toggle() {
    if (this.isThemeActiveReceitaDigital()) {
      this.themeService.setTheme('theme-mrd');
    } else {
      this.themeService.setTheme('theme-receita-digital');
    }
  }

  isThemeActiveReceitaDigital() {
    const active = this.themeService.getActiveTheme();
    return active.name === 'theme-receita-digital';
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['entry/login']);
  }
}
