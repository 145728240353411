import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { PharmacistService } from '../pharmacist/services/pharmacist.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private pharmacistService: PharmacistService,
    private router: Router
  ) {}

  @nativeAsync
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user = this.authService.user();
    const selectedPharmacy = localStorage.getItem('extra_data');
    if (user.userType === 'pharmacist' && !selectedPharmacy) {
      const pharmacies = await this.pharmacistService.getPharmacies(user._id).toPromise();
      if (pharmacies.length === 1) {
        const pharmacy = pharmacies[0];
        localStorage.setItem(
          'extra_data',
          JSON.stringify({ pharmacyUserId: pharmacy.userId, pharmacyId: pharmacy._id })
        );
      } else {
        this.router.navigate(['/entry/pharmacy-options'], { state: { pharmacies } });
      }
    }
    return true;
  }
}
