import { Theme } from './symbols';

export const themeMrd: Theme = {
  title: 'Meu Receituário',
  name: 'theme-mrd',
  favicon: 'assets/img/mrd/logo-03.png',
  properties: {
    '--color-white': '#FFFFFF',
    '--color-black': '#000000',
    '--color-alert': '#FFFF00',
    '--color-icon-menu': '#FFFFFF',
    '--color-title': '#494949',
    '--color-hover-item-menu': '#ccc',
    '--bg-color-primary': '#EB5E54',
    '--on-bg-color-primary': '#FFFFFF',
    '--bg-color-secondary': '#F69487',
    '--on-bg-color-secondary': '#FFFFFF',
    '--bg-color-cinza-escuro': '#494949',
    '--on-bg-color-cinza-escuro': '#FFFFFF',
    '--bg-color-cinza-intermediario': '#797979',
    '--on-bg-color-cinza-intermediario': '#FFFFFF',
    '--bg-color-cinza-claro': '#EBEBEB',
    '--on-bg-color-cinza-claro': '#4B4945',
    '--bg-footer': '#494949',

    '--background-header': '#494949',
    '--on-background-header': '#FFF',
    '--background-section': '#797979',
    '--background-card-prescricao': '#F59487',
    '--background-card-historico': '#494949',
    '--background-card-pacientes': '#EBEBEB',
    '--color-card-pacientes': '#494949',
    '--background-card-meus-dados': '#EB5E53',
    '--background-card-tele-consulta': '#EBEBEB',
    '--backgorund-card-agenda-virtual': '#17C6A6',
    '--background-card-modelo-favorito': '#acacac',

    '--color-label-input': '#494949',
    '--color-input-border-hover': '#ccc',
    '--bg-input-text': '#fdece9',

    '--bg-color-btn-success': '#797979',
    '--bd-color-btn-success': '#494949',
    '--color-btn-success': '#FFF',
    '--bg-color-btn-save': '#EB5E54',

    '--bg-select': '#e5e5e5',
    '--bd-select': '#494949',
    '--color-select-placeholder': '#000',

    '--bg-btn-menu': '#d72a12',

    '--bg-data-profile': '#EB5E53',
    '--bg-sub-header-pacientes': '#acacac',

    '--bg-modal-header': '#F59487',

    '--color-primary': '#d72a12'
  }
};
