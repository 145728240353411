import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PatientRecord } from '@app/shared/models/patient-record';
import { PatientRecordPage } from '@app/shared/models/patient-record-page';
import { PatientRecordRequest } from '@app/shared/models/patient-record-request';
import { Tag } from '@app/shared/models/tag';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class PatientRecordService {
  constructor(private http: HttpClient) {}

  getTags() {
    return this.http.get<Tag[]>(`${environment.apiRoot}tags`);
  }

  getRecords(patientId: string, page: number, limit: number) {
    const params = { patientId, page, limit } as any;
    return this.http.get<PatientRecordPage>(`${environment.apiRoot}patient-records`, { params });
  }

  submitRecord(record: PatientRecordRequest) {
    return this.http.post<PatientRecord>(`${environment.apiRoot}patient-records`, record);
  }

  updateRecord(record: PatientRecord) {
    return this.http.patch<PatientRecord>(`${environment.apiRoot}patient-records/${record.id}`, record);
  }

  deleteRecord(id: string) {
    return this.http.delete(`${environment.apiRoot}patient-records/${id}`);
  }

  mergeRecords(records: PatientRecord[], mainTag: string) {
    return this.http.post<PatientRecord>(`${environment.apiRoot}patient-records/merge`, {
      records,
      mainTag
    });
  }
}
