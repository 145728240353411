import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormattedCepPipe } from '@app/shared/pipes/formatted-cep.pipe';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCep]'
})
export class CepDirective {
  constructor(private ngControl: NgControl) {}

  private formattedCep = new FormattedCepPipe();

  @HostListener('input', ['$event'])
  onInputChange(event: Event | any) {
    this.cep(event.target.value);
  }

  cep(value: string): void {
    this.ngControl.control.setValue(this.formattedCep.transform(value));
  }
}
