import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[onlyNumbers]'
})
export class OnlyNumbersDirective {
  regexStr = '^[0-9]*$';

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode == 65 && event.ctrlKey === true) ||
      // Allow: Ctrl+C
      (event.keyCode == 67 && event.ctrlKey === true) ||
      // Allow: Ctrl+V
      (event.keyCode == 86 && event.ctrlKey === true) ||
      // Allow: Ctrl+X
      (event.keyCode == 88 && event.ctrlKey === true) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    const ch = String.fromCharCode(event.keyCode);
    const regEx = new RegExp(this.regexStr);
    if (regEx.test(ch)) {
      return;
    } else {
      event.preventDefault();
    }
  }
}
