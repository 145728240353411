import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-resend-documento-empty',
  templateUrl: './modal-resend-documento-empty.component.html',
  styleUrls: ['./modal-resend-documento-empty.component.scss']
})
export class ModalResendDocumentoEmptyComponent implements OnInit {
  @Output() afterInit = new EventEmitter<any>();
  @Output() goBack = new EventEmitter<any>();

  code: string;

  constructor(private modalref: BsModalRef, private activateRouter: ActivatedRoute) {}

  ngOnInit() {
    this.afterInit.emit();
  }

  back() {
    this.goBack.emit();
    this.close();
  }

  close() {
    this.modalref.hide();
  }

  get isVeterinarian(): boolean {
    const prefixCode = this.code.substring(0, 4);
    return (
      prefixCode === 'VETP' || prefixCode === 'VETA' || prefixCode === 'VETE' || prefixCode === 'VETO'
    );
  }

  get title() {
    if (this.isVeterinarian) {
      return this.getTitlePet();
    } else {
      switch (this.code[0]) {
        case 'A':
          return 'Atestado não reenviado';
        case 'E':
          return 'Pedido de Exame não reenviado';
        case 'O':
          return 'Documento não reenviado';
        default:
          return 'Receita não reenviada';
      }
    }
  }

  getTitlePet() {
    const prefixCode = this.code.substring(0, 4);
    switch (prefixCode) {
      case 'VETA':
        return 'Atestado não reenviado';
      case 'VETE':
        return 'Pedido de Exame não reenviado';
      case 'VETO':
        return 'Documento não reenviado';
      default:
        return 'Receita não reenviada';
    }
  }
}
