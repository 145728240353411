import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NgxMaskModule } from 'ngx-mask';
import { FileStepComponent } from './form-steps/file-step/file-step.component';
import { FormStepsComponent } from './form-steps/form-steps.component';
import { SubaccountStepComponent } from './form-steps/subaccount-step/subaccount-step.component';
import { SubscriptionStepComponent } from './form-steps/subscription-step/subscription-step.component';
import { IntroComponent } from './intro/intro.component';
import { ModalTelemedicineContractComponent } from './modal-telemedicine-contract.component';

@NgModule({
  declarations: [
    ModalTelemedicineContractComponent,
    IntroComponent,
    FormStepsComponent,
    FileStepComponent,
    SubaccountStepComponent,
    SubscriptionStepComponent
  ],
  imports: [SharedModule, NgxMaskModule.forRoot(), CreditCardDirectivesModule],
  exports: [ModalTelemedicineContractComponent]
})
export class ModalTelemedicineContractModule {}
