import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '@app/modules/user/user.service';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-contacts-data-form',
  templateUrl: './contacts-data-form.component.html'
})
export class ContactsDataFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() showAlertMsg = true;

  isExternalRegister = false;

  constructor(private router: Router, private userService: UserService) {}

  ngOnInit() {
    this.isExternalRegister = this.router.url === '/entry/register/patient';
    if (this.isExternalRegister) {
      this.form.get('cellphone').setValidators([Validators.required]);
      this.form.get('email').setValidators([Validators.required]);
    }

    const emailControl = this.form.get('email');
    this.form
      .get('email')
      .valueChanges.pipe(
        map(email => {
          const value = email && email.toLowerCase();
          emailControl.setValue(value, { emitEvent: false });
          return value;
        }),
        distinctUntilChanged(),
        filter(_ => emailControl.valid && emailControl.value),
        debounceTime(environment.debounceTime),
        switchMap(email => this.userService.checkEmailExists(email).pipe(catchError(err => of(err))))
      )
      .subscribe(res => {
        if (res.status === 200) {
          emailControl.setErrors({ exists: true });
        } else if (res.status === 404) {
          emailControl.setErrors(null);
        } else if (res.status === 400) {
          emailControl.setErrors({ invalid: true });
        }
      });
  }
}
