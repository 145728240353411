import { PetPatient } from '@app/shared/models/pet-patient';
import { Address } from './address';
import { User } from './decodedLoginToken';
import { Membership } from './membership';
import { Pendency } from './pendency';

export class Patient {
  _id?: string;
  id?: string;
  userId?: string;
  name?: string;
  fullname?: string;
  cpf?: string;
  identity?: string;
  telephone?: string;
  cellphone?: string;
  email?: string;
  sex?: string;
  dateOfBirth?: Date | string;
  membership?: Membership;
  address?: Address;
  user?: User;
  img?: string;
  pendency?: Pendency;
  responsible?: Patient;
  dependents?: Patient[];
  pets?: PetPatient[];
  tenantId?: string;
  exists?: boolean;
  emailContact?: string;
  womenOver40?: boolean;

  loadingRevalidate?: boolean;
  loadingTenant?: boolean;
}
