import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { Furs } from '@app/shared/data/Fur';
import { BrazilState, Fur, Patient } from '@app/shared/models';
import { PetPatient } from '@app/shared/models/pet-patient';
import { Race } from '@app/shared/models/races';
import { Species } from '@app/shared/models/species';
import { markFormGroup } from '@app/utils/markFormGroup';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PetPatientService } from '../../services/pet-patient.service';
import { DateUtils } from './../../../../shared/data/DateUtils';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';

@Component({
  selector: 'app-modal-edit-pet',
  templateUrl: './modal-edit-pet.component.html',
  styleUrls: ['./modal-edit-pet.component.scss']
})
export class ModalEditPetComponent implements OnInit {
  @Input() pet: PetPatient;
  @Input() isVeterinarian = false;
  @Input() isConfirmation: boolean;
  @Input() displayAlert = true;
  @Output() submit = new EventEmitter<Patient>();
  @Output() onClose = new EventEmitter<Patient>();

  states: BrazilState[] = BrazilianStates;
  loading = false;
  species: Species[];
  races: Race[];
  furs: Fur[] = Furs;

  labelAge = 'Idade';

  form = this.fb.group({
    _id: [null],
    name: ['', Validators.required],
    species: ['', Validators.required],
    sex: ['', Validators.required],
    race: ['', Validators.required],
    isApproximateAge: [],
    approximateAgeLabel: [
      {
        value: '',
        disabled: true
      }
    ],
    dateOfBirth: ['', Validators.required],
    weight: [null],
    fur: [''],
    castration: [false, Validators.required],
    temperament: [''],
    microchipNumber: [''],
    responsible: this.fb.group({
      _id: [''],
      cpf: ['', [Validators.required, ValidationService.cpfValidator]],
      fullname: ['', Validators.required],
      name: ['', Validators.required],
      sex: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      membership: this.fb.group({
        mothersName: ['']
      }),
      cellphone: [''],
      telephone: [''],
      email: ['', Validators.email],
      emailContact: ['', Validators.email],
      address: this.fb.group({
        uf: [''],
        city: [''],
        complement: [''],
        number: [''],
        street: [''],
        neighborhood: [''],
        cep: ['']
      }),
      pendency: [null]
    }),
    doNotShowAgainPetDataConfirmationModal: [false]
  });

  constructor(
    private modalRef: BsModalRef,
    private fb: FormBuilder,
    private cepService: CepService,
    private petPatientService: PetPatientService
  ) {}

  @nativeAsync
  async ngOnInit() {
    this.species = await this.petPatientService.getSpecies().toPromise();
    this.patchForm();

    this.checkApproximateAge(this.pet.isApproximateAge);

    this.form.get('dateOfBirth').valueChanges.subscribe(dateOfBirth => {
      if (dateOfBirth) {
        const diffBetweenDate = DateUtils.diffBetweenDate(new Date(), dateOfBirth);

        this.form.get('approximateAgeLabel').setValue(diffBetweenDate.result);
      }
    });

    if (!this.isVeterinarian) {
      this.form.get('weight').disable();
      this.form.get('fur').disable();
      this.form.get('castration').disable();
      this.form.get('temperament').disable();
      this.form.get('microchipNumber').disable();
    }
  }

  @nativeAsync
  private async patchForm() {
    this.form.patchValue(this.pet);

    if (this.pet.species) {
      const speciesId = this.getSpeciesId(this.pet.species);
      this.races = await this.petPatientService.getRaces(speciesId).toPromise();
    }

    const date = new Date(this.pet.dateOfBirth);
    this.form.get('dateOfBirth').setValue(date.toISOString().substring(0, 10));

    this.form.get('responsible').patchValue(this.pet.responsible);
  }

  get cpfCtrl() {
    return this.form.get('cpf');
  }

  get dateCtrl() {
    return this.form.get('dateOfBirth');
  }

  get currentDate() {
    const now = new Date();
    const dd = String(now.getDate()).padStart(2, '0');
    const mm = String(now.getMonth() + 1).padStart(2, '0');
    const yyyy = now.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  get isDependent() {
    return this.pet.responsible;
  }

  checkRepeatName(value: boolean) {
    if (value) {
      this.form.get('name').setValue(this.form.get('fullname').value);
      this.form.get('name').disable();
    } else {
      this.form.get('name').enable();
    }
  }

  @nativeAsync
  async onKey(event: any) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        this.form.controls['address'].patchValue({
          uf: data.uf,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          number: null,
          cep: data.cep
        });
      }
    }
  }

  submitForm() {
    markFormGroup(this.form);
    const form = this.form.getRawValue();

    if (this.form.valid) {
      this.modalRef.hide();
      this.submit.emit(form);
    }
  }

  close() {
    this.modalRef.hide();
    this.onClose.emit();
  }

  private getSpeciesId(title: string) {
    const species = this.species.find(s => s.title === title);
    return species._id;
  }
  @nativeAsync
  async setSpecies(title: any) {
    const speciesId = this.getSpeciesId(title);
    await this.getRaces(speciesId);
  }
  @nativeAsync
  async getRaces(speciesId: string) {
    this.form.get('race').setValue('');
    this.races = await this.petPatientService.getRaces(speciesId).toPromise();
  }

  checkApproximateAge(value: boolean) {
    if (value) {
      this.labelAge = 'Idade Aproximada';
    } else {
      this.labelAge = 'Idade';
    }
  }
}
