import { Component, Input, ViewChild } from '@angular/core';
import { MyPieChart } from '@app/shared/models/my-pie-chart';
import { ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-my-pie-chart',
  templateUrl: './my-pie-chart.component.html',
  styleUrls: ['./my-pie-chart.component.scss']
})
export class MyPieChartComponent {
  @Input() title: string;

  @ViewChild(BaseChartDirective) baseChart: any;

  pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 12
      }
    }
  };

  private _chart: MyPieChart;

  constructor() {}

  @Input()
  set chart(chart: MyPieChart) {
    this._chart = chart;
    if (this.title) {
      let text = this.title;
      if (this.total) {
        text += ` (${this.total})`;
      }
      this.pieChartOptions.title = {
        text,
        display: true
      };
      if (this.baseChart) {
        this.baseChart.refresh();
      }
    }
  }

  get chart() {
    return this._chart;
  }

  get total() {
    return this.chart && this.chart.data.filter(d => !isNaN(d)).reduce((a, b) => a + b, 0);
  }
}
