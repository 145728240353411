import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { Patient } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { environment } from '@env/environment';
import { ReplaySubject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil
} from 'rxjs/operators';

@Component({
  selector: 'app-float-button',
  templateUrl: './float-button.component.html',
  styleUrls: ['./float-button.component.scss']
})
export class FloatButtonComponent implements OnInit {
  visible = false;
  patientRecordForm: FormGroup;
  patient: any;
  patients: Patient[];
  patientRecordStyle = {
    recordsArea: { height: '50vh' }
  };

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private healthProfessionalService: HealthProfessionalService
  ) {
    this.patientRecordForm = this.fb.group({
      _id: this.fb.control(''),
      name: this.fb.control('')
    });
  }

  ngOnInit() {
    this.onChange();
  }

  get user(): User {
    return this.authService.user();
  }

  onChangePatient(patient: Patient): void {
    this.patient = patient;
    this.patientRecordForm.get('_id').setValue(patient._id);
  }

  setVisible() {
    this.visible = !this.visible;
  }

  resetPatient() {
    this.patient = undefined;
    this.patientRecordForm.get('_id').setValue('');
    this.patientRecordForm.get('name').setValue('');
  }

  onChange(): void {
    let inputValue = '';

    this.patientRecordForm
      .get('name')
      .valueChanges.pipe(
        debounceTime(environment.debounceTime),
        distinctUntilChanged(),
        switchMap(value => {
          inputValue = value;
          return this.getPatients(value);
        }),
        map((res: any) => {
          if (res) {
            if (res.length > 0) {
              return res;
            }
          }
          this.patientRecordForm.get('_id').setValue(null);
        }),
        takeUntil(this.destroyed$),
        catchError((err, source) => {
          return source.pipe(startWith([]));
        })
      )
      .subscribe(patients => {
        this.patients = patients;
      });
  }

  private getPatients(value: string) {
    return this.healthProfessionalService.getPatientsByUserId(value, this.user._id);
  }

  private onlyNumbers(value: String): Boolean {
    return value.match(/^[0-9]+$/) != null;
  }
}
