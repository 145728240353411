import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-covid',
  templateUrl: './modal-covid.component.html',
  styleUrls: ['./modal-covid.component.scss']
})
export class ModalCovidComponent implements OnInit {
  @Output() searchEvent = new EventEmitter();
  @Input() pharmacy;
  public covidForm: FormGroup;

  constructor(
    private pharmacyService: PharmacyService,
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private notification: AppToastService
  ) {
    this.covidForm = this.fb.group({
      covid: this.fb.control('', Validators.required)
    });
  }

  ngOnInit() {
    const valCovidDB = this.pharmacy.covid;
    this.covidForm.controls.covid.setValue(valCovidDB);
    this.covidForm.patchValue(this.covidForm.controls.covid);
  }

  handleClick() {
    this.modalRef.hide();
  }

  @nativeAsync
  async save(param) {
    if (param === '') {
      this.notification.notify('warning', 'Atenção', 'Valor do teste Covid não informado');
    } else {
      const pharmacyId = this.pharmacy._id;
      this.pharmacy.covid = this.covidForm.get('covid').value;
      const pharmacy = await this.pharmacyService.update(pharmacyId, this.pharmacy).toPromise();
      this.modalRef.hide();
      this.notification.notify('success', 'Informação atualizada com sucesso .', null);
    }
  }
}
