import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CidService } from '@app/modules/document/services/cid.service';
import { Cid } from '@app/shared/models/cid';
import { ColorSetting } from '@app/shared/models/color-setting';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-cid-form',
  templateUrl: './cid-form.component.html',
  styleUrls: ['./cid-form.component.scss']
})
export class CidFormComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() authorizedByPatient = false;
  @Input() showDescription = false;
  @Input() items = [];
  @Input() colorSetting: ColorSetting;

  @Output() onClickRemoveCardCid = new EventEmitter<void>();
  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  inputSearch = new FormControl();
  isLoading = false;

  cids: Cid[] = [];

  constructor(
    private cidService: CidService,
    private notification: AppToastService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form.addControl(
      'cid',
      this.fb.group({
        authorizedByPatient: [this.authorizedByPatient],
        showDescription: [this.showDescription],
        items: new FormArray([], Validators.required)
      })
    );

    if (this.items && this.items.length) {
      this.items.forEach(item => {
        this.addCid(item);
      });
    }

    this.listenSearchInput();
    this.formEmitter.emit(this.form);
  }

  ngOnDestroy(): void {
    this.form.removeControl('cid');
    this.formEmitter.emit(this.form);
  }

  listenSearchInput() {
    this.inputSearch.valueChanges
      .pipe(
        filter((text: string) => text && text.length >= 3),
        tap(() => {
          this.isLoading = true;
          this.cids = [];
        }),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(text => this.cidService.findAllByQuery(text))
      )
      .subscribe(cids => {
        if (cids.length > 0) {
          this.cids = cids;
        } else {
          this.notification.notify('warning', 'Não foi encontado CID com código ou nome informado!', '');
        }

        this.isLoading = false;
      });
  }

  addCid(cid: Cid) {
    const newControlCid = this.fb.group({
      id: [cid._id],
      code: [cid.code, [Validators.required]],
      description: [cid.description]
    });
    this.formArrayItens.push(newControlCid);
    this.formEmitter.emit(this.form);
    this.inputSearch = new FormControl('');
    this.listenSearchInput();
  }

  get formCid(): FormGroup {
    return this.form.get('cid') as FormGroup;
  }

  get formArrayItens(): FormArray {
    return this.form.get('cid').get('items') as FormArray;
  }

  removeItem(index: number) {
    this.formArrayItens.removeAt(index);
  }

  removeCardCid() {
    this.onClickRemoveCardCid.emit();
  }
}
