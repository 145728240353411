import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { ThemeService } from '@app/theme';
import { markFormGroup } from '@app/utils/markFormGroup';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading: boolean;

  form = new FormGroup({
    email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
    password: new FormControl('', Validators.required)
  });

  constructor(
    private router: Router,
    private authService: AuthService,
    private notification: AppToastService,
    private themeService: ThemeService
  ) {}

  ngOnInit() {
    this.form.get('email').valueChanges.subscribe(email => {
      this.form.get('email').setValue(email && email.toLowerCase(), { emitEvent: false });
    });
  }

  login() {
    markFormGroup(this.form);
    if (this.form.valid) {
      this.loading = true;
      this.authService
        .login(this.form.value)
        .pipe(
          switchMap(_ => of(this.authService.user())),
          take(1)
        )
        .subscribe(
          user => {
            this.loading = false;
            if (user.userType === 'admin') {
              window.location.href = '/admin';
            } else {
              window.location.href = '/dashboard';
            }
          },
          err => {
            if (err.status === 400 || err.status === 401 || err.status === 429) {
              this.notification.notify('warning', 'Avisos', err.error.message);
            } else {
              this.notification.notify('warning', 'Aviso', 'Erro interno');
            }
            this.loading = false;
          }
        );
    }
  }

  isThemeActiveReceitaDigital() {
    const active = this.themeService.getActiveTheme();
    return active.name === 'theme-receita-digital';
  }
}
