import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-popover-button',
  templateUrl: './popover-button.component.html',
  styleUrls: ['./popover-button.component.scss']
})
export class PopoverButtonComponent implements OnInit {
  @Input() content = '';
  @Input() shape = 'circle';
  @Input() placement = 'right';

  constructor() {}

  ngOnInit() {}
}
