import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { ThemeService } from '@app/theme';
import { environment } from '@env/environment';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { tap } from 'rxjs/operators';
import { ClientAppService } from '../../modules/external-document/services/client-app.service';
import { CoreService } from '../services/core.service';

@Injectable({ providedIn: 'root' })
export class HttpInterceptorService implements HttpInterceptor {
  private readonly host = 'api';

  constructor(
    private localStorage: LocalStorage,
    private notification: AppToastService,
    private router: Router,
    private clientAppService: ClientAppService,
    private coreService: CoreService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let httpRequest = req.clone({
      url: req.url.includes('http') ? req.url : `${this.host}/${req.url}`
    });

    let params = httpRequest.params;
    for (const key of httpRequest.params.keys()) {
      if (params.get(key) === undefined) {
        params = params.delete(key);
      }
    }

    httpRequest = httpRequest.clone({ params });
    httpRequest = httpRequest.clone({
      headers: httpRequest.headers.set('ngsw-bypass', 'true')
    });

    /**
     * Inclusão do tenantId MRD
     */
    if (this.coreService.isMrd) {
      httpRequest = httpRequest.clone({
        headers: httpRequest.headers.set('x-tenant', 'MRD')
      });
    }

    if (httpRequest.url.includes('/v1')) {
      httpRequest = httpRequest.clone({
        headers: httpRequest.headers.set('x-api-key', environment.envAdminApiKey)
      });
    }

    if (httpRequest.url.includes('receitadigital.com/medicament')) {
      httpRequest = httpRequest.clone({
        headers: httpRequest.headers
          .set('Authorization', 'Bearer ')
          .set('x-api-key', environment.envKeyMedicament)
      });
    }

    if (httpRequest.url.includes('receitadigital.com/speciality')) {
      httpRequest = httpRequest.clone({
        headers: httpRequest.headers
          .set('Authorization', 'Bearer ')
          .set('x-api-key', environment.envKeySpecialty)
      });
    }

    if (this.router.url.startsWith('/recipe')) {
      const apiKey = this.clientAppService.apiKey;
      if (apiKey) {
        httpRequest = httpRequest.clone({
          headers: httpRequest.headers.set('x-api-key', apiKey)
        });
      }
    }

    const externalApi = this.isExternalApi(httpRequest.url);

    return this.localStorage.getItem<string>('id_token').pipe(
      mergeMap((idToken: string) => {
        if (idToken && !externalApi) {
          httpRequest = httpRequest.clone({
            headers: httpRequest.headers.set('Authorization', 'Bearer ' + idToken)
          });
        }
        return next.handle(httpRequest).pipe(
          tap(
            (event: HttpEvent<any>) => {
              // if the event is for http response
              if (event instanceof HttpResponse) {
                // set loading to false
              }
            },
            (err: any) => {
              if (
                err instanceof HttpErrorResponse &&
                !err.url.includes('/v1/') &&
                err.status !== 400 &&
                err.status !== 401 &&
                err.status !== 403 &&
                err.status !== 404 &&
                err.status !== 409 &&
                err.status !== 422
              ) {
                const message = err.status
                  ? (err.error || {}).message || err.message || err.error
                  : 'Serviço indisponível no momento.' +
                    ' Por favor verifique sua conexão ou se o problema persistir tente mais tarde.';
                this.notification.notify('warning', 'Aviso', message);
              }
            }
          )
        );
      })
    );
  }

  private isExternalApi(url: string) {
    return url.includes(environment.plusGApp) || url.includes('/v1');
  }
}
