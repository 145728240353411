import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'toJSON'
})
export class ToJsonPipe implements PipeTransform {
  transform(value = '{}'): string {
    return JSON.stringify(JSON.parse(value), null, 2);
  }
}
