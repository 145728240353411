import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from '@app/core/services/core.service';
import { ValidationService } from '@app/core/services/validation.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { ModalAddPatientComponent } from '@app/modules/patients/pages/modal-add-patient/modal-add-patient.component';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { BrazilState } from '@app/shared/models';
import { DataBigboostConsult } from '@app/shared/models/paciente';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { BigboostService } from '@app/shared/services/bigboost.service';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

type typeEntryPatient = 'HUMANO' | 'ANIMAL';

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        group([animate(300, style({ height: 0 })), animate('400ms ease-in-out', style({ opacity: 0 }))])
      ]),
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        group([
          animate(300, style({ height: '*' })),
          animate('400ms ease-in-out', style({ opacity: 1 }))
        ])
      ])
    ])
  ]
})
export class PatientFormComponent implements OnInit {
  @Input() patient: DataBigboostConsult;
  @Input() isRegisteredByPatient = true;
  @Input() getForm: Observable<boolean>;
  @Output() submit = new EventEmitter<FormGroup>();
  @Output() formChange = new EventEmitter<FormGroup>();

  isProduction = environment.production;
  valueTypeEntryPatient: typeEntryPatient = 'HUMANO';
  isMobile = false;

  form = this.fb.group({
    name: ['', [Validators.required, ValidationService.nomeValidator]],
    fullname: ['', [Validators.required, ValidationService.nomeValidator]],
    checkNoCpf: [false],
    cpf: ['', [Validators.required, ValidationService.cpfValidator]],
    sex: ['', Validators.required],
    birthdate: ['', [Validators.required, ValidationService.dateValidator()]],
    dateOfBirth: ['', [Validators.required, ValidationService.dateValidator({ format: 'YYYY-MM-DD' })]],
    cellphone: ['', Validators.required],
    telephone: [''],
    email: ['', [Validators.required, Validators.email]],
    membership: this.fb.group({
      mothersName: ['']
    }),
    address: this.fb.group({
      uf: ['', Validators.required],
      city: ['', Validators.required],
      complement: [''],
      number: ['', Validators.required],
      street: ['', Validators.required],
      neighborhood: ['', Validators.required],
      cep: ['', [Validators.required, ValidationService.cepValidator]]
    }),
    pendency: [null],
    verifiedCpf: [false]
  });

  states: BrazilState[] = BrazilianStates;
  loading = false;
  consult: any;
  private lastCpf: string;
  private lastDateOfBirth: string;

  constructor(
    private fb: FormBuilder,
    private cepService: CepService,
    private modalService: BsModalService,
    private notification: AppToastService,
    private bigboostService: BigboostService,
    private patientsService: PatientsService,
    private coreService: CoreService
  ) {
    this.onResize();
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = this.coreService.isViewPortMobile();
  }

  ngOnInit() {
    this.initForm();

    if (this.patient) {
      this.handleConsultCpf(this.patient);
    }

    this.formChange.emit(this.form);
  }

  private initForm() {
    if (this.isRegisteredByPatient) {
      this.form.addControl('identity', this.fb.control(''));
      this.form.addControl(
        'emailConfirmation',
        this.fb.control('', Validators.compose([Validators.required, Validators.email]))
      );
      this.form.validator = ValidationService.emailMatch;

      this.form.get('emailConfirmation').valueChanges.subscribe(email => {
        this.form.get('emailConfirmation').setValue(email && email.toLowerCase(), { emitEvent: false });
      });
    }

    this.form.get('cpf').valueChanges.subscribe(cpf => {
      if (this.lastCpf !== cpf && !this.hasPendency) {
        this.verifiedCpf = false;
      }
    });

    this.form.get('birthdate').valueChanges.subscribe(birthdate => {
      const dateOfBirth = moment(birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      if (this.lastDateOfBirth !== dateOfBirth) {
        if (!this.hasPendency) {
          this.verifiedCpf = false;
        }
        this.form.get('dateOfBirth').setValue(dateOfBirth);
      }
    });

    this.form.get('email').valueChanges.subscribe(email => {
      this.form.get('email').setValue(email && email.toLowerCase(), { emitEvent: false });
    });
  }

  get hasPendency() {
    return (this.patient && this.patient.status !== 1) || this.form.value.pendency;
  }

  get cpfCtrl() {
    return this.form.get('cpf');
  }

  get dateCtrl() {
    return this.form.get('birthdate');
  }

  get verifiedCpf() {
    return this.form.get('verifiedCpf').value;
  }

  set verifiedCpf(value: boolean) {
    this.form.get('verifiedCpf').setValue(value);
  }

  @nativeAsync
  async consultCpf() {
    const { cpf, birthdate: date, checkNoCpf } = this.form.value;
    // A data que vem no formulário não vem no formato para que possa ser formatada.
    // Essa linha vai fazer com que a data fique no formato 00/00/0000.
    let noCpf = checkNoCpf;
    if (!noCpf) {
      noCpf = this.valueTypeEntryPatient === 'ANIMAL';
    }

    const dateOfBirth = moment(date, 'DDMMYYYY').format('YYYY-MM-DD');

    if (
      this.cpfCtrl.valid &&
      this.dateCtrl.valid &&
      (this.lastCpf !== cpf || this.lastDateOfBirth !== dateOfBirth || !this.verifiedCpf)
    ) {
      try {
        this.loading = true;
        const existsPatient = Boolean(await this.patientsService.existsPatient(cpf).toPromise());
        if (existsPatient) {
          if (!noCpf) {
            this.notification.notify(
              'warning',
              'Aviso',
              `Paciente já cadastrado. Favor entrar em contato com a Central de atendimento`
            );
          } else {
            const patient = await this.patientsService.getPatientByCpf(cpf).toPromise();
            this.showModalAddPatient(patient, noCpf, existsPatient);
          }
          this.loading = false;
        } else {
          const consult = await this.bigboostService.consultCpf(cpf, dateOfBirth).toPromise();
          this.consult = consult;
          this.loading = false;
          if (noCpf) {
            if (consult.menorIdade) {
              this.notification.notify(
                'warning',
                'Aviso',
                'CPF é de um menor, tente novamente com o CPF do responsável'
              );
            } else {
              const valueNoCpf = noCpf;
              this.showModalAddPatient(consult, valueNoCpf);
            }
          } else {
            if (consult.menorIdade) {
              const valueNoCpf = noCpf;
              this.showModalAddPatient(consult, valueNoCpf);
            } else {
              this.handleConsultCpf(consult);
            }
          }
        }
      } catch (err) {
        console.error(err);
        this.loading = false;
        if (err.status === 404) {
          this.notification.notify(
            'error',
            'Error ao consultar CPF',
            'CPF não localizado na Receita Federal. Verifique as informações digitadas e tente novamente. Em caso de dúvidas, entre em contato com nosso atendimento',
            8000
          );
          return;
        }

        if (err.status === 500 || err.status === 0) {
          this.notification.notify(
            'error',
            'Error ao consultar CPF',
            'Plataforma de consulta de CPF com erro ao tentar realizar consulta do CPF',
            8000
          );
          return;
        }

        if (err.status === 400 && err.error.code === 'ERRO_CPF_INVALID') {
          this.notification.notify('warning', 'Aviso', 'CPF informado é invalido!');
          this.form.get('cpf').setErrors({ cpf: true });
        } else {
          const consult = {
            status: -1,
            cpf,
            erroCodigo: err.status,
            erro: 'A importação de dados do cpf está com instabilidade'
          };
          this.form.get('pendency').setValue(consult);
          if (err.status === 401) {
            this.notification.notify(
              'warning',
              'Aviso',
              'Data de nascimento não corresponde ao CPF informado'
            );
            this.form.get('birthdate').setErrors({ nonMatchingCpf: true });
            this.verifiedCpf = false;
          } else {
            this.handleConsultCpf(consult);
          }
        }
      }
      this.lastCpf = cpf;
      this.lastDateOfBirth = dateOfBirth;
    }
  }

  private showModalAddPatient(consult, noCpf, existingPatient = false) {
    const initialState = {
      patient: consult,
      registeredByResponsible: true,
      isPet: this.valueTypeEntryPatient === 'ANIMAL',
      isResponsible: noCpf || !consult.menorIdade,
      existingPatient
    };
    const modal = this.modalService.show(ModalAddPatientComponent, {
      class: 'modal-lg',
      initialState,
      backdrop: 'static',
      keyboard: false
    });
  }

  private handleConsultCpf(consult: DataBigboostConsult) {
    this.form.get('name').reset();
    this.form.get('name').enable();

    if (consult.status === 1) {
      this.form.get('fullname').disable();
      this.form.get('pendency').setValue(null);
      this.setForm(consult);
      if (!this.isRegisteredByPatient) {
        this.form.get('cpf').disable({ emitEvent: false });
        this.form.get('birthdate').disable({ emitEvent: false });
      }
    } else {
      this.form.get('fullname').enable();
      this.form.get('pendency').setValue(consult);
      this.form.get('cpf').setValue(consult.cpf);
      this.resetForm();
      if (consult.erro) {
        this.notification.notify(
          'warning',
          'Aviso',
          'A importação de dados do CPF está com instabilidade, mas você poderá se cadastrar preenchendo os campos manualmente.'
        );
      }
    }
    this.verifiedCpf = true;
  }

  private setForm(consult: DataBigboostConsult) {
    this.form.get('cpf').setValue(consult.cpf);
    this.form.get('fullname').setValue(consult.nome);
    this.form.get('name').setValue(consult.nome);
    this.form.get('sex').setValue(consult.genero);
    if (!this.form.value.dateOfBirth) {
      const nascimento = moment(consult.nascimento, 'DD/MM/YYYY');
      this.form.get('dateOfBirth').setValue(nascimento.format('YYYY-MM-DD'));
      this.form.get('birthdate').setValue(nascimento.format('DDMMYYYY'));
    }

    this.lastCpf = this.form.value.cpf;
    this.lastDateOfBirth = this.form.value.dateOfBirth;
  }

  private resetForm() {
    this.form.get('fullname').reset();
    this.form.get('sex').reset();
  }

  @nativeAsync
  async onKey(event: any) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        this.form.controls['address'].patchValue({
          uf: data.uf,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          number: null,
          cep: data.cep
        });
      }
    }
  }

  checkEmail(disable: boolean) {
    if (disable) {
      this.form.get('email').reset();
      this.form.get('email').disable();
    } else {
      this.form.get('email').enable();
    }
  }
}
