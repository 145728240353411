import { Pipe, PipeTransform } from '@angular/core';
import { OnlyNumbersPipe } from './only-numbers.pipe';

@Pipe({
  name: 'formattedCep'
})
export class FormattedCepPipe implements PipeTransform {
  transform(value: string): string {
    if (value && value !== '') {
      let cep;
      const receivedValue = value ? new OnlyNumbersPipe().transform(value) : value;
      if (receivedValue.length <= 5) {
        cep = receivedValue.substring(0, receivedValue.length);
      } else {
        cep = `${receivedValue.substring(0, 5)}-${receivedValue.substring(5, receivedValue.length)}`;
      }
      return cep;
    }
  }
}
