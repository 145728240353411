import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { PharmacistService } from '@app/modules/pharmacist/services/pharmacist.service';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { BrazilState } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { markFormGroup } from '@app/utils/markFormGroup';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-modal-edit-address-data',
  templateUrl: './modal-edit-address-data.component.html',
  styleUrls: ['./modal-edit-address-data.component.scss']
})
export class ModalEditAddressDataComponent implements OnInit {
  user: User;
  completeUser: any;
  isNew: boolean;
  userType: string;
  commercialData: any;
  commercialDataIndex: number;
  loading = false;
  form: FormGroup;

  states: BrazilState[] = BrazilianStates;

  @Output() updateUserEvent = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private cepService: CepService,
    private patientService: PatientsService,
    private healthProfessionalService: HealthProfessionalService,
    private pharmacyService: PharmacyService,
    private pharmacistService: PharmacistService,
    private nzModalRef: NzModalRef
  ) {}

  ngOnInit() {
    if (this.userType === 'healthProfessional') {
      this.buildCommercialAddressForm();
    } else {
      this.buildAddressForm();
    }
  }
  get title() {
    return this.userType === 'patient' ? 'ENDEREÇO' : 'DADOS EMPRESARIAIS';
  }

  private buildCommercialAddressForm() {
    this.form = this.fb.group({
      email: ['', Validators.email],
      name: ['', Validators.required],
      telephone: ['', Validators.required],
      cellphone: [''],
      address: this.fb.group({
        uf: ['', Validators.required],
        city: ['', Validators.required],
        complement: [''],
        number: ['', Validators.required],
        street: ['', Validators.required],
        neighborhood: ['', Validators.required],
        cep: ['', [Validators.required, ValidationService.cepValidator]]
      }),
      logo: ['']
    });

    if (!this.isNew) {
      this.form.patchValue(this.commercialData);
    }
  }

  private buildAddressForm() {
    this.form = this.fb.group({
      address: this.fb.group({
        uf: ['', Validators.required],
        city: ['', Validators.required],
        complement: [''],
        number: ['', Validators.required],
        street: ['', Validators.required],
        neighborhood: ['', Validators.required],
        cep: ['', [Validators.required, ValidationService.cepValidator]]
      })
    });
    this.form.controls.address.patchValue(this.completeUser.address);
  }

  submitForm() {
    markFormGroup(this.form);
    if (this.form.valid) {
      this.loading = true;
      this.form.disable();
      let execute = new Observable();
      switch (this.userType) {
        case 'healthProfessional':
          if (this.isNew) {
            this.completeUser.commercialData.push(this.form.value);
          } else {
            this.completeUser.commercialData[this.commercialDataIndex] = this.form.value;
          }
          execute = this.healthProfessionalService.update(this.completeUser.userId, this.completeUser);
          break;
        case 'pharmacy':
          this.completeUser.address = this.form.value.address;
          execute = this.pharmacyService.update(this.completeUser._id, this.completeUser);
          break;
        case 'pharmacist':
          this.completeUser.address = this.form.value.address;
          execute = this.pharmacistService.update(this.completeUser._id, this.completeUser);
          break;

        default:
          this.completeUser.address = this.form.value.address;
          execute = this.patientService.update(this.completeUser._id, this.completeUser);
      }

      execute
        .subscribe(data => {
          this.nzModalRef.close(data);
        })
        .add(() => {
          this.loading = false;
          this.nzModalRef.destroy();
        });
    }
  }

  @nativeAsync
  async onKey(event: any) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        this.form.controls['address'].patchValue({
          uf: data.uf,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          number: null,
          cep: data.cep
        });
      }
    }
  }
}
