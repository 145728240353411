import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isCpf'
})
@Injectable()
export class IsCpfPipe implements PipeTransform {
  transform(value: string): any {
    if (value) {
      value = value.toString().replace(/[^\d]+/g, '');
      let numeros, digitos, soma, i, resultado, digitos_iguais;

      digitos_iguais = 1;

      if (value.length < 11) {
        return false;
      }

      for (i = 0; i < value.length - 1; i++) {
        if (value.charAt(i) !== value.charAt(i + 1)) {
          digitos_iguais = 0;
          break;
        }
      }

      if (!digitos_iguais) {
        numeros = value.substring(0, 9);
        digitos = value.substring(9);
        soma = 0;
        for (let b = 10; b > 1; b--) {
          soma += numeros.charAt(10 - b) * b;
        }

        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado !== parseFloat(digitos.charAt(0))) {
          return false;
        }
        numeros = value.substring(0, 10);
        soma = 0;

        for (let a = 11; a > 1; a--) {
          soma += numeros.charAt(11 - a) * a;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        return resultado === parseFloat(digitos.charAt(1));
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
