import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CrService } from '@app/modules/document/services/cr.service';
import { environment } from '@env/environment';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ReplaySubject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { MedicamentoBusca } from '../models/register-class';

@Component({
  selector: 'app-modal-dispention',
  templateUrl: './modal-dispention.component.html',
  styleUrls: ['./modal-dispention.component.scss']
})
export class ModalDispentionComponent implements OnInit, OnDestroy {
  medicament: any;
  dispesationForm: FormGroup;
  isNewItem = true;
  medicamentItems: MedicamentoBusca[] = [];

  batchForm: FormGroup;
  medicationForm: FormGroup;

  medications = [];
  loading = false;

  private destroyed$ = new ReplaySubject<boolean>();

  constructor(private fb: FormBuilder, private ref: NzModalRef, private crService: CrService) {
    this.batchForm = this.fb.group({ number: [''] });
    this.medicationForm = this.fb.group({ ean: [''] });
  }

  ngOnInit() {
    if (this.medicament.items) {
      // deep copy
      this.medicamentItems = JSON.parse(JSON.stringify(this.medicament.items));
    }

    this.medicationForm
      .get('ean')
      .valueChanges.pipe(
        filter(value => value && value.length >= 3),
        debounceTime(environment.debounceTime),
        takeUntil(this.destroyed$)
      )
      .subscribe(value => {
        try {
          this.loading = true;
          this.crService.searchMedicament(value).subscribe(medications => {
            this.medications = medications;
          });
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  selectMedicament(option: any) {
    this.medicamentItems.push({ ...option, show: true });
    this.isNewItem = false;
    this.medications = [];
    this.medicationForm.reset();
  }

  deleteMedicament(event) {
    event.stopPropagation();
    const path = event.path.find(p => p.id && p.id.includes('item-'));
    if (path) {
      const element = document.getElementById(path.id);
      const elementIndex = element.id.replace('item-', '');
      this.medicamentItems.splice(+elementIndex, 1);
    }

    if (!this.medicamentItems.length) {
      this.isNewItem = true;
    }
  }

  deleteBox(medicamentoBusca: MedicamentoBusca, nome: string) {
    const index = medicamentoBusca.caixas.findIndex(caixa => caixa.nome === nome);
    medicamentoBusca.caixas.splice(index, 1);
  }

  addBox(medicamentoBusca: MedicamentoBusca) {
    const number = this.batchForm.get('number').value;
    if (number) {
      if (!medicamentoBusca.caixas) {
        medicamentoBusca.caixas = [];
      }

      medicamentoBusca.caixas.push({ nome: number });
      this.batchForm.get('number').reset();
    }
  }

  newItem() {
    this.isNewItem = true;
    this.medicamentItems.forEach(item => (item.show = false));
  }

  cancel() {
    this.reset();
    this.ref.close();
  }

  private reset() {
    this.batchForm.reset();
    this.medicationForm.reset();
    this.medicamentItems = [];
    this.isNewItem = true;
  }

  finish() {
    this.medicamentItems.forEach(item => (item.show = false));
    this.medicament.items = this.medicamentItems;
    this.medicamentItems = [];
    this.ref.close();
  }

  get invalidForm() {
    return (
      (!this.medicamentItems.length && !this.medicament.items) ||
      this.medicamentItems.reduce((acc, item) => acc || !item.caixas || !item.caixas.length, false)
    );
  }
}
