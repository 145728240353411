import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective implements AfterViewInit {
  @Input() focus: boolean;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    if (this.focus) {
      this.elementRef.nativeElement.focus();
    }
  }
}
