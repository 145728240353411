import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Attestation } from '@app/shared/models/attestation';

export const ATTESTATION = `attestation`;
export const ATTESTATION_PRESCRIBER = `attestation/prescriber`;
export const ATTESTATION_PATIENT_URL = `attestation/patient`;

@Injectable({
  providedIn: 'root'
})
export class AttestationService {
  constructor(private http: HttpClient) {}

  public save(attestation): Observable<any> {
    if (attestation.code) {
      return this.http.put(`${environment.apiRoot}${ATTESTATION}/${attestation.code}`, attestation);
    }
    return this.http.post(`${environment.apiRoot}${ATTESTATION}`, attestation);
  }

  public getAllPrescriberAttestations(prescriberId: string): Observable<Attestation[]> {
    return this.http.get<Attestation[]>(`${environment.apiRoot}${ATTESTATION}`, {
      params: { prescriberId }
    });
  }

  public getAttestationsByPrescriberUserId(prescriberId: string, filter: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${ATTESTATION_PRESCRIBER}/${prescriberId}`, {
      params: filter
    });
  }

  public getAllPatientsAttestations(patientId: string) {
    return this.http.get<Attestation[]>(`${environment.apiRoot}${ATTESTATION}`, {
      params: { patientId }
    });
  }

  getAttestationsByPatientId(patientId: string, filter: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${ATTESTATION_PATIENT_URL}/${patientId}`, {
      params: filter
    });
  }

  getAttestation(code: string) {
    return this.http.get(`${environment.apiRoot}${ATTESTATION}/${code}`);
  }

  getByFilter(filter: any): Promise<Attestation[]> {
    return this.http
      .get<Attestation[]>(`${environment.apiRoot}${ATTESTATION}/filter`, {
        params: filter
      })
      .toPromise();
  }

  delete(attestationId: number): Observable<any> {
    return this.http.delete(`${environment.apiRoot}${ATTESTATION}/${attestationId}`);
  }
}
