import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

const KEY = 'token';

@Injectable({ providedIn: 'root' })
export class TokenService {
  readonly jwtHelper = new JwtHelperService();

  hasToken(): boolean {
    return this.getToken() !== null;
  }

  setToken(token: string): void {
    window.localStorage.setItem(KEY, token);
  }

  getToken(): string | null {
    return window.localStorage.getItem(KEY);
  }

  removeToken(): void {
    window.localStorage.removeItem(KEY);
  }

  tokenEstaExpirado() {
    return this.hasToken() && this.jwtHelper.isTokenExpired(this.getToken());
  }
}
