import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ProductRennova } from '../models/product-rennova';

const API_UL = `${environment.apiRoot}rennova`;

@Injectable({
  providedIn: 'root'
})
export class RennovaService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<ProductRennova[]> {
    return this.http.get<ProductRennova[]>(`${API_UL}/products`);
  }

  sendDocument(data) {
    return this.http.post(`${API_UL}/send-document`, data);
  }
}
