import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-file-step',
  templateUrl: './file-step.component.html',
  styleUrls: ['./file-step.component.scss']
})
export class FileStepComponent implements OnInit {
  @Output() formChange = new EventEmitter<any>();

  private _form: FormGroup;

  constructor(private fb: FormBuilder) {
    this._form = this.fb.group({
      filename: this.fb.control(''),
      documentFile: this.fb.control('', Validators.required)
    });
  }

  ngOnInit() {
    this._form.valueChanges.subscribe(value => this.formChange.emit(value));
  }

  @Input()
  set form(form: any) {
    if (form) {
      this._form.setValue(form);
    }
  }

  get form() {
    return this._form;
  }

  get file() {
    return this._form.get('documentFile').value;
  }

  get filename() {
    return this._form.get('filename').value;
  }

  set filename(value: string) {
    this._form.get('filename').setValue(value);
  }

  get invalid() {
    return this._form.invalid;
  }

  get errorMessage() {
    return 'Você precisa selecionar uma foto da sua carteira profissional para upload';
  }
}
