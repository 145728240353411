import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdditionalFeature } from '@app/shared/models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

const ADDITIONAL_FEATURE_URL = 'additional-feature';

@Injectable({
  providedIn: 'root'
})
export class AdditionalFeatureService {
  constructor(private http: HttpClient) {}

  save(additionalFeature: AdditionalFeature): Observable<AdditionalFeature> {
    return this.http.post<AdditionalFeature>(
      `${environment.apiRoot}${ADDITIONAL_FEATURE_URL}`,
      additionalFeature
    );
  }

  update(code, additionalFeature: AdditionalFeature): Observable<AdditionalFeature> {
    return this.http.put<AdditionalFeature>(
      `${environment.apiRoot}${ADDITIONAL_FEATURE_URL}/${code}`,
      additionalFeature
    );
  }

  associateFunctionalities(userId, data: any): Observable<any> {
    return this.http.post<AdditionalFeature>(
      `${environment.apiRoot}${ADDITIONAL_FEATURE_URL}/user/${userId}`,
      data
    );
  }

  disassociateFunctionality(userId, featureId) {
    return this.http.delete<AdditionalFeature>(
      `${environment.apiRoot}${ADDITIONAL_FEATURE_URL}/user/disassociate-functionality/${userId}/${featureId}`
    );
  }

  getAdditionalFeatures(filter: any): Observable<AdditionalFeature[]> {
    return this.http.get<AdditionalFeature[]>(`${environment.apiRoot}${ADDITIONAL_FEATURE_URL}`, {
      params: filter
    });
  }

  getAdditionalFeaturesByUser(userId, filter: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${ADDITIONAL_FEATURE_URL}/user/${userId}`, {
      params: filter
    });
  }

  acceptFeature(code: number) {
    return this.http
      .put<any>(`${environment.apiRoot}${ADDITIONAL_FEATURE_URL}/${code}/accept`, {})
      .toPromise();
  }

  refuseFeature(code: number) {
    return this.http
      .delete(`${environment.apiRoot}${ADDITIONAL_FEATURE_URL}/${code}/accept`)
      .toPromise();
  }
}
