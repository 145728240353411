import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { HealthProfessional } from '@app/shared/models';
import { AppToastService } from '@app/shared/services/app-toast.service';

@Component({
  selector: 'app-modal-rqe-warning',
  templateUrl: './modal-rqe-warning.component.html',
  styleUrls: ['./modal-rqe-warning.component.scss']
})
export class ModalRqeWarningComponent {
  @Input() isVisible = false;
  @Input() healthProfessional: HealthProfessional;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Input() healthProfessionalChange = new EventEmitter<HealthProfessional>();

  refuseLoading = false;

  constructor(
    private router: Router,
    private healthProfessionalService: HealthProfessionalService,
    private notification: AppToastService
  ) {}

  submit(value: boolean) {
    if (!value) {
      this.refuseRqe();
    } else {
      this.router.navigate(['/profile'], { state: { showModalPersonalData: true } });
      this.close();
    }
  }

  @nativeAsync
  private async refuseRqe() {
    try {
      this.refuseLoading = true;
      this.healthProfessional.specialties.forEach(s => {
        if (s.notExists === undefined) {
          s.notExists = true;
        }
      });

      const saved = await this.healthProfessionalService
        .update(this.healthProfessional.userId, this.healthProfessional)
        .toPromise();

      this.close();
      this.healthProfessionalChange.emit(saved);
      this.notification.notify('success', 'Sucesso', 'Registro atualizado');
    } catch (error) {
      console.error(error);
    }
    this.refuseLoading = false;
  }

  private close() {
    this.isVisibleChange.emit(false);
  }
}
