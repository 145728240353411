import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { BrazilState } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { BigboostService } from '@app/shared/services/bigboost.service';
import { markFormGroup } from '@app/utils/markFormGroup';
import * as moment from 'moment';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-edit-pharmacy-technician',
  templateUrl: './modal-edit-pharmacy-technician.component.html',
  styleUrls: ['./modal-edit-pharmacy-technician.component.scss']
})
export class ModalEditPharmacyTechnicianComponent implements OnInit {
  user: User;
  completeUser: any;
  userType: string;
  technicalResponsible: any;
  loading = false;

  form: FormGroup;
  states: BrazilState[] = BrazilianStates;
  @Output() updateUserEvent = new EventEmitter<User>();
  sexos = [
    { label: 'Masculino', value: 'M' },
    { label: 'Feminino', value: 'F' }
  ];

  constructor(
    private formp: FormBuilder,
    private pharmacyService: PharmacyService,
    private nzModalRef: NzModalRef,
    private bigboostService: BigboostService,
    private notification: AppToastService
  ) {}

  ngOnInit() {
    this.buildPharmacyForm();
  }

  private buildPharmacyForm() {
    this.form = this.formp.group({
      name: this.formp.control(
        this.isEditing ? this.completeUser.technicalResponsible.name : '',
        Validators.required
      ),
      socialName: [this.isEditing ? this.completeUser.technicalResponsible.socialName : ''],
      sex: [this.isEditing ? this.completeUser.technicalResponsible.sex : ''],
      birthDate: [this.isEditing ? this.formatBirthDate : null, Validators.required],
      cpf: this.formp.control(this.isEditing ? this.completeUser.technicalResponsible.cpf : '', [
        Validators.required,
        ValidationService.cpfValidator
      ]),
      crf: this.formp.group({
        number: this.formp.control(
          this.isEditing ? this.completeUser.technicalResponsible.crf.number : '',
          Validators.required
        ),
        uf: this.formp.control(
          this.isEditing ? this.completeUser.technicalResponsible.crf.uf : '',
          Validators.required
        )
      })
    });
    console.log(this.completeUser);
  }

  get isEditing() {
    return Boolean(this.completeUser && this.completeUser.technicalResponsible);
  }

  get formatBirthDate() {
    if (this.completeUser.technicalResponsible && this.completeUser.technicalResponsible.birthDate) {
      return moment(this.completeUser.technicalResponsible.birthDate).format('YYYY-MM-DD');
    }
    return null;
  }

  @nativeAsync
  async consultCpf() {
    this.loading = true;
    try {
      const { cpf, birthdate: date } = this.form.value;
      const dateOfBirth = moment(date, 'DDMMYYYY').format('YYYY-MM-DD');
      const consult = await this.bigboostService.consultCpf(cpf, dateOfBirth).toPromise();
      if (consult.erroCodigo === 102) {
        this.handleErrorMessageStatusCode102();
      } else {
        const { nome: name, genero: sex } = consult;
        this.form.patchValue({
          ...this.form.value,
          name,
          sex
        });
      }
    } catch (error) {
      if (error.status === 401) {
        this.notification.notify(
          'warning',
          'Aviso',
          'Data de nascimento não corresponde ao CPF informado'
        );
      } else {
        this.notification.notify(
          'warning',
          'Aviso',
          'A importação de dados do cpf está com instabilidade'
        );
      }
    } finally {
      this.loading = false;
    }
  }

  private handleErrorMessageStatusCode102(): void {
    this.notification.notify(
      'warning',
      'Aviso',
      'O CNPJ informado não existe em nossos fornecedores. Por favor, confira o número do CNPJ e tente novamente. Em caso de dúvidas, entre em contato com o nosso suporte'
    );
  }

  @nativeAsync
  async submitForm() {
    markFormGroup(this.form);
    try {
      if (this.form.valid) {
        this.form.disable();
        this.loading = true;

        if (this.userType == 'pharmacy') {
          this.completeUser.technicalResponsible = this.form.value;
          this.completeUser = await this.pharmacyService
            .update(this.completeUser._id, this.completeUser)
            .toPromise();
        } else {
          this.completeUser.technicalResponsible = this.form.value.technicalResponsible;
          this.completeUser = await this.pharmacyService
            .update(this.completeUser._id, this.completeUser)
            .toPromise();
        }
        this.form.enable();
        this.updateUserEvent.emit(this.completeUser);
        this.loading = false;
        this.onDestroyModal();
      }
    } catch (error) {
      this.loading = false;
    }
  }

  onDestroyModal() {
    this.nzModalRef.destroy();
  }
}
