import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ResponseConsultsCnes } from '../models/response-consults-cnes';

const API_UL = `${environment.apiRoot}open-data`;

@Injectable({
  providedIn: 'root'
})
export class OpenDataService {
  constructor(private http: HttpClient) {}

  consultCnes(cnes: string): Observable<ResponseConsultsCnes> {
    return this.http.get<ResponseConsultsCnes>(`${API_UL}/cnes/${cnes}`);
  }
}
