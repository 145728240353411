import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CepService } from '@app/modules/entry/services/cep.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { BrazilState } from '@app/shared/models';

@Component({
  selector: 'app-address-data-form',
  templateUrl: './address-data-form.component.html'
})
export class AddressDataFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() required = false;
  @Input() showAlertMsg = true;
  @Input() alertMsg =
    'Ao preencher o endereço do paciente, você será capaz de emitir Receitas de Controle Especial (listas C1, C5 e adendos das listas A1, B1 e B2). Para prescrever este tipo de medicamentos, deverá ser preenchido o endereço completo (RDC 344/98).';

  isExternalRegister = false;
  states: BrazilState[] = BrazilianStates;

  constructor(private cepService: CepService, private router: Router) {}

  ngOnInit(): void {
    this.isExternalRegister = this.router.url === '/entry/register/patient';
    if (this.isExternalRegister) {
      this.required = true;
      this.validatorsFields();
    } else {
      this.disableFields();
      this.removeValidators();
    }
  }
  @nativeAsync
  async onKey(event: any) {
    this.required = false;
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        this.required = true;
        this.enabledFields();
        this.validatorsFields();
        this.patchValueForm(data);
      }
    } else {
      this.patchValueForm({});
      this.disableFields();
      this.removeValidators();
    }
  }
  @nativeAsync
  async onKeyExternal(event: any) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      this.patchValueForm(data);
      this.enabledFields();
    } else {
      this.patchValueForm({});
      this.disableFields();
    }
  }

  patchValueForm(data: any) {
    this.form.patchValue({
      uf: data.uf,
      street: data.logradouro,
      neighborhood: data.bairro,
      city: data.localidade,
      number: null
    });
  }

  validatorsFields() {
    this.form.get('uf').setValidators([Validators.required]);
    this.form.get('street').setValidators([Validators.required]);
    this.form.get('neighborhood').setValidators([Validators.required]);
    this.form.get('city').setValidators([Validators.required]);
    this.form.get('number').setValidators([Validators.required]);
    this.form.get('cep').setValidators([Validators.required]);
  }

  removeValidators() {
    this.form.clearValidators();
    this.form.updateValueAndValidity();
  }

  disableFields() {
    this.form.get('uf').disable();
    this.form.get('street').disable();
    this.form.get('neighborhood').disable();
    this.form.get('city').disable();
    this.form.get('number').disable();
    this.form.get('complement').disable();
  }

  enabledFields() {
    this.form.get('uf').enable();
    this.form.get('street').enable();
    this.form.get('neighborhood').enable();
    this.form.get('city').enable();
    this.form.get('number').enable();
    this.form.get('complement').enable();
  }
}
