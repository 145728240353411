import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '@app/core/services/core.service';
import { DialogModalComponent } from '@app/shared/components/dialog-modal/dialog-modal.component';
import { CpfCnpjApiErrors } from '@app/shared/models/cpf-cnpj-api-errors.enum';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { BigboostService } from '@app/shared/services/bigboost.service';
import { ThemeService } from '@app/theme';
import { logInvalidForm } from '@app/utils/logInvalidForm';
import { markFormGroup } from '@app/utils/markFormGroup';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { RegisterService } from '../../services/register.service';
import { ModalTermsAndConditionsComponent } from './modal-terms-and-conditions/modal-terms-and-conditions.component';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { NzModalService } from 'ng-zorro-antd';
import { HealthProfessional } from '@app/shared/models';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  actualDate = new Date();
  isDisabledButton = true;
  isCheckedTerms = false;
  isVisible = false;
  loading = false;

  form: FormGroup;
  routerParam: string;
  backendVersion$: Observable<string>;

  @ViewChild(DialogModalComponent, { static: true })
  dialogModal: DialogModalComponent;

  @ViewChild('contentModalErrorCfmValidate', { static: true })
  contentModalErrorCfmValidate: TemplateRef<{}>;

  @ViewChild('contentModalErrorCfmConsult', { static: true })
  contentModalErrorCfmConsult: TemplateRef<{}>;

  @ViewChild(ModalTermsAndConditionsComponent, { static: true })
  modalTermsAndConditionsComponent: ModalTermsAndConditionsComponent;

  isMobile = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = this.coreService.isViewPortMobile();
  }

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private registerService: RegisterService,
    private notification: AppToastService,
    private bigboostService: BigboostService,
    private themeService: ThemeService,
    private coreService: CoreService,
    private modalService: NzModalService
  ) {
    this.backendVersion$ = this.coreService.getBackendVersion();
  }

  ngOnInit(): void {
    this.routerParam = this._route.snapshot.paramMap.get('type');
  }

  get verifiedCpf() {
    return !!this.form && this.form.value.verifiedCpf;
  }

  get verified() {
    return !!this.form && this.form.value.verified;
  }

  get isPharmacyPublic() {
    return this.routerParam === 'pharmacy' && !!this.form && this.form.value.public_pharmacy;
  }

  get frontendVersion(): string {
    return this.coreService.frontendVersion;
  }

  get isRegisterDoctor() {
    return this.routerParam === 'doctor';
  }

  get viewBtnSaveAndTermsDisclaimer(): boolean {
    return (
      ((this.routerParam === 'patient' ||
        this.routerParam === 'doctor' ||
        this.routerParam === 'dentist' ||
        this.routerParam === 'veterinarian') &&
        this.verifiedCpf) ||
      (this.routerParam === 'pharmacy' && this.verified)
    );
  }

  handleError(err: HttpErrorResponse) {
    console.error(err);
    const message = err.error ? err.error.message : 'Houve um erro no cadastro';
    return throwError(message);
  }

  openTermsAndConditionsModal() {
    this.modalTermsAndConditionsComponent.openModal();
  }

  redirect(privacy?: boolean) {
    let url;
    if (this.routerParam === 'patient') {
      url = this.router.createUrlTree(['policies', 'termspatient']);
    }
    if (this.routerParam === 'doctor') {
      url = this.router.createUrlTree(['policies', 'termsdoctor']);
    }
    if (this.routerParam === 'dentist') {
      url = this.router.createUrlTree(['policies', 'termsdentist']);
    }
    if (this.routerParam === 'veterinarian') {
      url = this.router.createUrlTree(['policies', 'termsveterinary']);
    }
    if (this.routerParam === 'pharmacy') {
      url = this.router.createUrlTree(['policies', 'termspharmacy']);
    }
    if (privacy) {
      url = this.router.createUrlTree(['policies', 'privacypolicy']);
    }
    if (url) {
      window.open(url.toString(), '_blank');
    }
  }

  openTerms() {
    window.open('assets/file/mrd_termos.pdf', '_blank');
  }

  openConditions() {
    window.open('assets/file/mrd_politica.pdf', '_blank');
  }

  @nativeAsync
  async isValidCpfCnpj(cpf, cnpj) {
    try {
      this.loading = true;
      let consult: any;
      let message = '';
      if (cpf) {
        consult = await this.bigboostService.consultCpf(cpf).toPromise();
        message =
          'A importação de dados do CPF está com instabilidade, preencha manualmente ou tente novamente mais tarde.';
      } else {
        consult = await this.bigboostService.consultCnpj(cnpj);
        message =
          'A importação de dados do CNPJ está com instabilidade, preencha manualmente ou tente novamente mais tarde.';
      }
      this.loading = false;

      if (consult.status === 1) {
        return true;
      }
      if (consult.status === -1 || consult.erroCodigo >= 1000) {
        consult.erro = consult.erroCodigo >= 1000 ? consult.erro : message;
        this.form.value.pendency = consult;
        return true;
      }
      if (consult.status === 0 && consult.erroCodigo && consult.erroCodigo < 1000) {
        if (Object.values(CpfCnpjApiErrors).includes(consult.erroCodigo)) {
          this.notification.notify('warning', 'Aviso', consult.erro);
          return false;
        }
      }
      return false;
    } catch (error) {
      this.loading = false;
    }
  }

  @nativeAsync
  async submit() {
    markFormGroup(this.form);
    if (this.routerParam === 'pharmacy') {
      this.markVerified();
    }
    this.loading = true;

    const isHealthProfessional =
      this.routerParam === 'doctor' ||
      this.routerParam === 'dentist' ||
      this.routerParam === 'veterinarian';

    if (isHealthProfessional && this.form.value.specialties.length === 0) {
      this.notification.notify('warning', 'Aviso', 'Você precisa escolher ao menos uma especialidade.');
    } else if (this.form.invalid) {
      logInvalidForm(this.form);
      this.notification.notify('warning', 'Aviso', 'Confira os campos obrigatórios');
    } else if (!this.isCheckedTerms) {
      this.notification.notify(
        'warning',
        'Aviso',
        'Para concluir o seu cadastro é necessário concordar com os Termo de Uso e Política de Privacidade da Receita Digital'
      );
    } else {
      await this.register(this.form.getRawValue());
    }
    this.loading = false;
  }

  private markVerified() {
    const controlVerified = this.form.get('verified');
    if (controlVerified) {
      controlVerified.setValue(true);
    }
  }

  @nativeAsync
  private async register(data: any) {
    try {
      const response = await this.registerService
        .register(this.routerParam, data)
        .pipe(take(1))
        .toPromise();

      if (this.isRegisterDoctor) {
        const dadosProfissionais = response as HealthProfessional;
        const title = dadosProfissionais.cfm.pendenteValidacao
          ? 'Recebemos seu cadastro!'
          : 'Cadastro realizado com sucesso!';
        const subtitle = 'Acesse seu e-mail para ativar a conta e cadastrar sua senha!';
        const textoComplemento = dadosProfissionais.cfm.pendenteValidacao
          ? 'Seu cadastro será liberado após a validação dos dados pela nossa equipe (RES 2299/2021 CFM)'
          : null;

        this.dialogModal.createTplModal(title, subtitle, textoComplemento);
      } else {
        this.dialogModal.createTplModal(
          'Cadastro realizado com sucesso.',
          'Acesse seu e-mail para ativar a conta e cadastrar sua senha!'
        );
      }

      this.router.navigate(['entry/login']);
    } catch (httpError) {
      this.loading = false;
      const errMsg = httpError.error ? httpError.error.message : 'Houve um erro no cadastro';
      const errCode = httpError.error ? httpError.error.errorCode : '';
      if (errCode === 'ERROR_CRM_VALIDADO_INVALIDO') {
        this.dialogModal.createTplModalContent(this.contentModalErrorCfmValidate);
      } else if (errCode === 'ERROR_CRM_CONSULTADO_IRREGULAR') {
        this.dialogModal.createTplModalContent(this.contentModalErrorCfmConsult);
      } else {
        this.notification.notify('error', 'Erro', errMsg);
        if (this.routerParam === 'pharmacy') {
          if (this.isPharmacyPublic) {
            this.form.get('cnes').setErrors(null);
          } else {
            this.form.get('cnpj').setErrors(null);
          }
        } else {
          this.form.get('cpf').setErrors(null);
        }
        this.form.get('email').setErrors(null);
        if (errMsg === 'E-mail já cadastrado') {
          this.form.get('email').setErrors({ alreadyExists: true });
        } else if (errMsg === 'CPF já cadastrado') {
          this.form.get('cpf').setErrors({ alreadyExists: true });
        } else if (errMsg === 'CNES já cadastrado') {
          this.form.get('cnes').setErrors({ alreadyExists: true });
        } else if (errMsg === 'CNPJ já cadastrado') {
          this.form.get('cnpj').setErrors({ alreadyExists: true });
        }
      }
    }
  }

  isThemeActiveReceitaDigital() {
    const active = this.themeService.getActiveTheme();
    return active.name === 'theme-receita-digital';
  }
}
