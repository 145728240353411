import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

type TypeToast = 'success' | 'info' | 'warning' | 'error';

@Injectable({
  providedIn: 'root'
})
export class AppToastService {
  constructor(private notification: NzNotificationService) {}

  public notify(type: TypeToast, title: string, subtitle?: string, duration?: number) {
    this.notification.create(type, title, subtitle, {
      nzStyle: {
        'z-index': 1000000
      },
      nzDuration: !!duration ? duration : 5000
    });
  }
}
