import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExamType } from '@app/shared/models';
import { Exam } from '@app/shared/models/exam';
import { ExamBase } from '@app/shared/models/ExamBase';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

const EXAM_URL = 'exam';
const EXAM_BASE_URL = 'exams-base';
export const EXAM_PRESCRIBER = `exam/prescriber`;
const EXAM_PATIENT = `exam/patient`;
const TYPES_URL = 'types';
@Injectable({
  providedIn: 'root'
})
export class ExamService {
  constructor(private http: HttpClient) {}
  uploadExams(file: File, isPet: boolean) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${environment.apiRoot}${EXAM_BASE_URL}/import?isPet=${isPet}`, formData);
  }

  findAllByQuery(query: string, isVeterinarian: boolean) {
    return this.http.get<ExamBase[]>(
      `${environment.apiRoot}${EXAM_BASE_URL}?query=${query}&pet=${isVeterinarian}`
    );
  }

  public getMaterials(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiRoot}${EXAM_URL}/${TYPES_URL}/materials`);
  }

  public getExamTypes(material: string, examType: string): Observable<ExamType[]> {
    return this.http.get<ExamType[]>(
      `${environment.apiRoot}${EXAM_URL}/${TYPES_URL}?material=${material}&examType=${examType}`
    );
  }

  public save(exam: Exam) {
    if (exam.code) {
      return this.http.put(`${environment.apiRoot}${EXAM_URL}/${exam.code}`, exam);
    }
    return this.http.post(`${environment.apiRoot}${EXAM_URL}`, exam);
  }

  public getAllPrescriberExams(prescriberId: string) {
    return this.http.get<Exam[]>(`${environment.apiRoot}${EXAM_URL}`, {
      params: { prescriberId }
    });
  }

  public getExamsByPrescriberUserId(prescriberId: string, filter: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${EXAM_PRESCRIBER}/${prescriberId}`, {
      params: filter
    });
  }

  public getAllPatientExams(patientId: string) {
    return this.http.get<Exam[]>(`${environment.apiRoot}${EXAM_URL}`, {
      params: { patientId }
    });
  }

  getExamsByPatientId(patientId: string, filter: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${EXAM_PATIENT}/${patientId}`, { params: filter });
  }

  getExam(code: string) {
    return this.http.get(`${environment.apiRoot}${EXAM_URL}/${code}`);
  }

  getByFilter(filter: any): Promise<Exam[]> {
    return this.http
      .get<Exam[]>(`${environment.apiRoot}${EXAM_URL}/filter`, {
        params: filter
      })
      .toPromise();
  }

  delete(examId: number): Observable<any> {
    return this.http.delete(`${environment.apiRoot}${EXAM_URL}/${examId}`);
  }
}
