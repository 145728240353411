import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { markFormGroup } from '@app/utils';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-dependent-form',
  templateUrl: './modal-dependent-form.component.html',
  styleUrls: ['./modal-dependent-form.component.scss']
})
export class ModalDependentFormComponent implements OnInit {
  @Input() responsible: any;
  @Input() dependent: any;
  @Output() submit = new EventEmitter<any>();

  constructor(private fb: FormBuilder, private modalRef: BsModalRef) {}

  loading = false;

  form = this.fb.group({
    _id: [undefined],
    name: ['', [Validators.required]],
    fullname: ['', [Validators.required]],
    cpf: ['', [Validators.required, ValidationService.cpfValidator]],
    sex: ['', Validators.required],
    dateOfBirth: [null, [Validators.required, ModalDependentFormComponent.validateUnderAge]],
    membership: this.fb.group({
      mothersName: ['']
    }),
    pendency: [null],
    verifiedCpf: [false]
  });

  static validateUnderAge(control: FormControl): { [key: string]: boolean } | null {
    const birthdate = new Date(control.value);
    const diffDate = Date.now() - birthdate.getTime();
    const ageDate = new Date(diffDate);
    const years = Math.abs(ageDate.getUTCFullYear() - 1970);
    if (years >= 18) {
      return { isNotMinor: true };
    }

    return null;
  }

  ngOnInit() {
    if (this.dependent) {
      this.form.patchValue(this.dependent);
      const date = new Date(this.dependent.dateOfBirth);
      this.form.get('dateOfBirth').setValue(date.toISOString().substring(0, 10));
    }

    this.form.get('name').valueChanges.subscribe(name => {
      this.form.get('fullname').setValue(name);
    });
  }

  get currentDate() {
    const now = new Date();
    return this.formatDate(now);
  }

  get minDate() {
    const now = new Date();
    now.setFullYear(now.getFullYear() - 18);
    return this.formatDate(now);
  }

  private formatDate(now: Date) {
    const dd = String(now.getDate()).padStart(2, '0');
    const mm = String(now.getMonth() + 1).padStart(2, '0');
    const yyyy = now.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  checkCpf(value: boolean) {
    if (value) {
      this.form.get('cpf').setValue(null);
      this.form.get('cpf').disable();
      this.form.get('cpf').setValidators(ValidationService.cpfValidator);
    } else {
      this.form.get('cpf').setValue('');
      this.form.get('cpf').enable();
      this.form.get('cpf').setValidators([Validators.required, ValidationService.cpfValidator]);
    }
  }

  checkResponsibleName(value: boolean) {
    if (value) {
      this.form.get('membership.mothersName').setValue(this.responsible.name);
      this.form.get('membership.mothersName').disable();
    } else {
      this.form.get('membership.mothersName').enable();
    }
  }

  submitForm() {
    markFormGroup(this.form);

    if (this.form.valid) {
      this.submit.emit(this.form.value);
    }
  }
}
