import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { AccountTypeComponent } from './pages/register/account-type.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ActivateAccountComponent } from './pages/activate-account/activate-account.component';
import { PharmacyOptionComponent } from './pages/pharmacy-option/pharmacy-option.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'pharmacy-options', component: PharmacyOptionComponent },
  { path: 'account-type', component: AccountTypeComponent },
  { path: 'register/:type', component: RegisterComponent },
  { path: 'registration-complete', component: WelcomeComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'reset-password/:token', component: ChangePasswordComponent },
  { path: 'activate-account/:token', component: ActivateAccountComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EntryRoutingModule {}
