import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PdfData } from '@app/shared/models/pdf-data';
import { PdfRequest } from '@app/shared/models/pdf-request';
import { environment } from '@env/environment';
export const PDF_URL = 'pdf';

interface UploadResponse {
  message: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  private _endpoint = `${environment.apiRoot}${PDF_URL}`;

  constructor(private http: HttpClient) {}

  get endpoint(): string {
    return this._endpoint;
  }

  useV1Endpoint(): void {
    this._endpoint = `${environment.apiV1}/${PDF_URL}`;
  }

  generatePdf(code: string, theme: string): Promise<PdfData> {
    const params = { theme };
    return this.http.get<PdfData>(`${this._endpoint}/${code}/generate`, { params }).toPromise();
  }

  generateAndSavePdf({ code, draft = false, layout = {} }: PdfRequest): Promise<PdfData> {
    const options = {
      params: {
        ...layout,
        draft: draft.toString(),
        showPrescriberCpf: layout.showPrescriberCpf && layout.showPrescriberCpf.toString()
      }
    };
    return this.http.put<PdfData>(`${this._endpoint}/${code}/save`, {}, options).toPromise();
  }

  downloadPdfDocument(code: string, _secretCode = '') {
    const params = _secretCode ? { params: { _secretCode } } : {};
    return this.http.get<PdfData>(`${this._endpoint}/${code}`, params).toPromise();
  }

  postFilePdf(file: Blob, code: string): Promise<string> {
    return new Promise(async resolve => {
      const reader = new FileReader();
      reader.onloadend = async event => {
        const base64 = reader.result;
        const res = await this.http
          .post<UploadResponse>(`${this._endpoint}/upload`, { base64, code })
          .toPromise();

        resolve(res.url);
      };
      reader.readAsDataURL(file);
    });
  }

  openPdf(pdf: PdfData) {
    const pdfBase64 = pdf.data;
    const binary = atob(pdfBase64.split(',')[1].replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < len; i += 1) {
      view[i] = binary.charCodeAt(i);
    }

    const blob = new Blob([view], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = pdf.filename;
    a.target = '_blank';
    a.click();
    a.remove();
  }
}
