import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Specialty } from '@app/shared/models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

const SPECIALTY_URL = 'specialty';

@Injectable({
  providedIn: 'root'
})
export class SpecialtiesService {
  constructor(private http: HttpClient) {}

  getSpecialtiesByType(type: string): Observable<Specialty[]> {
    return this.http.get<any>(`${environment.apiRoot}${SPECIALTY_URL}/${type}`);
  }

  save(specialty: Specialty): Observable<Specialty> {
    return this.http.post<Specialty>(`${environment.apiRoot}${SPECIALTY_URL}`, specialty);
  }

  update(code, specialty: Specialty): Observable<Specialty> {
    return this.http.put<Specialty>(`${environment.apiRoot}${SPECIALTY_URL}/${code}`, specialty);
  }

  delete(specialty: Specialty): Observable<Specialty> {
    return this.http.delete<Specialty>(`${environment.apiRoot}${SPECIALTY_URL}/${specialty._id}`);
  }

  getSpecialties(filter: any): Observable<Specialty[]> {
    return this.http.get<Specialty[]>(`${environment.apiRoot}${SPECIALTY_URL}`, {
      params: filter
    });
  }
}
