import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { OnlyNumbersPipe } from '@app/shared/pipes/only-numbers.pipe';
import { numeroEspecial } from './helpers';

@Pipe({
  name: 'formattedTel'
})
@Injectable()
export class FormattedTelPipe implements PipeTransform {
  transform(value): string {
    if (value && value !== '') {
      let tel;
      const receivedValue = value ? new OnlyNumbersPipe().transform(value) : value;
      if (numeroEspecial(receivedValue) && receivedValue.length === 8) {
        tel = receivedValue.substring(0, 3) + '-' + receivedValue.substring(3, receivedValue.length - 3);
      } else if (numeroEspecial(receivedValue) && receivedValue.length > 8) {
        tel =
          receivedValue.substring(0, 3) +
          '-' +
          receivedValue.substring(4, receivedValue.length - 4) +
          '-' +
          receivedValue.substring(receivedValue.length - 3, receivedValue.length);
      } else if (receivedValue.length > 2 && receivedValue.length <= 7) {
        tel = `(${receivedValue.substring(0, 2)}) ${receivedValue.substring(2)}`;
      } else if (receivedValue.length <= 2) {
        tel = receivedValue.substring(0, 7);
      } else if (receivedValue.length === 11) {
        tel = `(${receivedValue.substring(0, 2)}) ${receivedValue.substring(
          2,
          7
        )}-${receivedValue.substring(7, receivedValue.length)}`;
      } else {
        tel = `(${receivedValue.substring(0, 2)}) ${receivedValue.substring(
          2,
          6
        )}-${receivedValue.substring(6, receivedValue.length)}`;
      }
      return tel;
    }
  }
}
