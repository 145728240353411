import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { ThemeService } from '@app/theme';
import { AccountService } from '../../services/account.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  loading: boolean;

  form = this.fb.group({
    accountField: ['', Validators.compose([Validators.required, Validators.email])]
  });

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private notification: AppToastService,
    private themeService: ThemeService
  ) {}

  @nativeAsync
  async sendEmail() {
    this.loading = true;
    try {
      const res = await this.accountService.sendEmail(this.form.value.accountField).toPromise();
      this.notification.notify('info', res.message, null);
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  }

  isThemeActiveReceitaDigital() {
    const active = this.themeService.getActiveTheme();
    return active.name === 'theme-receita-digital';
  }
}
