import { Address } from './address';
import { Membership } from './membership';
import { Crf } from './crf';
import { MedicalData } from './medical-data';
import { User } from './decodedLoginToken';

export class Pharmacist {
  _id: string;
  userId: string;
  name: string;
  cpf: string;
  identity: string;
  telephone: string;
  cellphone: string;
  sex: string;
  dateOfBirth: Date;
  membership: Membership;
  maritalStatus: string;
  color: string;
  profession: string;
  schooling: string;
  address: Address;
  medicalData: MedicalData;
  user: User;
  crf: Crf;
}
