import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColorSetting } from '@app/shared/models/color-setting';
import { Protocol, ProtocolGroup } from '@app/shared/models/protocol';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-list-victa-protocol',
  templateUrl: './modal-list-victa-protocol.component.html',
  styleUrls: ['./modal-list-victa-protocol.component.scss']
})
export class ModalListVictaProtocolComponent implements OnInit {
  @Input() groups: ProtocolGroup[];
  @Input() colorSetting: ColorSetting;
  @Output() selectEvent = new EventEmitter<any>();

  constructor(private modalRef: BsModalRef) {}

  ngOnInit() {
    if (this.groups.length === 1) {
      this.activeChange(this.groups[0]);
    }
  }

  activeChange(item: any) {
    item.active = !item.active;
  }

  selectProtocol(protocol: Protocol) {
    this.selectEvent.emit({
      name: `${protocol.code} - ${protocol.name}`,
      code: protocol.code,
      phases: protocol.phases.map(phase => {
        const medicaments = phase.elements
          .map(e => {
            const medicament = e.presentation || e.name;
            return medicament.concat(` - ${e.amount} ${e.measurement}`);
          })
          .join('\n');

        const dosage = [
          phase.dosage ? `Posologia: ${phase.dosage}` : '',
          phase.application,
          phase.preparation,
          phase.note
        ]
          .filter(v => v)
          .join('\n');

        return {
          name: phase.name,
          dosage: `${medicaments}\n\n${dosage}`
        };
      })
    });

    this.close();
  }

  close() {
    this.modalRef.hide();
  }
}
