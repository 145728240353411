import { Component, Input, forwardRef } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor
} from '@angular/forms';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputPasswordComponent)
    }
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class InputPasswordComponent implements ControlValueAccessor {
  @Input() formControlName: string;
  @Input() placeholder: string;
  @Input() isPasswordVisible = false;

  constructor() {}

  writeValue(value: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}
}
