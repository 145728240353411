import { distinctUntilChanged } from 'rxjs/operators';
import { Component, NgZone, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { ModalBuyerDataComponent } from '@app/shared/modals/modal-buyer-data/modal-buyer-data.component';
import { HealthProfessional } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { Prescription } from '@app/shared/models/prescription';
import { Retention } from '@app/shared/models/retention';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PrescriptionService } from '../../services/prescription.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { CrService } from '../../services/cr.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  quantidade = 1;
  allChecked = false;
  indeterminate = false;
  prescription: Prescription = new Prescription();
  prescriptionCode;
  medications;
  quantities = [];
  checkedNumber: number;
  loading = false;
  completeUser: any;
  pharmacyId: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private prescriptionService: PrescriptionService,
    private notification: AppToastService,
    private pharmacyService: PharmacyService,
    private modalService: NzModalService,
    private ngZone: NgZone,
    private crService: CrService,
    private toast: AppToastService
  ) {
    const state = this.router.getCurrentNavigation().extras.state;
    if (state) {
      this.prescription = state.prescription;
      this.prescriptionCode = this.prescription.code;
    }
  }

  @nativeAsync
  async ngOnInit() {
    this.loading = true;

    this.route.params.subscribe(params => {
      this.prescriptionCode = params['param'];
    });
    await this.getCompleteUser();

    this.prescription = await this.prescriptionService
      .getPrescriptionbyCode(this.prescriptionCode)
      .toPromise();

    this.updateMedicaments();

    this.quantities = this.prescription.medicaments.map(m => m.availableQuantity);
    this.loading = false;
  }

  get user(): User {
    return this.authService.user();
  }

  refreshStatus(): void {
    const allChecked = this.prescription.medicaments.every(value => value.valid === true);
    const allUnChecked = this.prescription.medicaments.every(value => !value.valid);
    this.allChecked = allChecked;
    this.indeterminate = !allChecked && !allUnChecked;
    this.checkedNumber = this.prescription.medicaments.filter(value => value.valid).length;
  }

  @nativeAsync
  private async getCompleteUser() {
    try {
      this.loading = true;
      const userType = this.user.userType;

      switch (userType) {
        case 'pharmacy':
          this.completeUser = await this.pharmacyService.getPharmacyByUserId(this.user._id).toPromise();
          this.pharmacyId = this.completeUser._id;
          break;
        default:
          const extraData = JSON.parse(localStorage.getItem('extra_data'));
          this.pharmacyId = extraData.pharmacyId;
      }

      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  }

  checkAll(value: boolean): void {
    this.prescription.medicaments.forEach(data => {
      if (data.availableQuantity > 0) {
        data.valid = value;
      }
    });
    this.refreshStatus();
  }

  getSpecialties() {
    return this.prescription.healthProfessional.specialties.map(s => s.title).join(', ');
  }

  openModalBuyerData() {
    const medicinesRetained = this.prescription.medicaments.filter(m => m.valid);
    if (medicinesRetained.length === 0) {
      this.notification.notify('warning', 'Aviso', 'Selecione um medicamento');
    } else {
      const modal = this.modalService.create({
        nzContent: ModalBuyerDataComponent,
        nzComponentParams: {
          patient: this.prescription.patient
        },
        nzTitle: null,
        nzFooter: null,
        nzWidth: 900
      });
      this.ngZone.run(() => {
        modal.afterOpen.pipe(distinctUntilChanged()).subscribe(() => {
          const instance = modal.getContentComponent();
          if (instance) {
            instance.saveRetention.subscribe(cpf => {
              this.saveRetentionFeedback(cpf, medicinesRetained);
              modal.close();
            });
          }
        });
      });
    }
  }

  @nativeAsync
  async saveRetentionFeedback(cpfBuyer: string, medicinesRetained: any[]) {
    const cpf = cpfBuyer.replace(/[^\d]+/g, '');
    await this.confirm(cpf, medicinesRetained);
  }

  @nativeAsync
  async confirm(cpfBuyer: string, medicinesRetained: any[]) {
    const retention = {
      cpfBuyer,
      medicinesRetained
    };

    this.prescriptionService
      .confirm(retention, this.pharmacyId, this.prescriptionCode)
      .then((res: Retention) => {
        this.router.navigate([`prescription/confirmation/successful/${res.dispensationCode}`]);
      });
  }

  changeRoute() {
    window.location.href = '/dashboard';
  }

  downloadPrescriptionPdf(prescription) {
    this.prescriptionService.downloadPrescriptionPdf(prescription.code);
  }

  getProfessionalTypeName() {
    return HealthProfessional.getProfessionalTypeName(
      this.prescription.healthProfessional.professionalType
    );
  }

  async updateMedicaments() {
    for await (const medicament of this.prescription.medicaments) {
      if (!!medicament.ean) {
        try {
          const medicamentsCR = await this.crService.searchMedicament(medicament.ean).toPromise();
          if (medicamentsCR.length > 0) {
            const medicamentCR = medicamentsCR[0];
            medicament.name = medicamentCR.nome;
            medicament.prescriptionTypeId = medicamentCR.prescriptionTypeId;
            medicament.typeCr = medicamentCR.typeCr;
            medicament.type = 'industrialized';
            medicament.laboratory = medicamentCR.laboratorio;
            medicament.description = medicamentCR.apresentacaoCustomizada;
            medicament.medicamentId = medicamentCR.id;
            medicament.principle = medicamentCR.principio;
          }
        } catch (error) {
          console.log(error);
          this.toast.notify('error', 'Erro ao consultar dados do medicamento.');
        }
      } else {
        medicament.type = 'free-text';
      }
    }
  }
}
