import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

interface RdTabsComponentType {
  label: string;
  value: string;
  disabled: boolean;
}

@Component({
  selector: 'app-rd-tabs',
  templateUrl: './rd-tabs.component.html',
  styleUrls: ['./rd-tabs.component.scss']
})
export class RdTabsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() tabDefault: string;
  @Input() isRennovaFeature = false;

  @Output() onCLickTab = new EventEmitter<string>();

  tabs: RdTabsComponentType[] = [];

  tabSelected: RdTabsComponentType;

  ngOnInit(): void {
    if (this.isRennovaFeature) {
      this.tabs = [{ label: 'Prescrição Rennova', value: 'Prescrição Rennova', disabled: false }];
    } else {
      this.tabs = [
        { label: 'Prescrição', value: 'Prescrição', disabled: false },
        { label: 'Exame', value: 'Exame', disabled: false },
        { label: 'Atestado', value: 'Atestado', disabled: false },
        { label: 'Outros', value: 'Outros', disabled: false }
      ];
    }
  }

  ngOnChanges(_: SimpleChanges): void {
    this.changeTabSelected();
  }

  ngAfterViewInit(): void {
    this.changeTabSelected();
  }

  changeTabSelected() {
    if (this.tabDefault) {
      this.tabSelected = this.tabs.find(tab => tab.value === this.tabDefault.trim());
    }
  }

  onChangeTab(tab: RdTabsComponentType) {
    this.tabSelected = tab;
    this.onCLickTab.emit(tab.value);
  }
}
