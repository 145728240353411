import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
@Injectable()
export class CapitalizePipe implements PipeTransform {
  // first letter uppercase and the others lowercase
  transform(value: string): string {
    return value && value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}
