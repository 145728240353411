import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';

export const USER_URL = `user`;
@Injectable({
  providedIn: 'root'
})
export class AccountService {
  originHost: string;
  constructor(private http: HttpClient) {}

  sendEmail(email: string) {
    return this.http.post<any>(`${environment.apiRoot}${USER_URL}/forgot-password`, {
      email: email,
      originHost: window.location.origin
    });
  }

  checkToken(token: string) {
    return this.http.get(`${environment.apiRoot}${USER_URL}/reset-password/${token}`);
  }

  @nativeAsync
  async activateAccount(form: any) {
    form.originHost = window.location.origin;
    return await this.http.post(`${environment.apiRoot}${USER_URL}/activate-account`, form).toPromise();
  }

  changePassword(token: string, form: any) {
    return this.http.post(`${environment.apiRoot}${USER_URL}/reset-password/${token}`, form);
  }
}
