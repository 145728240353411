import { Address } from './address';
import { Comercial } from './comercial';
import { User } from './decodedLoginToken';
import { ProfessionalType } from './professional-type.enum';
import { Specialty } from './specialty';

export type statusServiceCFM = 'SERVICE_AVAILABLE' | 'SERVICE_UNAVAILABLE';

export type CfmData = {
  validate: {
    isValid: boolean;
    status: statusServiceCFM;
  };
  consult: {
    name?: string;
    uf?: string;
    specialties?: string[];
    updateDate?: string;
    inscription?: {
      code?: string;
      description?: string;
    };
    situation?: {
      code?: string;
      status?: string;
      description?: string;
    };
    isValid: boolean;
    status: statusServiceCFM;
    errorCode?: string;
    errorMessage?: string;
    error?: any;
  };
  pendenteValidacao?: boolean;
};

export class HealthProfessional {
  _id: string;
  userId: string;
  user: User;
  professionalType: ProfessionalType;
  name: string;
  fullname: string;
  cpf: string;
  regionalCouncilNumber: {
    label: string;
    number: string;
    uf: string;
  };
  specialties: Specialty[];
  cellphone: string;
  telephone: string;
  otherPhones?: string[];
  otherEmails?: string[];
  personalAddress?: Address;
  dateOfBirth?: Date;
  sex?: string;
  commercialData?: Comercial[];
  pets?: any[];
  cfm?: CfmData | any;

  loading?: boolean;
  loadingRevalidate?: boolean;
  loadingTenant?: boolean;

  static getRegionalCouncilLabel(professionalType: string) {
    const RegionalCouncilLabels = {
      dentist: 'CRO',
      doctor: 'CRM',
      pharmacist: 'CRF',
      biomedic: 'CRBM1',
      veterinarian: 'CRMV'
    };
    return RegionalCouncilLabels[professionalType];
  }

  static getProfessionalTypeName(professionalType: string) {
    const RegionalCouncilLabels = {
      dentist: 'Dentista',
      doctor: 'Médico',
      pharmacist: 'Farmacêutico',
      biomedic: 'Biomédico',
      veterinarian: 'Veterinário'
    };
    return RegionalCouncilLabels[professionalType];
  }

  static getProfessionalTypeNameLabel(professionalType: string) {
    const RegionalCouncilLabels = {
      dentist: 'Dentista',
      doctor: 'Médico(a)',
      pharmacist: 'Farmacêutico',
      biomedic: 'Biomédico',
      veterinarian: 'Veterinário(a)'
    };
    return RegionalCouncilLabels[professionalType];
  }
}
