import { Patient } from '@app/shared/models/patient';
import { HealthProfessional } from './health-professional';
import { PetPatient } from './pet-patient';
import { ProductRennova } from './product-rennova';

export interface Medicamento {
  precos: string;
  principiosAtivos: string;
  frequencia: string;
  apresentacao: string;
  nome: string;
  id: string;
  quantidade: number;
  posologia: string;
  valid: boolean;
}

export interface Subreceita {
  pdf: string;
  numero: number;
  xml: string;
  hash: string;
}

export interface Documentos {
  pdf: string;
  xml: string;
  subreceitas: Subreceita[];
  hash: string;
}

export class Prescription {
  _id: string;
  code: string;
  createdAt: Date;
  status: string;
  type: string;
  patient?: Patient;
  pet?: PetPatient;
  healthProfessional: HealthProfessional;
  medicaments: any[];
  productsRennova: ProductRennova[];
  retention: any[];
  amountOfMedicines: number;
  local: any;
}
