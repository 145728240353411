import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pharmacist, Pharmacy } from '@app/shared/models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

const PHARMACIST_URL = 'pharmacist';
const PHARMACIST_PHARMACY_URL = 'pharmacist/pharmacy';

@Injectable({
  providedIn: 'root'
})
export class PharmacistService {
  constructor(private http: HttpClient) {}

  save(pharmacist: any): Observable<Pharmacist> {
    pharmacist.originHost = window.location.origin;
    return this.http.post<Pharmacist>(`${environment.apiRoot}${PHARMACIST_URL}`, pharmacist);
  }

  update(id: string, data: any): Observable<Pharmacist> {
    return this.http.put<Pharmacist>(`${environment.apiRoot}${PHARMACIST_URL}/${id}`, data);
  }

  getPharmacistsByPharmacyId(pharmacyId: string, filter: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${PHARMACIST_PHARMACY_URL}/${pharmacyId}`, {
      params: filter
    });
  }

  getPharmacistByUserId(userId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${PHARMACIST_URL}/user/${userId}`);
  }

  getPharmacyByCpf(cpf: string): Observable<Pharmacist> {
    return this.http.get<Pharmacist>(`${environment.apiRoot}${PHARMACIST_URL}/cpf/${cpf}`);
  }

  getPharmaciesByFilter(filter: any): Observable<any> {
    return this.http.get<Pharmacist[]>(`${environment.apiRoot}${PHARMACIST_URL}/filter`, {
      params: filter
    });
  }

  getPharmacies(pharmacistId) {
    return this.http.get<Pharmacy[]>(
      `${environment.apiRoot}${PHARMACIST_URL}/pharmacies/${pharmacistId}`
    );
  }
}
