import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { CoreService } from '@app/core/services/core.service';
import { PetPatientService } from '@app/modules/patients/services/pet-patient.service';
import { DateUtils } from '@app/shared/data/DateUtils';
import { Furs } from '@app/shared/data/Fur';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { Fur } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { Race } from '@app/shared/models/races';
import { Species } from '@app/shared/models/species';
import { UserType } from '@app/shared/models/user-type.enum';
import moment from 'moment';

@Component({
  selector: 'app-pet-data-form',
  templateUrl: './pet-data-form.component.html',
  styleUrls: ['./pet-data-form.component.scss']
})
export class PetDataFormComponent implements OnInit {
  @Input() form: FormGroup;

  species: Species[];
  races: Race[];
  furs: Fur[] = Furs;

  labelAge = 'Idade';
  isPatient = false;
  isMobile = false;
  isExternalRegister = false;

  constructor(
    private petPatientService: PetPatientService,
    private authService: AuthService,
    private coreService: CoreService,
    private router: Router
  ) {
    this.onResize();
    this.isExternalRegister = this.router.url === '/entry/register/patient';
  }
  @nativeAsync
  async ngOnInit() {
    this.form.get('race').setValidators([Validators.required]);
    this.form.get('dateOfBirth').setValidators([Validators.required]);
    this.form.get('sex').setValidators([Validators.required]);

    this.species = await this.petPatientService.getSpecies().toPromise();

    this.form.get('dateOfBirth').valueChanges.subscribe(dateOfBirth => {
      if (dateOfBirth) {
        const diffBetweenDate = DateUtils.diffBetweenDate(new Date(), dateOfBirth);

        this.form.get('approximateAgeLabel').setValue(diffBetweenDate.result);
      }
    });

    this.isPatient = this.isExternalRegister || this.user.userType === UserType.PATIENT;
  }

  get user(): User {
    return this.authService.user();
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = this.coreService.isViewPortMobile();
  }

  get cpf() {
    return this.form.get('responsible.cpf');
  }

  get currentDate() {
    return moment().format('YYYY-MM-DD');
  }
  @nativeAsync
  async setSpecies(title: any) {
    const species = this.species.find(s => s.title === title);
    await this.getRaces(species._id);
  }
  @nativeAsync
  async getRaces(speciesId: string) {
    this.form.get('race').setValue('');
    this.races = await this.petPatientService.getRaces(speciesId).toPromise();
  }

  checkApproximateAge(value: boolean) {
    if (value) {
      this.labelAge = 'Idade Aproximada';
    } else {
      this.labelAge = 'Idade';
    }
  }
}
