import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { FileStepComponent } from './file-step/file-step.component';
import { SubaccountStepComponent } from './subaccount-step/subaccount-step.component';
import { SubscriptionStepComponent } from './subscription-step/subscription-step.component';

@Component({
  selector: 'app-form-steps',
  templateUrl: './form-steps.component.html',
  styleUrls: ['./form-steps.component.scss', '../modal-telemedicine-contract.component.scss']
})
export class FormStepsComponent implements OnInit {
  @Input() userType: string;
  @Output() title = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<any>();
  @Output() submitForm = new EventEmitter<any>();

  @ViewChild(FileStepComponent)
  fileStepComponent: FileStepComponent;

  @ViewChild(SubaccountStepComponent)
  subaccountStepComponent: SubaccountStepComponent;

  @ViewChild(SubscriptionStepComponent)
  subscriptionStepComponent: SubscriptionStepComponent;

  step = 1;
  profile: string;

  fileForm: any;
  subaccountForm: any;
  subscriptionForm: any;

  constructor(private notification: AppToastService) {}

  ngOnInit() {
    this.emitTitle(this.step);
  }

  changeStep(advance: boolean) {
    if (advance) {
      this.advanceStep();
    } else {
      this.backStep();
    }
  }

  private advanceStep() {
    if (this.step === 1 && this.fileStepComponent.invalid) {
      this.showWarning(this.fileStepComponent.errorMessage);
    } else if (this.step === 2 && this.subaccountStepComponent.invalid) {
      this.showWarning(this.subaccountStepComponent.errorMessage);
    } else if (this.step === 3) {
      if (this.subscriptionStepComponent.invalid) {
        this.showWarning(this.subscriptionStepComponent.errorMessage);
      } else {
        this.submit();
      }
    } else {
      this.step += 1;
      this.emitTitle(this.step);
    }
  }

  private backStep() {
    if (this.step === 1) {
      this.title.emit(null);
      this.cancel.emit();
    } else {
      this.step -= 1;
      this.emitTitle(this.step);
    }
  }

  private showWarning(message: string) {
    this.notification.notify('warning', 'Aviso', message);
  }

  private emitTitle(step: number) {
    if (step === 1) {
      this.title.emit('Documento');
    } else if (step === 2) {
      this.title.emit('Dados bancários');
    } else if (step === 3) {
      this.title.emit('Pagamento');
    }
  }

  private submit() {
    this.submitForm.emit({
      subaccount: this.subaccountForm,
      subscription: {
        ...this.subscriptionForm,
        emails: [this.subaccountForm.email]
      },
      document: this.fileForm.documentFile
    });
  }
}
