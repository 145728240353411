import { distinctUntilChanged } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { BrazilState } from '@app/shared/models';
import { ResponseSearchCnpj } from '@app/shared/models/reponse-search-cnpj';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { BigboostService } from './../../../../../shared/services/bigboost.service';
import { OpenDataService } from './../../../../../shared/services/open-data.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';

const onlyNumbers = (valor: string) => {
  return valor.replace(/[^0-9]+/g, '');
};

@Component({
  selector: 'app-pharmacy-form',
  templateUrl: './pharmacy-form.component.html',
  styleUrls: ['./pharmacy-form.component.scss']
})
export class PharmacyFormComponent implements OnInit, AfterContentChecked {
  readonly STATUS_VALID = 'VALID';

  @Output() formChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  states: BrazilState[] = BrazilianStates;

  form: FormGroup;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private cepService: CepService,
    private bigboostService: BigboostService,
    private openDataService: OpenDataService,
    private notification: AppToastService,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this.fb.group({
      cnpj: [null, [Validators.required, ValidationService.cnpjValidator]],
      businessName: ['', Validators.required],
      fantasyName: ['', Validators.required],
      cnae: this.fb.group({
        code: [''],
        description: ['']
      }),
      cpfResponsible: ['', [ValidationService.cpfValidator]],
      telephone: ['', Validators.required],
      cellphone: [''],
      email: ['', [Validators.required, Validators.email]],
      emailConfirmation: [
        '',
        [Validators.required, Validators.email, ValidationService.confirmEmailValidator('email')]
      ],
      licenseNumber: [''],
      technicalResponsible: this.fb.group({
        name: ['', Validators.compose([ValidationService.nomeValidator])],
        cpf: ['', [ValidationService.cpfValidator]],
        crf: this.fb.group({
          number: [''],
          uf: ['']
        })
      }),
      address: this.fb.group({
        uf: [null, Validators.required],
        city: ['', Validators.required],
        complement: [''],
        number: ['', Validators.required],
        street: ['', Validators.required],
        neighborhood: ['', Validators.required],
        cep: ['', [Validators.required, ValidationService.cepValidator]]
      }),
      pendency: [null],
      public_pharmacy: [false],
      cnes: [null],
      cnpj_entity: [''],
      business_name_entity: [''],
      verified: [false]
    });
  }

  get isPharmacyPublic(): boolean {
    return this.form.get('public_pharmacy').value;
  }

  ngOnInit() {
    this.form.get('email').valueChanges.subscribe(email => {
      this.form.get('email').setValue(email && email.toLowerCase(), { emitEvent: false });
      this.formChange.emit(this.form);
    });
    this.form.get('emailConfirmation').valueChanges.subscribe(email => {
      this.form.get('emailConfirmation').setValue(email && email.toLowerCase(), { emitEvent: false });
      this.formChange.emit(this.form);
    });

    this.form.get('cnpj').valueChanges.subscribe(() => {
      this.form.get('verified').setValue(false);
      this.formChange.emit(this.form);
    });
    this.listenAndApplyValitationsPublicPharmacy();
    this.formChange.emit(this.form);
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  listenAndApplyValitationsPublicPharmacy() {
    this.form
      .get('public_pharmacy')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => {
        if (value) {
          this.form.get('cnes').setValidators([Validators.required]);
          this.form.get('cnpj_entity').setValidators([Validators.required]);

          this.form.get('cnpj').clearValidators();
          this.form.get('businessName').clearValidators();
          if (!this.verified) {
            this.form.get('cnpj').reset();
          }
        } else {
          this.form.get('cnes').clearValidators();
          this.form.get('cnpj_entity').clearValidators();

          this.form.get('cnpj').setValidators([Validators.required, ValidationService.cnpjValidator]);
          this.form.get('businessName').setValidators([Validators.required]);
          if (!this.verified) {
            this.form.get('cnes').reset();
          }
        }
        this.form.get('cnes').updateValueAndValidity();
        this.form.get('cnpj_entity').updateValueAndValidity();

        this.form.get('cnpj').updateValueAndValidity();
        this.form.get('businessName').updateValueAndValidity();
        this.formChange.emit(this.form);
      });
  }

  @nativeAsync
  async handleConsult() {
    if (this.isPharmacyPublic) {
      await this.handleConsultCnes();
    } else {
      await this.handleConsultCnpj();
    }
  }

  @nativeAsync
  async handleConsultCnes() {
    this.isLoading = true;
    const cnes = this.form.get('cnes').value;
    this.openDataService
      .consultCnes(cnes)
      .subscribe(
        response => {
          const cnae = {
            code: response.cnae_entity,
            description: response.cnae_entity_description
          };
          const pharmacyInformation = {
            cnes: response.cnes,
            cnpj_entity: response.cnpj_entity,
            business_name_entity: response.business_name,
            fantasyName: response.fantasy_name,
            cnae: {
              ...cnae
            }
          };

          this.form.patchValue(pharmacyInformation);
          this.verified = true;
          this.cdr.detectChanges();
          this.form.get('cnae').disable();
        },
        (httpError: HttpErrorResponse) => {
          if (httpError.status === 400 || httpError.status === 409) {
            const { message } = httpError.error;
            this.notification.notify('warning', message);
          } else {
            this.notification.notify('error', 'Error ao tentar consultar CNAE');
          }
          console.error(httpError);
          this.verified = false;
        }
      )
      .add(() => {
        this.isLoading = false;
        this.cdr.detectChanges();
      });
  }

  @nativeAsync
  async handleConsultCnpj() {
    this.isLoading = true;
    const cnpj = onlyNumbers(this.form.get('cnpj').value);
    try {
      const response = await this.bigboostService.consultCnpj(cnpj);
      if (response.erro) {
        if (response.erroCodigo === 102) {
          this.handleErrorMessageStatusCode102();
          return;
        } else {
          const consult = {
            status: -1,
            cnpj,
            erroCodigo: response.erroCodigo,
            erro: 'A importação de dados do cnpj está com instabilidade'
          };
          this.form.get('pendency').setValue(consult);
          this.notification.notify(
            'warning',
            'Aviso',
            'A importação de dados do CNPJ está com instabilidade, mas você poderá se cadastrar preenchendo os campos manualmente.'
          );
          this.verified = true;
        }
      }

      this.setDataForm(response);
      if (response) {
        this.form.get('businessName').disable();
      }
      this.verified = true;
    } catch (err) {
      if (err.status === 400 || err.status === 409) {
        const { message } = err.error;
        this.notification.notify('warning', message);
        this.form.get('cnpj').setErrors({ notExists: true });
      } else {
        this.notification.notify('error', 'Error ao tentar consultar CNPJ');
      }
      console.error(err);
      this.verified = false;
    } finally {
      this.isLoading = false;
      this.cdr.detectChanges();
    }
  }

  private handleErrorMessageStatusCode102(): void {
    this.notification.notify(
      'warning',
      'Aviso',
      'O CNPJ informado não existe em nossos fornecedores. Por favor, confira o número do CNPJ e tente novamente. ' +
        'Em caso de dúvidas, entre em contato com o nosso suporte'
    );
  }

  get verified() {
    return this.form.get('verified').value;
  }

  set verified(value: boolean) {
    this.form.get('verified').setValue(value);
    this.cdr.detectChanges();
  }

  private setDataForm(response: ResponseSearchCnpj): void {
    const cnae = response.cnae
      ? {
          code: response ? response.cnae.fiscal : null,
          description: response ? response.cnae.descricao : null
        }
      : {
          code: '',
          description: ''
        };
    const pharmacyInformation = {
      businessName: response ? response.razao : null,
      fantasyName: response ? response.fantasia : null,
      cnae: {
        ...cnae
      }
    };

    this.form.patchValue(pharmacyInformation);
  }

  @nativeAsync
  async onKey(event: any, address: FormGroup) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        address.patchValue({
          uf: data.uf,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          number: null,
          cep: data.cep
        });
      }
    }
  }
}
