import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrescriptionService } from '@app/modules/document/services/prescription.service';

@Component({
  selector: 'app-consult-not-found',
  templateUrl: './consult-not-found.component.html',
  styleUrls: ['./consult-not-found.component.scss']
})
export class ConsultNotFound implements OnInit {
  loading = false;
  prescription: any;
  showDispensations = true;

  constructor(private router: Router, private prescriptionService: PrescriptionService) {
    this.prescription = this.prescriptionService.prescription;
  }

  ngOnInit() {}

  register(): void {
    this.router.navigate([`/dispensation/register`]);
  }
}
