import { Injectable } from '@angular/core';
import { ManipulatedProfile } from '@app/shared/models';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

const MANIPULATED_PROFILE_URL = 'manipulated-profile';
const MANIPULATION_URL = 'manipulation';
const MANIPULATED_PROFILE_PRESCRIBER_URL = `manipulated-profile/prescriber`;
const DRAFT_MANIPULATED_PROFILE_PATIENT_URL = `manipulated-profile/patient/draft`;

@Injectable({
  providedIn: 'root'
})
export class ManipulatedProfileService {
  constructor(private http: HttpClient) {}

  save(manipulatedProfile: ManipulatedProfile): Observable<ManipulatedProfile> {
    return this.http.post<ManipulatedProfile>(
      `${environment.apiRoot}${MANIPULATED_PROFILE_URL}`,
      manipulatedProfile
    );
  }

  update(id, manipulatedProfile: any): Observable<ManipulatedProfile> {
    return this.http.put<ManipulatedProfile>(
      `${environment.apiRoot}${MANIPULATED_PROFILE_URL}/${id}`,
      manipulatedProfile
    );
  }

  addPendency(manipulatedProfileId, pendency: any): Observable<ManipulatedProfile> {
    return this.http.put<ManipulatedProfile>(
      `${environment.apiRoot}${MANIPULATED_PROFILE_URL}/${manipulatedProfileId}/add-pendency`,
      pendency
    );
  }

  resolvePending(manipulatedProfileId, pendency: any): Observable<ManipulatedProfile> {
    return this.http.put<ManipulatedProfile>(
      `${environment.apiRoot}${MANIPULATED_PROFILE_URL}/${manipulatedProfileId}/resolve-pending`,
      pendency
    );
  }

  conclude(id, manipulatedProfile: any): Observable<ManipulatedProfile> {
    return this.http.put<ManipulatedProfile>(
      `${environment.apiRoot}${MANIPULATED_PROFILE_URL}/${MANIPULATION_URL}/conclude/${id}`,
      manipulatedProfile
    );
  }

  updateEditingExpirationTime(id: any): Observable<ManipulatedProfile> {
    return this.http.put<ManipulatedProfile>(
      `${environment.apiRoot}${MANIPULATED_PROFILE_URL}/${MANIPULATION_URL}/editing-expiration-time/${id}`,
      {}
    );
  }

  getManipulatedProfilesByPrescriberUserId(prescriber: string, filter: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiRoot}${MANIPULATED_PROFILE_PRESCRIBER_URL}/${prescriber}`,
      {
        params: filter
      }
    );
  }

  getManipulatedProfiles(filter: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${MANIPULATED_PROFILE_URL}`, {
      params: filter
    });
  }

  getPendencytHistory(manipulatedProfileId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiRoot}${MANIPULATED_PROFILE_URL}/pendency/${manipulatedProfileId}/history`
    );
  }

  getManipulatedProfilesForPharmacist(pharmacyId, filter: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiRoot}${MANIPULATED_PROFILE_URL}/pharmacist/${pharmacyId}`,
      {
        params: filter
      }
    );
  }

  getManipulatedProfilesByPharmacy(pharmacyId: string, filter: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiRoot}${MANIPULATED_PROFILE_URL}/pharmacy/${pharmacyId}`,
      {
        params: filter
      }
    );
  }

  getManipulatedProfileById(profileId: string): Observable<ManipulatedProfile> {
    return this.http.get<ManipulatedProfile>(
      `${environment.apiRoot}${MANIPULATED_PROFILE_URL}/${profileId}`
    );
  }

  getManipulatedProfileByIdForManipulation(
    profileId: string,
    pharmacyId: string
  ): Observable<ManipulatedProfile> {
    return this.http.get<ManipulatedProfile>(
      `${environment.apiRoot}${MANIPULATED_PROFILE_URL}/${MANIPULATION_URL}/${profileId}/${pharmacyId}`
    );
  }

  getDraftManipulatedBrofileByPatient(patientId) {
    return this.http.get<any>(
      `${environment.apiRoot}${DRAFT_MANIPULATED_PROFILE_PATIENT_URL}/${patientId}`
    );
  }
}
