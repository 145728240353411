import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChange,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent implements OnChanges {
  @Input() password: string;
  @Output() isStrong = new EventEmitter<boolean>();

  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  bar4: string;

  msg = '';
  private colors = ['darkred', 'orangered', 'orange', 'yellowgreen'];

  ngOnChanges(changes: SimpleChanges) {
    const password = changes.password.currentValue;
    this.setBarColors(this.colors.length, '#DDD');

    if (password) {
      const pwdStrength = this.checkStrength(password);
      const c = this.getColor(pwdStrength);
      this.setBarColors(c.idx, c.col);

      this.isStrong.emit(pwdStrength >= 30);
      this.msg = this.getMessage(c.idx);
    } else {
      this.msg = '';
    }
  }

  private checkStrength(p: string) {
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = /[$-/:-?{-~!"^_@`\[\]]/g.test(p);

    const flags = [lowerLetters, upperLetters, numbers, symbols];

    const passedMatches = flags.reduce((sum, item) => sum + +item, 0);

    let force = 0;
    force += 2 * p.length + +(p.length >= 10);
    force += passedMatches * 10;

    // short password
    force = p.length <= 6 ? Math.min(force, 10) : force;

    // poor variety of characters
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 30) : force;
    force = passedMatches === 4 ? Math.min(force, 40) : force;

    return force;
  }

  private getColor(force: number) {
    let idx = 0;
    if (force <= 10) {
      idx = 0;
    } else if (force <= 20) {
      idx = 1;
    } else if (force <= 30) {
      idx = 2;
    } else {
      idx = 3;
    }
    return {
      idx: idx + 1,
      col: this.colors[idx]
    };
  }

  private setBarColors(count: number, col: string) {
    for (let i = 0; i < count; i++) {
      this['bar' + i] = col;
    }
  }

  private getMessage(idx: number) {
    switch (idx) {
      case 1:
        return 'Muito fraca';
      case 2:
        return 'Fraca';
      case 3:
        return 'Média';
      case 4:
        return 'Forte';
      case 5:
        return 'Muito forte';
    }
  }
}
