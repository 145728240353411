import moment from 'moment';

const AGE_TEENAGER = 13;
const AGE_ADULT = 18;

export class DateUtils {
  static isUnderAge(dateOfBirth: Date | string, format = null) {
    const date = dateOfBirth instanceof Date ? dateOfBirth : moment(dateOfBirth, format);
    const age = moment().diff(date, 'years', false);
    return age < AGE_ADULT;
  }
  static isUnderThirteenTearsOfAge(dateOfBirth: Date | string, format = null) {
    const date = dateOfBirth instanceof Date ? dateOfBirth : moment(dateOfBirth, format);
    const age = moment().diff(date, 'years', false);
    return age < AGE_TEENAGER;
  }

  static diffBetweenDate(date1: Date | string, date2: Date | string) {
    // new date instance
    const newDate1 = new Date(date1);
    const newDate2 = new Date(date2);

    // Get the Timestamp
    const date1TimeStamp = newDate1.getTime();
    const date2TimeStamp = newDate2.getTime();

    let calc;

    // Check which timestamp is greater
    if (date1TimeStamp > date2TimeStamp) {
      calc = new Date(date1TimeStamp - date2TimeStamp);
    } else {
      calc = new Date(date2TimeStamp - date1TimeStamp);
    }
    // Retrieve the date, month and year
    const calcFormatTmp = calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear();
    // Convert to an array and store
    const calcFormat = calcFormatTmp.split('-');
    // Subtract each member of our array from the default date
    const daysPassed = Number(Math.abs(Number(calcFormat[0])) - 1);
    const monthsPassed = Number(Math.abs(Number(calcFormat[1])) - 1);
    const yearsPassed = Number(Math.abs(Number(calcFormat[2])) - 1970);

    // Set up custom text
    const yrsTxt = ['ano', 'anos'];
    const mnthsTxt = ['mês', 'meses'];
    const daysTxt = ['dia', 'dias'];

    // Convert to days and sum together
    const totalDays = yearsPassed * 365 + monthsPassed * 30.417 + daysPassed;
    const totalSecs = totalDays * 24 * 60 * 60;
    const totalMins = totalDays * 24 * 60;
    const totalHours = totalDays * 24;
    const totalWeeks = totalDays >= 7 ? totalDays / 7 : 0;

    // display result with custom text
    const result =
      (yearsPassed == 1
        ? yearsPassed + ' ' + yrsTxt[0] + ' '
        : yearsPassed > 1
        ? yearsPassed + ' ' + yrsTxt[1] + ' '
        : '') +
      (monthsPassed == 1
        ? monthsPassed + ' ' + mnthsTxt[0]
        : monthsPassed > 1
        ? monthsPassed + ' ' + mnthsTxt[1] + ' '
        : '') +
      (daysPassed == 1
        ? daysPassed + ' ' + daysTxt[0]
        : daysPassed > 1
        ? daysPassed + ' ' + daysTxt[1]
        : '');

    // return the result
    return {
      totalDays: Math.round(totalDays),
      totalWeeks: Math.round(totalWeeks),
      totalHours: Math.round(totalHours),
      totalMinutes: Math.round(totalMins),
      totalSeconds: Math.round(totalSecs),
      result: result.trim()
    };
  }
}
