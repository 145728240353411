import { map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { BuyerService } from '@app/modules/document/services/buyer.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { BrazilState } from '@app/shared/models';
import { Patient } from '@app/shared/models/patient';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { BigboostService } from '@app/shared/services/bigboost.service';
import { markFormGroup } from '@app/utils/markFormGroup';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { of, pipe } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-modal-buyer-data',
  templateUrl: './modal-buyer-data.component.html',
  styleUrls: ['./modal-buyer-data.component.scss']
})
export class ModalBuyerDataComponent implements OnInit {
  @Input() patient: Patient;
  @Input() okText = 'Salvar';
  @Output() saveRetention = new EventEmitter();

  cpfSearched = false;
  states: BrazilState[] = BrazilianStates;
  loading = false;
  form: FormGroup;
  CEL_TEL: string;

  constructor(
    private fb: FormBuilder,
    private cepService: CepService,
    private bigboostService: BigboostService,
    private notification: AppToastService,
    private buyerService: BuyerService,
    private modelRef: NzModalRef,
    private patientService: PatientsService
  ) {
    this.buildForm();
  }
  ngOnInit(): void {
    this.listnerFieldCpf();
    this.form
      .get('cellphone')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value: string) => {
        this.setMaskTelephone(value);
      });

    this.form.get('cellphone').statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.form.get('telephone').enable();
      } else {
        this.form.get('telephone').setValue('');
        this.form.get('telephone').disable();
      }
    });

    this.form
      .get('telephone')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value: string) => {
        this.setMaskTelephone(value);
      });
  }

  get isPatientBuyer() {
    return this.form.get('isPatientBuyer').value;
  }

  set isPatientBuyer(value: boolean) {
    this.form.get('isPatientBuyer').setValue(value);
  }

  get cpfControl(): AbstractControl {
    return this.form.get('cpf');
  }

  buildForm() {
    this.form = this.fb.group({
      isPatientBuyer: this.fb.control(false),
      name: this.fb.control('', [Validators.required]),
      cpf: this.fb.control('', [Validators.required, ValidationService.cpfValidator]),
      identity: this.fb.control(''),
      cellphone: this.fb.control('', Validators.required),
      telephone: this.fb.control(''),
      address: this.fb.group({
        uf: this.fb.control('', [Validators.required, Validators.minLength(2)]),
        city: this.fb.control('', [Validators.required, Validators.minLength(2)]),
        complement: this.fb.control(''),
        number: this.fb.control('', [Validators.required]),
        street: this.fb.control('', [Validators.required, Validators.minLength(2)]),
        neighborhood: this.fb.control('', [Validators.required, Validators.minLength(2)]),
        cep: this.fb.control(
          '',
          Validators.compose([
            Validators.required,
            ValidationService.cepValidator,
            Validators.minLength(2)
          ])
        )
      })
    });
  }

  setMaskTelephone(value: string) {
    if (value) {
      const CEL = '(00) 0 0000-0000';
      const TEL = '(00) 0000-00009';
      this.CEL_TEL = value.length == 11 ? CEL : TEL;
    }
  }

  listnerFieldCpf() {
    this.cpfControl.statusChanges.pipe(distinctUntilChanged()).subscribe(status => {
      const valueCpf = this.cpfControl.value;
      const valueIsPatientBuyer = this.isPatientBuyer;
      if (status === 'VALID' && !this.isPatientBuyer) {
        this.searchData(valueCpf);
      } else {
        this.cpfSearched = false;
        this.form.reset({ cpf: valueCpf, isPatientBuyer: valueIsPatientBuyer });
      }
    });
  }

  @nativeAsync
  async onKeyFindCep(event: any) {
    if (this.cpfSearched) {
      if (event.target.value.length === 10) {
        const cep = event.target.value.replace(/[^\d]+/g, '');
        const data = await this.cepService.consult(cep);
        if (!data.erro) {
          this.form.controls['address'].patchValue({
            uf: data.uf,
            street: data.logradouro,
            neighborhood: data.bairro,
            city: data.localidade,
            number: null,
            cep: data.cep
          });
        }
      }
    }
  }

  searchData(cpfBuyer: string) {
    const cpf = cpfBuyer.replace(/[^\d]+/g, '');
    this.loading = true;
    this.buyerService.getBuyerByCpf(cpf).subscribe(
      buyer => {
        if (buyer) {
          this.setDataForm(buyer);
        } else {
          this.findInfoPatientByCpf(cpf);
        }
      },
      err => {
        this.loading = false;
        console.error(err);
        this.notification.notify('error', 'Error ao consultar comprador');
      }
    );
  }

  setDataPatient(isPatientBuyer: boolean) {
    if (isPatientBuyer) {
      if (this.patient._id) {
        const data = !!this.patient.responsible ? this.patient.responsible : this.patient;
        this.setDataForm(data);
      }
    } else {
      this.cpfSearched = false;
      this.form.reset();
    }
  }

  setDataForm(data: Patient) {
    this.form.patchValue(data);
    this.form.get('name').setValue(data.name);
    this.loading = false;
    this.cpfSearched = true;
  }

  save() {
    markFormGroup(this.form);
    if (this.form.valid && !this.loading) {
      this.loading = true;
      this.buyerService.save(this.form.value).subscribe(
        buyer => {
          if (buyer) {
            this.saveRetention.emit(buyer.cpf);
            this.modelRef.destroy();
          }
        },
        err => {
          this.notification.notify('error', 'Erro ao tentar salvar comprador', err.error.message);
        },
        () => {
          this.loading = false;
        }
      );
    }
  }

  close() {
    this.modelRef.close();
  }

  private findInfoPatientByCpf(cpf: string) {
    this.patientService
      .existsPatient(cpf)
      .pipe(
        switchMap(exists => {
          if (exists) {
            return this.patientService.getPatientByCpf(cpf);
          }
          return of(null);
        })
      )
      .subscribe(
        (patient: Patient | null) => {
          if (patient) {
            const data = !!patient.responsible ? patient.responsible : patient;
            this.setDataForm(data);
          } else {
            this.consultCpfByCpf(cpf);
          }
        },
        err => {
          this.loading = false;
          console.error(err);
          this.notification.notify('error', 'Error ao consultar paciente');
        }
      );
  }

  private consultCpfByCpf(cpf: string) {
    this.bigboostService.consultCpf(cpf).subscribe(
      data => {
        if (data) {
          if (data.menorIdade) {
            this.notification.notify(
              'warning',
              'Não é permitido cadastrar compradores menores de idade'
            );
          } else {
            this.setDataForm({ name: data.nome });
          }
          this.loading = false;
        }
      },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.loading = false;
        if (error.status === 404) {
          this.notification.notify(
            'error',
            'Error ao consultar CPF',
            'CPF não localizado na Receita Federal. Verifique as informações digitadas e tente novamente. Em caso de dúvidas, entre em contato com nosso atendimento',
            8000
          );
          return;
        } else {
          this.notification.notify('error', 'Error ao consultar CPF');
        }
      }
    );
  }
}
