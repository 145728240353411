export enum CpfCnpjApiErrors {
  INVALID_TOKEN = 1000,
  INSUFFICIENT_CREDITS = 1001,
  SUSPENDED_ACCOUNT = 1002,
  BLACKLIST = 1003,
  PACKAGE_UNAVAILABLE = 1004,
  IS_NOT_POSSIBLE_TO_CONSULT = 1005,
  OFFLINE_SUPPLIER = 1006,
  CPF_INVALID = 100,
  CPF_WITH_11_DIGIT = 101,
  CPF_DOES_NOT_EXIST = 102,
  CNPJ_INVALID = 200,
  CNPJ_WITH_11_DIGIT = 201,
  CNPJ_DOES_NOT_EXIST = 202
}
