import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientAppService {
  private _apiKey: string;

  constructor(private http: HttpClient) {}

  set apiKey(apiKey: string) {
    this._apiKey = apiKey;
  }

  get apiKey() {
    return this._apiKey;
  }

  validateToken(token: string, payloadChecksum: string) {
    const headers = new HttpHeaders().set('x-api-key', this._apiKey);
    return this.http
      .post<any>(`${environment.apiV1}/validate-payload`, { token, payloadChecksum }, { headers })
      .toPromise();
  }
}
