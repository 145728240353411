import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formattedCpf'
})
export class FormattedCpfPipe implements PipeTransform {
  transform(value: string): string {
    return value && value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
}
