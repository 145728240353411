import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sexPetParsed'
})
export class SexPetParsedPipe implements PipeTransform {
  transform(value: string): string {
    if (value && value !== '') {
      return value === 'M' ? 'Macho' : 'Fêmea';
    }
  }
}
