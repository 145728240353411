import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { AppToastService } from '../services/app-toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private notification: AppToastService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (environment.maintenance) {
      this.router.navigate(['/maintenance']);
      return of(false);
    }
    return this.checkLogin(route);
  }

  checkLogin(route: ActivatedRouteSnapshot): boolean {
    const isExpired = this.authService.isLoggedOut;
    if (isExpired) {
      const token = this.authService.token;
      if (token) {
        this.notification.notify('warning', 'Aviso', 'Acesso expirado. Realize o login novamente');
      }
      this.logout();
    } else {
      const user = this.authService.user();
      if (!user.features) {
        this.authService.refreshToken().subscribe();
      }
    }
    return !isExpired;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['entry/login']);
  }
}
