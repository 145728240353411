import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { markFormGroup } from '@app/utils';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Patient } from './../../../shared/models/patient';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';

@Component({
  selector: 'app-modal-complement-info-patient',
  templateUrl: './modal-complement-info-patient.component.html',
  styleUrls: ['./modal-complement-info-patient.component.scss']
})
export class ModalComplementInfoPatientComponent implements OnInit {
  patientId: string;
  patient: Patient;
  @Output() updateCompleteUser = new EventEmitter();

  form: FormGroup;

  constructor(
    private patientsService: PatientsService,
    private modalRef: BsModalRef,
    private notification: AppToastService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      identity: [this.patient.identity],
      cellphone: [this.patient.cellphone, Validators.required],
      address: this.fb.group({
        complement: [''],
        city: [this.patient.address ? this.patient.address.city : '', Validators.required],
        number: [this.patient.address ? this.patient.address.number : '', Validators.required],
        street: [this.patient.address ? this.patient.address.street : '', Validators.required],
        neighborhood: [
          this.patient.address ? this.patient.address.neighborhood : '',
          Validators.required
        ],
        cep: [this.patient.address ? this.patient.address.cep : '', Validators.required],
        uf: [this.patient.address ? this.patient.address.uf : '', Validators.required]
      })
    });
  }

  @nativeAsync
  async save() {
    markFormGroup(this.form);
    if (this.form.valid) {
      try {
        const data = {
          ...this.patient,
          identity: this.form.get('identity').value,
          cellphone: this.form.get('cellphone').value,
          address: {
            ...this.form.get('address').value
          }
        } as Patient;

        const patient = await this.patientsService.update(this.patientId, data).toPromise();
        this.updateCompleteUser.emit(patient);
        this.notification.notify('success', 'Dados atualizados com sucesso!', null);
        this.modalRef.hide();
      } catch (error) {
        console.error(error);
        this.notification.notify(
          'error',
          'Ocorreu um erro ao tentar atualizar os dados cadastrais',
          null
        );
      }
    }
  }

  viewAddress() {
    return (
      !this.patient.address ||
      (this.patient.address &&
        !this.patient.address.cep &&
        !this.patient.address.street &&
        !this.patient.address.number &&
        !this.patient.address.neighborhood &&
        !this.patient.address.city &&
        !this.patient.address.uf)
    );
  }
}
