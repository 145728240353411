import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RetentionService } from '@app/modules/document/services/retention.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-cancel-retention',
  templateUrl: './modal-cancel-retention.component.html',
  styleUrls: ['./modal-cancel-retention.component.scss']
})
export class ModalCancelRetentionComponent implements OnInit {
  userId: string;
  retentionId: string;
  isRetentionsThirdParties = false;
  loading = false;
  @Input() isVisible = true;
  @Output() updateListPrescriptionEvent = new EventEmitter<any>();
  reason = '';

  constructor(
    private nzModalRef: NzModalRef,
    private notification: AppToastService,
    private retentionService: RetentionService
  ) {}

  ngOnInit() {}
  @nativeAsync
  async handleOk() {
    if (this.reason === '') {
      this.notification.notify('warning', 'Aviso', 'O motivo é obrigatório');
      return;
    }

    if (this.reason.length < 10) {
      this.notification.notify('warning', 'Aviso', 'O motivo não pode ter menos que 10 caracteres');
      return;
    }
    const data = { reason: this.reason, userId: this.userId, retentionId: this.retentionId };
    this.retentionService.cancel(data, this.isRetentionsThirdParties).subscribe(response => {
      if (!response.erro) {
        this.notification.notify('success', 'Sucesso', response.message);
        this.updateListPrescriptionEvent.emit('');
        this.nzModalRef.destroy();
      }
    });
  }

  handleCancel(): void {
    this.nzModalRef.destroy();
  }
}
