import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { User } from '@app/shared/models/decodedLoginToken';
import { ThemeService } from '@app/theme';

@Component({
  selector: 'app-alternative-navbar-cip',
  templateUrl: './alternative-navbar-cip.component.html',
  styleUrls: ['./alternative-navbar-cip.component.scss']
})
export class AlternativeNavbarCipComponent {
  @Input() completeUser: any;

  constructor(
    private router: Router,
    private themeService: ThemeService,
    private authService: AuthService
  ) {}

  get user(): User {
    return this.authService.user();
  }

  redirect() {
    const { permission, userType } = this.user;
    const url = permission === 'basic' || userType !== 'admin' ? '/dashboard' : '/admin';
    this.router.navigate([url]);
  }

  isThemeActiveReceitaDigital() {
    const active = this.themeService.getActiveTheme();
    return active.name === 'theme-receita-digital';
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['entry/login']);
  }
}
