import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { ThemeService } from '@app/theme';
import { markFormGroup } from '@app/utils/markFormGroup';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss', '../reset-password/reset-password.component.scss']
})
export class ChangePasswordComponent {
  loading: boolean;
  form = new FormGroup(
    {
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', Validators.required)
    },
    { validators: [this.checkPasswords] }
  );

  constructor(
    private router: Router,
    private accountService: AccountService,
    private notification: AppToastService,
    private themeService: ThemeService
  ) {}

  checkPasswords(group: FormGroup) {
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    if (password !== confirmPassword) {
      group.get('confirmPassword').setErrors({
        mismatch: true,
        required: !confirmPassword
      });
    }
    return null;
  }

  async changePassword() {
    markFormGroup(this.form);
    if (this.form.valid) {
      const url = window.location.href.split('/');
      const token = url[url.length - 1];

      try {
        this.loading = true;
        await this.accountService.checkToken(token).toPromise();
        await this.accountService.changePassword(token, this.form.value).toPromise();
        this.loading = false;
        this.notification.notify('success', 'Senha atualizada com sucesso', null);
        this.router.navigate([`/entry`]);
      } catch (err) {
        console.log(err);
        this.loading = false;
        if (err.error.message) {
          this.notification.notify('error', 'Alerta', err.error.message);
        }
      }
    }
  }

  isThemeActiveReceitaDigital() {
    const active = this.themeService.getActiveTheme();
    return active.name === 'theme-receita-digital';
  }
}
