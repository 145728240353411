import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@app/modules/user/user.service';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { environment } from '@env/environment';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { DataBigboostConsult } from './../../../../shared/models/paciente';

@Component({
  selector: 'app-simplified-patient-form',
  templateUrl: './simplified-patient-form.component.html'
})
export class SimplifiedPatientFormComponent implements OnInit {
  @Input() patient: DataBigboostConsult;
  @Input() isRegisteredByPatient = true;

  @Output() submit = new EventEmitter<FormGroup>();
  @Output() formChange = new EventEmitter<FormGroup>();

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private notification: AppToastService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.buildForm();
    if (this.patient) {
      this.handleConsultCpf(this.patient);
    }

    this.formChange.emit(this.form);

    const emailForm = this.form.get('email');
    emailForm.valueChanges
      .pipe(
        map(email => {
          const value = email && email.toLowerCase();
          emailForm.setValue(value, { emitEvent: false });
          return value;
        }),
        filter(_ => emailForm.valid && emailForm.value),
        debounceTime(environment.debounceTime),
        switchMap(email => this.userService.checkEmailExists(email).pipe(catchError(err => of(err))))
      )
      .subscribe(res => {
        if (res.status === 200) {
          emailForm.setErrors({ exists: true });
        } else if (res.status === 404) {
          emailForm.setErrors(null);
        } else if (res.status === 400) {
          emailForm.setErrors({ invalid: true });
        }
      });

    this.form.get('emailContact').valueChanges.subscribe(email => {
      this.form.get('emailContact').setValue(email && email.toLowerCase(), { emitEvent: false });
    });
  }

  buildForm() {
    this.form = this.fb.group({
      fullname: ['', Validators.required],
      name: [''],
      cpf: ['', Validators.required],
      sex: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      cellphone: [''],
      telephone: [''],
      email: ['', Validators.email],
      emailContact: ['', Validators.email],
      membership: this.fb.group({
        mothersName: ['']
      }),
      address: this.fb.group({
        city: [''],
        complement: [''],
        number: [''],
        street: [''],
        neighborhood: [''],
        cep: [''],
        uf: ['']
      }),
      pendency: [null]
    });
  }

  private handleConsultCpf(consult: DataBigboostConsult) {
    this.form.get('name').reset();
    this.form.get('name').enable();
    this.form.get('cpf').setValue(consult.cpf);
    if (consult.status === 1) {
      this.setForm(consult);
    } else {
      this.form.get('fullname').enable();
      this.form.get('pendency').setValue(consult);
      this.resetForm();
      if (consult.erro) {
        this.notification.notify(
          'warning',
          'Aviso',
          'A importação de dados do CPF está com instabilidade, mas você poderá se cadastrar preenchendo os campos manualmente.'
        );
      }
    }
  }

  private setForm(consult: DataBigboostConsult) {
    const nascimento = moment(consult.nascimento, 'DD/MM/YYYY');
    this.form.get('cpf').setValue(consult.cpf);
    this.form.get('fullname').setValue(consult.nome);
    this.form.get('name').setValue(consult.nome);
    this.form.get('dateOfBirth').setValue(nascimento.format('YYYY-MM-DD'));

    this.form.get('cpf').disable();
    this.form.get('fullname').disable();
    this.form.get('dateOfBirth').disable();
  }

  private resetForm() {
    this.form.get('fullname').reset();
  }
}
