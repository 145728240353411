import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';
import { ValidationService } from '@app/core/services/validation.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { BrazilState, Patient } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { DataBigboostConsult } from '@app/shared/models/paciente';
import { BigboostService } from '@app/shared/services/bigboost.service';

@Component({
  selector: 'app-responsible-form',
  templateUrl: './responsible-form.component.html',
  styleUrls: ['./responsible-form.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        group([animate(300, style({ height: 0 })), animate('400ms ease-in-out', style({ opacity: 0 }))])
      ]),
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        group([
          animate(300, style({ height: '*' })),
          animate('400ms ease-in-out', style({ opacity: 1 }))
        ])
      ])
    ])
  ]
})
export class ResponsibleFormComponent implements OnInit {
  @Output() formChange = new EventEmitter<FormGroup>();

  cpfForm: FormGroup;
  responsibleForm: FormGroup;
  loading = false;
  consulted = false;
  states: BrazilState[] = BrazilianStates;

  @ViewChild('repeatName')
  private repeatNameCheckbox: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private healthProfessionalService: HealthProfessionalService,
    private patientService: PatientsService,
    private bigboostService: BigboostService,
    private cepService: CepService
  ) {}

  ngOnInit() {
    this.initForm();
    this.formChange.emit(this.responsibleForm);
  }

  get user(): User {
    return this.authService.user();
  }

  private initForm() {
    this.cpfForm = this.fb.group({
      cpf: ['', [Validators.required, ValidationService.cpfValidator]]
    });

    this.responsibleForm = this.fb.group({
      _id: [null],
      name: ['', [Validators.required, ValidationService.nomeValidator]],
      fullname: ['', [Validators.required, ValidationService.nomeValidator]],
      cpf: ['', [Validators.required, ValidationService.cpfValidator]],
      cellphone: ['', Validators.required],
      telephone: [''],
      email: ['', [Validators.required, Validators.email]],
      dateOfBirth: [null, Validators.required],
      sex: ['', Validators.required],
      membership: this.fb.group({
        mothersName: ['', Validators.required]
      }),
      address: this.fb.group({
        uf: ['', Validators.required],
        city: ['', Validators.required],
        complement: [''],
        number: ['', Validators.required],
        street: ['', Validators.required],
        neighborhood: ['', Validators.required],
        cep: ['', [Validators.required, ValidationService.cepValidator]]
      }),
      pendency: [null]
    });

    this.responsibleForm.get('fullname').valueChanges.subscribe(fullname => {
      if (this.repeatNameCheckbox && this.repeatNameCheckbox.nzChecked) {
        if (!fullname) {
          this.repeatNameCheckbox.nzChecked = false;
          this.responsibleForm.get('name').enable();
        }
        this.responsibleForm.get('name').setValue(fullname);
      }
    });

    this.responsibleForm.get('email').valueChanges.subscribe(email => {
      if (email) {
        this.responsibleForm.get('email').setValue(email.toLowerCase(), { emitEvent: false });
      }
    });
  }

  get cpf() {
    return this.cpfForm.get('cpf');
  }

  get currentDate() {
    const now = new Date();
    const dd = String(now.getDate()).padStart(2, '0');
    const mm = String(now.getMonth() + 1).padStart(2, '0');
    const yyyy = now.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  get hasAccount(): boolean {
    return !!this.responsibleForm.value._id;
  }

  checkEmail(value: boolean) {
    if (value) {
      this.responsibleForm.get('email').disable();
    } else {
      this.responsibleForm.get('email').enable();
    }
  }

  @nativeAsync
  async searchCpf() {
    try {
      const cpf = this.cpf.value;

      this.loading = true;
      let patient = await this.hasPatient(cpf);
      this.consulted = true;

      this.responsibleForm.disable();
      if (!patient) {
        patient = await this.getPatient(cpf);
      }

      if (patient) {
        this.patchForm(patient);
      } else {
        const consult = await this.consultCpf(cpf);
        if (consult.status === 1) {
          this.patchFormConsult(consult);
        } else {
          this.responsibleForm.enable();
          this.responsibleForm.get('pendency').setValue(consult);
        }
      }

      if (this.hasAccount) {
        this.responsibleForm.get('email').disable();
      } else if (this.responsibleForm.disabled) {
        this.responsibleForm.enable();
        this.disableForm();
      }
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  }

  checkRepeatName(value: boolean) {
    if (value) {
      this.responsibleForm.get('name').setValue(this.responsibleForm.get('fullname').value);
      this.responsibleForm.get('name').disable();
    } else {
      this.responsibleForm.get('name').enable();
    }
  }

  private patchForm(patient: Patient) {
    this.responsibleForm.patchValue(patient);
    const date = new Date(patient.dateOfBirth);
    this.responsibleForm.get('dateOfBirth').setValue(date.toISOString().substring(0, 10));
    if (patient.user) {
      this.responsibleForm.get('email').setValue(patient.user.email);
    }
    if (!patient.fullname) {
      this.responsibleForm.get('fullname').setValue(patient.name);
    }
  }

  private patchFormConsult(consult: DataBigboostConsult) {
    this.responsibleForm.get('_id').setValue(null);
    this.responsibleForm.get('name').setValue(consult.nome);
    this.responsibleForm.get('fullname').setValue(consult.nome);
    this.responsibleForm.get('cpf').setValue(consult.cpf);
    this.responsibleForm.get('sex').setValue(consult.genero);
    this.responsibleForm.get('membership.mothersName').setValue(consult.mae);
    this.responsibleForm.get('email').setValue('');
    this.responsibleForm.get('cellphone').setValue('');
    this.responsibleForm.get('telephone').setValue('');
    this.responsibleForm.get('pendency').setValue(null);
    const date = consult.nascimento.split('/');
    const dateOfBirth = new Date(`${date[1]}/${date[0]}/${date[2]}`);
    this.responsibleForm.get('dateOfBirth').setValue(dateOfBirth.toISOString().substring(0, 10));
  }

  private disableForm() {
    this.responsibleForm.get('fullname').disable();
    this.responsibleForm.get('cpf').disable();
    this.responsibleForm.get('sex').disable();
    this.responsibleForm.get('membership.mothersName').disable();
    this.responsibleForm.get('dateOfBirth').disable();
  }

  @nativeAsync
  private async hasPatient(cpf: string) {
    const result = await this.healthProfessionalService
      .getPatientsByUserId(cpf, this.user._id)
      .toPromise();
    return result.length ? result[0] : null;
  }

  @nativeAsync
  private async getPatient(cpf: string) {
    try {
      return await this.patientService.getPatientByCpf(cpf).toPromise();
    } catch (err) {
      return null;
    }
  }

  @nativeAsync
  private async consultCpf(cpf: string) {
    try {
      return await this.bigboostService.consultCpf(cpf).toPromise();
    } catch (err) {
      console.error(err);
      const consult = {
        status: -1,
        cpf,
        erroCodigo: err.status,
        erro: 'A importação de dados do cpf está com instabilidade'
      };
      return consult;
    }
  }

  @nativeAsync
  async onKey(event: any) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        this.responsibleForm.controls['address'].setValue({
          uf: data.uf,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          number: null,
          cep: data.cep
        });
      }
    }
  }
}
