import { Directive, ElementRef, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import * as CNPJ from '@fnando/cnpj';
import * as CPF from '@fnando/cpf';
import { OnlyNumbersPipe } from '@app/shared/pipes';

@Directive({
  selector: `[appCPF],[cnpj]`,
  providers: [
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: CpfCnpjDirective
    }
  ]
})
export class CpfCnpjDirective implements Validator, OnChanges {
  @Input()
  cpf: string;
  @Input()
  cnpj: string;
  private inputMaxLength: number;
  private onChange: () => void;
  private onlyNumbersPipe = new OnlyNumbersPipe();

  constructor(private el: ElementRef) {}

  @HostBinding('attr.maxlength')
  get maxlength() {
    return this.inputMaxLength;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.onChange) {
      this.onChange();
      const { cnpj, cpf } = changes;
      this._inputChange((cnpj || ({} as any)).currentValue || (cpf || ({} as any)).currentValue);
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    if (this.cpf) {
      const cpf = CPF.isValid(this.cpf);
      return cpf ? null : { cpf: true };
    } else if (this.cnpj) {
      const cnpj = CNPJ.isValid(this.cnpj);
      return cnpj ? null : { cnpj: true };
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onChange = fn;
  }

  private _inputChange(text: string) {
    const value = this.onlyNumbersPipe.transform(text);
    const { nativeElement } = this.el;
    if (value) {
      if (this.cnpj) {
        nativeElement.value = CNPJ.format(value);
        this.inputMaxLength = 14;
      } else {
        nativeElement.value = CPF.format(value);
        this.inputMaxLength = 11;
      }
    }
  }
}
