import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-iframe',
  templateUrl: './modal-iframe.component.html',
  styleUrls: ['./modal-iframe.component.scss']
})
export class ModalIframeComponent implements OnInit {
  public onLoad: Subject<string>;

  src: string;
  height: number;
  width: number;

  constructor(public modalRef: BsModalRef) {
    this.onLoad = new Subject();
  }

  ngOnInit() {}

  onload(iframe: any) {
    try {
      const url = iframe.contentWindow.location.href;
      this.onLoad.next(url);
    } catch (e) {
      this.onLoad.next();
    }
  }
}
