import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

const USER_TELEMEDICINE = 'user-telemedicine';

@Injectable({
  providedIn: 'root'
})
export class VideoConferenceService {
  constructor(private http: HttpClient) {}

  getById(id: string) {
    return this.http.get<any>(`${environment.apiRoot}${USER_TELEMEDICINE}/${id}`);
  }

  create(form: any) {
    return this.http.post<any>(`${environment.apiRoot}${USER_TELEMEDICINE}`, form);
  }

  uploadDocument(base64: string) {
    return this.http.post<any>(`${environment.apiRoot}${USER_TELEMEDICINE}/upload-document`, { base64 });
  }
}
