import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: 'textarea[appAutoSize]'
})
export class AutoSizeDirective implements AfterViewInit {
  @HostBinding('class.overflow-hidden') className = true;
  @HostBinding('attr.rows') rows = 1;

  constructor(private elem: ElementRef) {}

  public ngAfterViewInit() {
    this.resize();
  }

  @HostListener('input')
  private resize() {
    const textarea = this.elem.nativeElement as HTMLTextAreaElement;
    // Reset textarea height to auto that correctly calculate the new height
    textarea.style.height = 'auto';
    // Set new height
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
}
