import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ThemeModule, themeMrd, themeReceitaDigital } from '@app/theme';
import { environment } from '@env/environment';
import { NgxMaskModule } from 'ngx-mask';
import { AlternativeNavbarCipComponent } from './alternative-navbar-cip/alternative-navbar-cip.component';
import { AlternativeNavbarComponent } from './alternative-navbar/alternative-navbar.component';
import { FooterMobileComponent } from './footer/footer-mobile/footer-mobile.component';
import { FooterComponent } from './footer/footer.component';
import { IconMenuComponent } from './icon-menu/icon-menu.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ThemeModule.forRoot({
      themes: [themeReceitaDigital, themeMrd],
      active: environment.template
    }),
    NgxMaskModule.forRoot()
  ],
  declarations: [
    NavbarComponent,
    FooterComponent,
    FooterMobileComponent,
    AlternativeNavbarComponent,
    AlternativeNavbarCipComponent,
    IconMenuComponent,
    SidebarMenuComponent
  ],
  exports: [NavbarComponent, FooterComponent, AlternativeNavbarComponent, SidebarMenuComponent]
})
export class LayoutModule {}
