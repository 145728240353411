import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-activate-account',
  templateUrl: './modal-activate-account.component.html',
  styleUrls: ['./modal-activate-account.component.scss']
})
export class ModalActivateAccountComponent implements OnInit {
  tplModal: NzModalRef;

  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() content?: string;

  @ViewChild('tplContent', { static: true })
  tplContent: TemplateRef<{}>;

  @ViewChild('tplFooter', { static: true })
  tplFooter: TemplateRef<{}>;

  constructor(private modalService: NzModalService, private router: Router) {}

  ngOnInit() {}

  createTplModal(title, subtitle, content?): void {
    this.title = title;
    this.subtitle = subtitle;
    this.content = content;
    this.tplModal = this.modalService.create({
      nzContent: this.tplContent,
      nzFooter: this.tplFooter,
      nzClosable: true,
      nzWidth: 650,
      nzMaskClosable: true,
      nzOnCancel: () => {},
      nzOnOk: () => console.log('Click ok')
    });
  }

  destroyTplModal(): void {
    this.tplModal.destroy();
  }

  public redirect() {
    window.open('https://receitadigital.safeweb.com.br/#/');
    this.router.navigate([`/entry`]);
    this.modalService.closeAll();
  }
}
