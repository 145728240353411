import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { DateUtils } from '@app/shared/data/DateUtils';
import { DataBigboostConsult } from '@app/shared/models/paciente';
import { Patient } from '@app/shared/models/patient';
import { ProfessionalType } from '@app/shared/models/professional-type.enum';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { logInvalidForm } from '@app/utils/logInvalidForm';
import { markFormGroup } from '@app/utils/markFormGroup';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PatientsService } from '../../services/patients.service';
import { PetPatientService } from '../../services/pet-patient.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';

@Component({
  selector: 'app-modal-add-patient',
  templateUrl: './modal-add-patient.component.html',
  styleUrls: ['./modal-add-patient.component.scss']
})
export class ModalAddPatientComponent {
  @Input() prescriber: any;
  @Input() patient: DataBigboostConsult & Patient;
  @Input() isPet = false;
  @Input() isResponsible = false;
  @Input() existingPatient = false;
  @Input() registeredByResponsible = false;
  @Output() updateListPatientEvent = new EventEmitter<any>();

  loading = false;
  form: FormGroup;

  constructor(
    private router: Router,
    public modalRef: BsModalRef,
    private patientService: PatientsService,
    private petPatientService: PetPatientService,
    private healthProfessionalService: HealthProfessionalService,
    private notification: AppToastService
  ) {}

  get hasPendency() {
    return !this.patient || this.patient.status !== 1;
  }

  get isUnderAge() {
    // the pet's age doesn't matter
    if (this.isVeterinarian) {
      return true;
    }

    if (!this.hasPendency) {
      const nascimento = this.patient.nascimento;
      return DateUtils.isUnderAge(nascimento, 'DD/MM/YYYY');
    }
    return this.patient.menorIdade;
  }

  get isVeterinarian(): boolean {
    return (
      this.isPet ||
      (this.prescriber && this.prescriber.professionalType === ProfessionalType.VETERINARIAN)
    );
  }

  @nativeAsync
  async submit() {
    markFormGroup(this.form);

    const form = this.form.getRawValue();
    if (!form.pendency && this.patient.status === -1) {
      form.pendency = this.patient;
    }

    if (this.form.valid) {
      try {
        this.loading = true;
        markFormGroup(this.form);
        const { responsible } = form;
        if (responsible) {
          const responsibleSaved = responsible._id
            ? await this.updateResponsible(responsible._id, responsible)
            : await this.saveResponsible(responsible);
          form.responsible = responsibleSaved._id;
        }

        const msg =
          !form.email && this.isPet
            ? 'O Tutor/Responsável deverá ativar sua conta na plataforma para acessar seu perfil.'
            : 'Acesse seu e-mail e ative sua conta!';

        if (!this.registeredByResponsible) {
          const patientSaved = await this.saveAddPatient(form);
          this.notification.notify('success', 'Paciente cadastrado com sucesso!', msg);
          this.updateListPatientEvent.emit(patientSaved);
        } else {
          await this.savePatient(form);
          this.notification.notify('success', 'Parabéns! Cadastro realizado com sucesso.', msg);
          this.router.navigate(['entry/login']);
        }
        this.modalRef.hide();
      } catch (err) {
        console.error(err);
        const msg = err.error ? err.error.message : 'Erro ao salvar paciente';
        if (err.status === 400) {
          this.notification.notify('warning', 'Atenção', msg);
        } else {
          this.notification.notify('error', 'Erro', msg);
          if (msg === 'E-mail já cadastrado') {
            if (this.isVeterinarian) {
              this.form.get('responsible.email').setErrors({ alreadyExists: true });
            } else {
              this.form.get('email').setErrors({ alreadyExists: true });
            }
          } else {
            if (this.isVeterinarian) {
              this.form.get('responsible.email').setErrors(null);
            } else {
              this.form.get('email').setErrors(null);
            }
          }
        }
      } finally {
        this.loading = false;
      }
    } else {
      logInvalidForm(this.form);
    }
  }

  private savePatient(patient: any) {
    patient.originHost = window.location.origin;
    if (this.isVeterinarian && this.isPet) {
      return this.petPatientService.save(patient).toPromise();
    }
    return this.patientService.save(patient).toPromise();
  }

  private saveResponsible(patient: any) {
    patient.originHost = window.location.origin;
    return this.patientService.save(patient).toPromise();
  }

  private updateResponsible(responsibleId, patient: any) {
    patient.originHost = window.location.origin;
    return this.patientService.update(responsibleId, patient).toPromise();
  }

  private saveAddPatient(patient: any) {
    patient.originHost = window.location.origin;
    if (this.isVeterinarian) {
      return this.healthProfessionalService.savePetPatient(patient).toPromise();
    }

    return this.healthProfessionalService.savePatient(this.prescriber._id, patient).toPromise();
  }

  private addPatient(patientId: string) {
    return this.healthProfessionalService.addPatient(this.prescriber._id, patientId).toPromise();
  }

  close() {
    this.modalRef.hide();
  }
}
