import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ThemeModule, themeMrd, themeReceitaDigital } from '@app/theme';
import { environment } from '@env/environment';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ChartsModule } from 'ng2-charts';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { MyPieChartComponent } from '../charts/my-pie-chart/my-pie-chart.component';
import { PipesModule } from '../pipes';
import { AppVersionComponent } from './AppVersion/AppVersion.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { AddTypeOptionsComponent } from './add-type-options/add-type-options.component';
import { AutoWidthInputNumberComponent } from './auto-width-input-number/auto-width-input-number.component';
import { CidFormComponent } from './cid-form/cid-form.component';
import { DataExamFormComponent } from './data-exam-form/data-exam-form.component';
import { DialogModalComponent } from './dialog-modal/dialog-modal.component';
import { AddressDataFormComponent } from './form-data/address-data-form/address-data-form.component';
import { ContactsDataFormComponent } from './form-data/contacts-data-form/contacts-data-form.component';
import { PersonalDataFormComponent } from './form-data/personal-data-form/personal-data-form.component';
import { PetDataFormComponent } from './form-data/pet-data-form/pet-data-form.component';
import { UnderageDataFormComponent } from './form-data/underage-data-form/underage-data-form.component';
import { InputFileComponent } from './input-file/input-file.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { InputSearchMedicamentsComponent } from './input-search-medicaments/input-search-medicaments.component';
import { MedicamentFormComponent } from './medicament-form/medicament-form.component';
import { MenuComponent } from './menu/menu.component';
import { ModalActivateAccountComponent } from './modal-activate-account/modal-activate-account.component';
import { ModalResendDocumentoEmptyComponent } from './modal-resend-documento-empty/modal-resend-documento-empty.component';
import { ModalSendDocumentComponent } from './modal-send-document/modal-send-document.component';
import { ModalSucessSendDocumentComponent } from './modal-sucess-send-document/modal-sucess-send-document.component';
import { ModelFormPrePreenchidoComponent } from './model-form-pre-preenchido/model-form-pre-preenchido.component';
import { PopoverButtonComponent } from './popover-button/popover-button.component';
import { RdButtomGroupIconComponent } from './rd-buttom-group-icon/rd-buttom-group-icon.component';
import { RdTabsComponent } from './rd-tabs/rd-tabs.component';
import { TextBoxFormComponent } from './text-box-form/text-box-form.component';

import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzInputNumberModule } from 'ng-zorro-antd';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    RouterModule,
    NzInputModule,
    NgxLoadingModule,
    NgxMaskModule.forRoot(),
    ThemeModule.forRoot({
      themes: [themeReceitaDigital, themeMrd],
      active: environment.template
    }),

    NzCollapseModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    NzAutocompleteModule,
    NzFormModule,
    NzNotificationModule,
    NzDropDownModule,
    NzStepsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzCardModule,
    NzCarouselModule,
    NzListModule,
    NzPopoverModule,
    NzTableModule,
    NzTabsModule,
    NzToolTipModule,
    NzAlertModule,
    NzDrawerModule,
    NzMessageModule,
    NzModalModule,
    NzProgressModule,
    NzDividerModule,
    NzIconModule,
    NzSwitchModule,
    NzInputNumberModule,
    NzPaginationModule,
    NzSpinModule
  ],
  declarations: [
    AutoWidthInputNumberComponent,
    MenuComponent,
    MyPieChartComponent,
    DialogModalComponent,
    InputFileComponent,
    ModalActivateAccountComponent,
    ModalSendDocumentComponent,
    ModalSucessSendDocumentComponent,
    InputPasswordComponent,
    AddButtonComponent,
    PopoverButtonComponent,
    AddressDataFormComponent,
    ContactsDataFormComponent,
    PersonalDataFormComponent,
    UnderageDataFormComponent,
    RdButtomGroupIconComponent,
    RdTabsComponent,
    PetDataFormComponent,
    AddTypeOptionsComponent,
    InputSearchMedicamentsComponent,
    CidFormComponent,
    DataExamFormComponent,
    TextBoxFormComponent,
    ModelFormPrePreenchidoComponent,
    MedicamentFormComponent,
    ModalResendDocumentoEmptyComponent,
    AppVersionComponent
  ],
  exports: [
    AutoWidthInputNumberComponent,
    MenuComponent,
    MyPieChartComponent,
    DialogModalComponent,
    InputFileComponent,
    ModalActivateAccountComponent,
    ModalSendDocumentComponent,
    ModalSucessSendDocumentComponent,
    InputPasswordComponent,
    AddButtonComponent,
    PopoverButtonComponent,
    AddressDataFormComponent,
    ContactsDataFormComponent,
    PersonalDataFormComponent,
    UnderageDataFormComponent,
    RdButtomGroupIconComponent,
    RdTabsComponent,
    PetDataFormComponent,
    AddTypeOptionsComponent,
    InputSearchMedicamentsComponent,
    CidFormComponent,
    DataExamFormComponent,
    TextBoxFormComponent,
    ModelFormPrePreenchidoComponent,
    MedicamentFormComponent,
    AppVersionComponent,

    NzCollapseModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    NzAutocompleteModule,
    NzFormModule,
    NzNotificationModule,
    NzDropDownModule,
    NzStepsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzCardModule,
    NzCarouselModule,
    NzListModule,
    NzPopoverModule,
    NzTableModule,
    NzTabsModule,
    NzToolTipModule,
    NzAlertModule,
    NzDrawerModule,
    NzMessageModule,
    NzModalModule,
    NzProgressModule,
    NzDividerModule,
    NzIconModule,
    NzSwitchModule,
    NzInputNumberModule,
    NzPaginationModule,
    NzSpinModule
  ]
})
export class ComponentsModule {}
