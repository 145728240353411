import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Patient } from '@app/shared/models';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PatientsService } from '../../services/patients.service';
import { PetPatientService } from '../../services/pet-patient.service';
import { PetPatient } from '@app/shared/models/pet-patient';
import { BigboostService } from '@app/shared/services/bigboost.service';
import { DataBigboostConsult } from '@app/shared/models/paciente';

@Component({
  selector: 'app-modal-switch-responsible',
  templateUrl: './modal-switch-responsible.component.html',
  styleUrls: ['./modal-switch-responsible.component.scss']
})
export class ModalSwitchResponsibleComponent implements OnInit {
  @Input() patient: Patient | PetPatient;
  @Input() isVeterinarian = false;

  @Output() sucess = new EventEmitter<Patient>();
  @Output() newPatientRegister = new EventEmitter<DataBigboostConsult>();

  cpf = new FormControl('');
  loadindSearch = false;
  loadindConfirm = false;
  loadindConfirmRegister = false;

  newResponsible: Patient;
  newResponsibleNotFound = false;

  constructor(
    public modalRef: BsModalRef,
    private notification: AppToastService,
    private patientService: PatientsService,
    private petPatientService: PetPatientService,
    private bigboostService: BigboostService
  ) {}

  ngOnInit(): void {}

  searchResponsiblePatient() {
    this.resetLoadings();
    this.loadindSearch = true;
    this.newResponsible = null;
    this.newResponsibleNotFound = null;

    const cpf = this.cpf.value;

    this.patientService
      .getPatientByCpf(cpf)
      .subscribe(
        newReponsible => {
          if (newReponsible.responsible) {
            this.notification.notify('warning', 'Outro menor não pode ser resposável por outro.');
            return;
          }
          this.newResponsible = newReponsible;
          this.cpf.reset();
        },
        (httpError: HttpErrorResponse) => {
          if (httpError.status === 404) {
            this.newResponsibleNotFound = true;
          } else {
            this.notification.notify(
              'error',
              'Ocorreu um erro ao buscar os dados do responsável. Favor entrar em contato com administradores!'
            );
          }
        }
      )
      .add(() => {
        this.loadindSearch = false;
      });
  }

  confirmSwitchReponsible() {
    this.resetLoadings();
    this.loadindConfirm = true;
    if (this.isVeterinarian) {
      this.switchReponsiblePet();
    } else {
      this.switchReponsiblePatient();
    }
  }

  confirmRegisterNewPatient() {
    this.resetLoadings();
    const cpf = this.cpf.value;
    this.loadindConfirmRegister = true;
    this.bigboostService
      .consultCpf(cpf, '')
      .subscribe(
        (response: DataBigboostConsult) => {
          this.newPatientRegister.emit(response);
          this.modalRef.hide();
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.notification.notify(
              'warning',
              'Aviso',
              'A data de nascimento não pertence a este CPF. Favor digitar novamente.'
            );
            return;
          }
          if (error.status === 404) {
            this.notification.notify(
              'error',
              'Error ao consultar CPF',
              'CPF não localizado na Receita Federal. Verifique as informações digitadas e tente novamente. Em caso de dúvidas, entre em contato com nosso atendimento',
              8000
            );
            return;
          }
        }
      )
      .add(this.resetLoadings);
  }

  cancel() {
    this.newResponsible = null;
    this.modalRef.hide();
  }

  private switchReponsiblePatient() {
    this.patientService
      .switchReponsible(this.patient._id, this.newResponsible.cpf)
      .subscribe(
        () => {
          this.sucess.emit(this.newResponsible);
          this.modalRef.hide();
        },
        () => {
          this.notification.notify(
            'error',
            'Ocorreu um erro ao alterar o responsável do paciente. Favor entrar em contato com administradores!'
          );
        }
      )
      .add(this.resetLoadings);
  }

  private switchReponsiblePet() {
    this.petPatientService
      .switchReponsible(this.patient._id, this.newResponsible.cpf)
      .subscribe(
        () => {
          this.sucess.emit(this.newResponsible);
          this.modalRef.hide();
        },
        () => {
          this.notification.notify(
            'error',
            'Ocorreu um erro ao alterar o responsável do PET. Favor entrar em contato com administradores!'
          );
        }
      )
      .add(this.resetLoadings);
  }

  private resetLoadings() {
    this.loadindSearch = false;
    this.loadindConfirm = false;
    this.loadindConfirmRegister = false;
  }
}
