import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-system-update',
  templateUrl: './system-update.component.html',
  styleUrls: ['./system-update.component.scss']
})
export class SystemUpdateComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    if (!environment.maintenance) {
      this.router.navigate(['/']);
    }
  }
}
