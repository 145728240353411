import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { PrescriptionService } from '@app/modules/document/services/prescription.service';
import { RetentionService } from '@app/modules/document/services/retention.service';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { ModalBuyerDataComponent } from '@app/shared/modals/modal-buyer-data/modal-buyer-data.component';
import { Pharmacy } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';
import { ModalDispentionComponent } from '../modal-dispention/modal-dispention.component';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  loading = false;
  prescription: any;
  private pharmacy: Pharmacy;
  thirdParties: any;
  prescriptionData: any;
  hash: any;

  constructor(
    private router: Router,
    private prescriptionService: PrescriptionService,
    private retentionService: RetentionService,
    private authService: AuthService,
    private pharmacyService: PharmacyService,
    private modalService: NzModalService,
    private notification: AppToastService
  ) {
    this.prescription = this.prescriptionService.prescription;
  }

  async ngOnInit() {
    const extraData = JSON.parse(localStorage.getItem('extra_data'));
    if (extraData) {
      this.pharmacy = await this.pharmacyService.getPharmacy(extraData.pharmacyId).toPromise();
    } else {
      this.pharmacy = await this.pharmacyService.getPharmacyByUserId(this.user._id).toPromise();
    }
  }

  get user(): User {
    return this.authService.user();
  }

  get patient() {
    return this.prescription.patient;
  }

  get healthProfessional() {
    return this.prescription.healthProfessional;
  }

  get medicaments() {
    return this.prescription.medicaments;
  }

  get notSelectedMedicaments() {
    return this.medicaments.filter(m => !m.checked && !m.hidden);
  }

  get selectedMedicaments() {
    return this.medicaments.filter(m => m.checked);
  }

  selectedCount(medicaments: any) {
    return medicaments.filter(m => m.include).length;
  }

  remove(medicament: any) {
    medicament.checked = false;
  }

  @nativeAsync
  async includeMedicaments() {
    this.notSelectedMedicaments.forEach(m => {
      if (m.include) {
        m.checked = true;
        m.include = false;
      }
    });
  }

  showModal(medicament: any) {
    this.modalService.create({
      nzContent: ModalDispentionComponent,
      nzClassName: 'modal-dispensation',
      nzComponentParams: {
        medicament: medicament
      },
      nzClosable: false,
      nzFooter: null,
      nzWidth: 600
    });
  }

  showModalAlert(medicament: any) {
    this.modalService.create({
      nzContent: ModalAlertComponent,
      nzClassName: 'modal-alert',
      nzComponentParams: {
        medicament: medicament
      },
      nzClosable: false,
      nzFooter: null
    });
  }

  get dispensedMedicaments() {
    return this.selectedMedicaments.filter(m => m.items).length;
  }
  @nativeAsync
  async finish() {
    const modal = this.modalService.create({
      nzContent: ModalBuyerDataComponent,
      nzComponentParams: {
        patient: this.prescription.patient,
        okText: 'Finalizar dispensação'
      },
      nzFooter: null,
      nzWidth: 900
    });

    modal.afterOpen.subscribe(_ => {
      modal.getContentComponent().saveRetention.subscribe(cpf => this.saveRentention(modal, cpf));
    });
  }

  @nativeAsync
  private async saveRentention(modal: any, cpf: string) {
    try {
      let dispensation: {};
      if (this.prescription.thirdParties) {
        const base64 = this.prescriptionService.pdfBase64.substring(
          this.prescriptionService.pdfBase64.indexOf(',') + 1
        );
        dispensation = {
          identifier: '',
          thirdParties: true,
          medicaments: this.selectedMedicaments.flatMap(medicament => {
            return medicament.items.flatMap(item => {
              return item.caixas.map(caixa => ({
                medicationName: 'unknown',
                medicationDispense: `${item.nome} - ${item.apresentacaoCustomizada}`,
                quantity: 1,
                lotNumber: caixa.nome,
                laboratory: {
                  name: item.laboratorio,
                  cnpj: item.cnpj
                }
              }));
            });
          }),
          pharmacy: {
            name: this.pharmacy.businessName || this.pharmacy.business_name_entity,
            cnpj: this.pharmacy.cnpj || this.pharmacy.cnpj_entity,
            phone: this.pharmacy.telephone,
            address: this.pharmacy.address
          },
          prescriptionData: base64
        };
      } else {
        dispensation = {
          identifier: this.prescription.identifier,
          thirdParties: false,
          medicaments: this.selectedMedicaments.flatMap(medicament => {
            return medicament.items.flatMap(item => {
              return item.caixas.map(caixa => ({
                uuid: medicament.uuid,
                medicationName: medicament.name,
                medicationDispense: `${item.nome} - ${item.apresentacaoCustomizada}`,
                quantity: 1,
                lotNumber: caixa.nome,
                laboratory: {
                  name: item.laboratorio,
                  cnpj: item.cnpj
                }
              }));
            });
          }),
          pharmacy: {
            name: this.pharmacy.businessName || this.pharmacy.business_name_entity,
            cnpj: this.pharmacy.cnpj || this.pharmacy.cnpj_entity,
            phone: this.pharmacy.telephone,
            address: this.pharmacy.address
          }
        };
      }

      this.loading = true;
      await this.prescriptionService.postDispensation(dispensation);
      await this.saveRetentionThirdParty(cpf);
      this.prescriptionService.pdfBase64 = null;
      this.notification.notify('success', 'Sucesso', 'Dispensação realizada com sucesso');
      window.location.href = '/dashboard';
      modal.close();
    } catch (err) {
      console.error(err);
      this.notification.notify('error', 'Erro', 'Ocorreu um erro ao realizar a dispensação');
    }
    this.loading = false;
  }

  private saveRetentionThirdParty(cpf: string) {
    const medications = this.selectedMedicaments.flatMap(medicament => {
      return medicament.items.map(
        (item: {
          ean: string;
          nome: string;
          apresentacaoCustomizada: any;
          caixas: any[];
          laboratorio: any;
          cnpj: string;
        }) => ({
          ean: item.ean,
          name: `${item.nome} - ${item.apresentacaoCustomizada}`,
          batches: item.caixas.map(caixa => caixa.nome),
          laboratory: {
            name: item.laboratorio,
            cnpj: item.cnpj
          }
        })
      );
    });

    const retention = {
      pdf: this.prescriptionService.pdfBase64,
      cpf,
      pharmacy: this.pharmacy.id,
      prescriber: this.healthProfessional,
      medications
    };
    return this.retentionService.saveRetentionOfThirdParties(retention);
  }
}
