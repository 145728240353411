import { UserService } from '@app/modules/user/user.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-womens-month',
  templateUrl: './modal-womens-month.component.html',
  styleUrls: ['./modal-womens-month.component.scss']
})
export class ModalWomensMonthComponent {
  @Input() isVisible = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();

  constructor(private userService: UserService) {}

  later() {
    localStorage.setItem('hideWomensModal', 'true');
    this.close();
  }

  open() {
    window.open('https://consultaremedios.com.br', '_blank');
    this.close();
    this.userService.checkWomensMonth().subscribe(_ => localStorage.setItem('hideWomensModal', 'true'));
  }

  private close() {
    this.isVisibleChange.emit(false);
  }
}
