import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlyNumbers'
})
@Injectable()
export class OnlyNumbersPipe implements PipeTransform {
  transform(value = '') {
    return value ? value.toString().replace(/[^\d]+/g, '') : '';
  }
}
