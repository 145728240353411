import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { User } from '@app/shared/models/decodedLoginToken';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-edit-pharmacy-covid',
  templateUrl: './modal-edit-pharmacy-covid.component.html',
  styleUrls: ['./modal-edit-pharmacy-covid.component.scss']
})
export class ModalEditPharmacyCovidComponent implements OnInit {
  @Input() completeUser: any;
  @Output() updateUserEvent = new EventEmitter<User>();

  covidForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private pharmacyService: PharmacyService,
    private nzModalRef: NzModalRef,
    private notification: AppToastService
  ) {}

  ngOnInit() {
    this.covidForm = this.fb.group({
      covid: [`${this.completeUser.covid}`, Validators.required]
    });
  }

  @nativeAsync
  async save(param) {
    if (param === '') {
      this.notification.notify('warning', 'Atenção', 'Valor do teste Covid não informado');
    } else {
      try {
        const pharmacyId = this.completeUser._id;
        this.completeUser.covid = this.covidForm.get('covid').value;
        await this.pharmacyService.update(pharmacyId, this.completeUser).toPromise();
        this.notification.notify('success', 'Sucesso', 'Informação atualizada com sucesso.');
        this.updateUserEvent.emit(this.completeUser);
        this.close();
      } catch (err) {
        console.error(err);
        this.notification.notify('error', 'Erro', 'Erro ao atualizar informação');
      }
    }
  }

  close() {
    this.nzModalRef.close();
  }
}
