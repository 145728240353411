import { Orientation } from './../../../../shared/models/orientation';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

const ORIENTATION_URL = 'orientation';
export const EXAM_PRESCRIBER = `orientation/prescriber`;
const EXAM_PATIENT = `orientation/patient`;
@Injectable({
  providedIn: 'root'
})
export class OrientationService {
  constructor(private http: HttpClient) {}

  public save(orientation: Orientation) {
    if (orientation.code) {
      return this.http.put(`${environment.apiRoot}${ORIENTATION_URL}/${orientation.code}`, orientation);
    }
    return this.http.post(`${environment.apiRoot}${ORIENTATION_URL}`, orientation);
  }

  public getOrientationsByPrescriberUserId(prescriberId: string, filter: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${EXAM_PRESCRIBER}/${prescriberId}`, {
      params: filter
    });
  }

  public getAllPatientExams(patientId: string) {
    return this.http.get<Orientation[]>(`${environment.apiRoot}${ORIENTATION_URL}`, {
      params: { patientId }
    });
  }

  getOrientationsByPatientId(patientId: string, filter: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${EXAM_PATIENT}/${patientId}`, { params: filter });
  }

  getOrientation(code: string) {
    return this.http.get(`${environment.apiRoot}${ORIENTATION_URL}/${code}`);
  }

  getByFilter(filter: any): Promise<Orientation[]> {
    return this.http
      .get<Orientation[]>(`${environment.apiRoot}${ORIENTATION_URL}/filter`, {
        params: filter
      })
      .toPromise();
  }

  delete(orientationId: number): Observable<any> {
    return this.http.delete(`${environment.apiRoot}${ORIENTATION_URL}/${orientationId}`);
  }
}
