import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss', '../modal-telemedicine-contract.component.scss']
})
export class IntroComponent implements OnInit {
  @Input() userType: string;

  constructor() {}

  ngOnInit() {}
}
