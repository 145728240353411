import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Orientation, Patient } from '@app/shared/models';
import { ColorSetting } from '@app/shared/models/color-setting';

@Component({
  selector: 'app-orientation',
  templateUrl: './orientation.component.html',
  styleUrls: ['./orientation.component.scss']
})
export class OrientationComponent implements OnInit {
  @Input() patient: Patient;
  @Input() orientation: Orientation;
  @Input() editMode = false;
  @Input() colorSetting: ColorSetting;
  @Input() isVeterinarian: boolean;
  @Output() formEmitter = new EventEmitter<FormGroup>();

  orientations: string;
  orientationForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.orientationForm = this.fb.group({
      title: ['', Validators.required],
      text: ['', Validators.required],
      orientations: [''],
      showPatientCpf: [false],
      showPrescriberCpf: [false]
    });
  }

  ngOnInit() {
    if (this.orientation) {
      this.orientationForm.get('title').setValue(this.orientation.title);
      const value = this.orientation.orientations.reduce(
        (accumulator, currentValue) => accumulator + '\n' + currentValue
      );
      this.orientationForm.get('text').setValue(value);
      this.orientationForm.get('orientations').setValue(this.orientation.orientations);
    }

    this.orientationForm.get('text').valueChanges.subscribe(val => {
      if (val) {
        const value = val.split('\n');
        this.orientationForm.get('orientations').setValue(value);
      }
    });
    this.formEmitter.emit(this.orientationForm);
  }

  @Input()
  set orientationModel(orientationModel: any) {
    if (orientationModel) {
      const value = orientationModel.orientations.reduce((acc, val) => acc + '\n' + val);
      this.orientationForm.get('text').setValue(value);
      this.orientationForm.get('title').setValue(orientationModel.title);
      this.orientationForm.get('orientations').setValue(orientationModel.orientations);
    }
  }

  get showCpfText() {
    return this.patient && !this.patient.cpf ? 'Mostrar CPF do Responsável' : 'Mostrar CPF do Paciente';
  }
}
