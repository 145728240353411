import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { User } from '@app/shared/models/decodedLoginToken';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VideoConferenceService } from './user-telemedicine.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';

@Component({
  selector: 'app-modal-telemedicine-contract',
  templateUrl: './modal-telemedicine-contract.component.html',
  styleUrls: ['./modal-telemedicine-contract.component.scss']
})
export class ModalTelemedicineContractComponent implements OnInit {
  title: string;
  hire: boolean;

  constructor(
    protected modalRef: BsModalRef,
    private authService: AuthService,
    private nzModalService: NzModalService,
    private notification: AppToastService,
    private videoConferenceService: VideoConferenceService
  ) {}

  ngOnInit() {}

  get user(): User {
    return this.authService.user();
  }

  @nativeAsync
  submitForm(data: any) {
    this.nzModalService.confirm({
      nzTitle: 'Atenção',
      nzContent: 'Tem certeza que deseja submeter seus dados?',
      nzOnOk: async () => {
        try {
          await this.videoConferenceService.create(data).toPromise();
          this.notification.notify(
            'success',
            'Cadastro realizado com sucesso.',
            'Aguarde 24 horas que retornaremos a análise dos seus dados'
          );
          this.closeModal();
        } catch (err) {
          console.error(err);
          if (err.error.message) {
            this.notification.notify('error', 'Alerta', err.error.message);
          } else {
            this.notification.notify(
              'error',
              'Erro',
              'Ocorreu um erro na solicitação. Entre em contato conosco'
            );
          }
        }
      }
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.modalRef = null;
  }
}
