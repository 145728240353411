import { Component, OnInit } from '@angular/core';
import { CoreService } from '@app/core/services/core.service';

import { version } from '../../../../../package.json';

@Component({
  selector: 'app-AppVersion',
  template: `
    <div style="padding: 10px;">
      <p>
        Varsão do Backend: <strong>{{ versionBack }}</strong>
      </p>

      <p>
        Varsão do Frontend: <strong>{{ versionFront }}</strong>
      </p>
    </div>
  `
})
export class AppVersionComponent implements OnInit {
  versionFront: string = version;
  versionBack: string;

  constructor(private coreService: CoreService) {}

  ngOnInit() {
    this.coreService.getBackendVersion().subscribe(version => {
      this.versionBack = version;
    });
  }
}
