import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './../../../core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.authService.user();
    const isPrescriber = user.userType === 'healthProfessional';
    if (!isPrescriber) {
      this.router.navigate(['']);
    }
    return isPrescriber;
  }
}
