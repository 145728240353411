import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-mobile',
  templateUrl: './footer-mobile.component.html',
  styleUrls: ['./footer-mobile.component.scss']
})
export class FooterMobileComponent implements OnInit {
  @Input() frontendVersion: string;
  @Input() backendVersion: string;

  constructor() {}

  ngOnInit() {}
}
