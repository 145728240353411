import { HttpClient } from '@angular/common/http';
import { HostListener, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrientationService } from '@app/modules/document/pages/orientation/orientation.service';
import { HealthProfessional, Orientation } from '@app/shared/models';
import { md5 } from '@app/utils/md5';
import { environment } from '@env/environment';
import { AttestationService } from './../pages/attestation/attestation.service';
import { ExamService } from './exam.service';
import { InfusionService } from './infusion.service';
import { PdfService } from './pdf.service';
import { PrescriptionService } from './prescription.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { Prescription } from '@app/shared/models/prescription';
import { Exam } from '@app/shared/models/exam';
import { Attestation } from '@app/shared/models/attestation';

interface PdfSignParams {
  code: string;
  version?: string;
  prescriber: HealthProfessional;
  patientId: string;
  token?: string;
  layout?: any;
  payload?: string;
  callbackUrl?: string;
  showSendDocumentOnCallback?: boolean;
  victaFeature?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private _model: any;
  private _patientForm: FormGroup;
  private _documentTypeSelected: string;
  private _plusGApp: string;
  private _sharedKey: string;

  constructor(
    private prescriptionService: PrescriptionService,
    private attestationService: AttestationService,
    private examService: ExamService,
    private orientationService: OrientationService,
    private infusionService: InfusionService,
    private pdfService: PdfService,
    private http: HttpClient
  ) {}

  get model() {
    return this._model;
  }

  set model(model: any) {
    this._model = model;
  }

  get patientForm() {
    return this._patientForm;
  }

  set patientForm(patientForm: FormGroup) {
    this._patientForm = patientForm;
  }

  get documentTypeSelected() {
    return this._documentTypeSelected;
  }

  set documentTypeSelected(documentType: string) {
    this._documentTypeSelected = documentType;
  }

  usePdfV1Endpoint() {
    this.pdfService.useV1Endpoint();
  }

  @nativeAsync
  async getDocument(code: string, type?: string): Promise<any> {
    let document = null;
    if (code[0] === 'A' || code.substring(0, 4) === 'VETA' || type === 'attestation') {
      document = await this.attestationService.getAttestation(code).toPromise();
      document.type = 'Atestado';
    } else if (code[0] === 'E' || code.substring(0, 4) === 'VETE' || type === 'exam') {
      document = await this.examService.getExam(code).toPromise();
      document.type = 'Exame';
    } else if (code[0] === 'O' || code.substring(0, 4) === 'VETO' || type === 'orientation') {
      document = await this.orientationService.getOrientation(code).toPromise();
      document.type = 'Outros';
    } else if (code[0] === 'I' || type === 'infusion') {
      document = await this.infusionService.getInfusion(code).toPromise();
      document.type = 'Infusão';
    } else if (code[0] === 'P' || code[0] === 'V' || type === 'prescription' || !type) {
      document = await this.prescriptionService.getPrescriptionbyCode(code).toPromise();
      document.type = 'Prescrição';
    } else if (code.substring(0, 3) === 'REN' || type === 'prescription-renew') {
      document = await this.prescriptionService.getPrescriptionbyCode(code).toPromise();
      document.type = 'Prescrição Rennova';
    }

    return document;
  }

  archive(code: string) {
    return this.http.put(`${environment.apiRoot}documents/${code}/archive`, {});
  }

  unarchive(code: string) {
    return this.http.delete(`${environment.apiRoot}documents/${code}/archive`);
  }

  getTotals(tenantId: string) {
    return this.http.get<any>(`${environment.apiRoot}documents/totals`, { params: { tenantId } });
  }

  @nativeAsync
  async generateAndOpenPdf(code: string, layout = {}) {
    const pdf = await this.pdfService.generateAndSavePdf({ code, draft: true, layout });
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = pdf.url;
    link.download = pdf.filename;
    link.click();
    link.remove();
  }

  @nativeAsync
  async getUrlToSignDocument({
    code,
    version,
    prescriber,
    patientId,
    token,
    layout,
    payload,
    callbackUrl,
    showSendDocumentOnCallback = true,
    victaFeature
  }: PdfSignParams): Promise<string> {
    if (victaFeature) {
      this._plusGApp = `${environment.plusGApp}/institutodosoro`;
      this._sharedKey = environment.envPlusSecretKeyIS;
    } else {
      this._plusGApp = `${environment.plusGApp}/receitadigital`;
      this._sharedKey = environment.envPlusSecretKey;
    }

    try {
      const { filename, url: pdfUrl } = await this.pdfService.generateAndSavePdf({ code, layout });
      if (!pdfUrl) {
        throw Error('Erro ao gerar PDF');
      }

      return this.buildSignPdfPayload({
        filename,
        pdfUrl,
        prescriber,
        patientId,
        code,
        version,
        token,
        payload,
        callbackUrl,
        showSendDocumentOnCallback
      });
    } catch (err) {
      throw err;
    }
  }

  private buildSignPdfPayload({
    filename,
    pdfUrl,
    prescriber,
    patientId,
    code,
    version,
    token,
    payload: payloadB64,
    callbackUrl,
    showSendDocumentOnCallback
  }): string {
    const isBeta = version === 'BETA';
    const path = isBeta ? 'document/new' : 'prescription/new';
    let route = path;
    if (token) {
      if (isBeta) {
        route = `recipe-v2/new/${token}`;
      } else {
        route = `recipe/new/${token}`;
      }
    }

    let url = `${window.location.origin}/${route}`;

    const params: any = { code };
    if (payloadB64) {
      params.payload = payloadB64;
    }

    if (prescriber.professionalType === 'veterinarian') {
      params.petId = patientId;
    } else {
      params.patientId = patientId;
    }

    if (callbackUrl) {
      if (showSendDocumentOnCallback) {
        params.callbackUrl = callbackUrl;
      } else {
        url = callbackUrl;
      }
    }

    const urlParams = new URLSearchParams(params).toString();
    const host = `${window.location.protocol}//${window.location.hostname}`;

    const payload: any = {
      callbackUrl: `${url}?${urlParams}&response=`,
      webhookUrl: `${environment.apiRoot}pdf/upload-signed?code=${code}`,
      ui: {
        username: prescriber.cpf,
        preferPreview: 'description'
      },
      security: {
        allowAddNewDocument: false,
        allowDocumentType: false,
        payloadCallbackUrl: true,
        allowChangeUsername: !environment.production
      },
      files: [
        {
          name: filename,
          description: this.getContent(host),
          src: pdfUrl,
          signatureSetting: {
            id: code,
            type: 'DOC-pdf',
            visibleSignatureField: 'Assinatura',
            visibleSignatureDateFormat: 'd/m/Y\nH:i:s|TZ|America/Sao_Paulo',
            visibleSignatureCustomTemplateSrc: `${host}/assets/templates/signature.html`
          }
        }
      ]
    };

    if (environment.production) {
      payload.certificates = {
        filters: [
          {
            validity: 'now',
            'issuer=>organizationName': 'ICP-Brasil',
            'subjectAltName=>otherName=>2.16.76.1.3.1': `********${prescriber.cpf}**************************`
          }
        ]
      };
    }

    return this.computeHmacUrl(JSON.stringify(payload).replace(/\//g, '\\/'));
  }

  private computeHmacUrl(payloadJson: string): string {
    // Payload
    const payloadEncoded = btoa(payloadJson);
    console.log('payloadEncoded: ', payloadEncoded);

    // Compute HMAC
    const time = Math.round(new Date().getTime() / 1000);
    const rand = Math.floor(Math.random() * 9999);
    const nonce = `${time}${rand}`;
    const token = `${nonce}-${md5(`${nonce}${this._sharedKey}${md5(payloadEncoded)}`)}`;

    // URL
    return `${this._plusGApp}/${token}/embed?payload=${encodeURIComponent(payloadEncoded)}`;
  }

  private getContent(host: string) {
    return `
    <div class="d-flex justify-content-center">
    <img
        class="border"
        src="${host}/assets/public/RECEITA_BORRADA_RD_480w.png"
        alt="Receita Borrada Sem efeitos Legais"
      />
    </div>
    `;
  }
}
