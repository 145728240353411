import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Patient } from '@app/shared/models/patient';
import { environment } from '@env/environment';
import { HealthProfessional } from '@app/shared/models/health-professional';
import { Infusion, InfusionRequest } from '@app/shared/models/infusion';

const INFUSIONS_URL = 'infusions';

export class Orientation {
  _id: string;
  code: string;
  createdAt: Date;
  status: string;
  type: string;
  patient: Patient;
  healthProfessional: HealthProfessional;
  title: string;
  orientations: string[];
  local: any;
}

@Injectable({
  providedIn: 'root'
})
export class InfusionService {
  constructor(private http: HttpClient) {}

  save(infusion: InfusionRequest) {
    if (infusion.code) {
      return this.http.put<Infusion>(
        `${environment.apiRoot}${INFUSIONS_URL}/${infusion.code}`,
        infusion
      );
    }
    return this.http.post(`${environment.apiRoot}${INFUSIONS_URL}`, infusion);
  }

  getInfusion(code: string): Observable<Infusion> {
    return this.http.get<Infusion>(`${environment.apiRoot}${INFUSIONS_URL}/${code}`);
  }

  getByFilter(filter: any) {
    return this.http
      .get<Orientation[]>(`${environment.apiRoot}${INFUSIONS_URL}/filter`, {
        params: filter
      })
      .toPromise();
  }
}
