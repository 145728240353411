import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

export const EXAM_MODEL_URL = 'exam-model';

@Injectable({
  providedIn: 'root'
})
export class ExamModelService {
  constructor(private http: HttpClient) {}

  getListByPrescriberId(prescriberId: any): any {
    return this.http
      .get(`${environment.apiRoot}${EXAM_MODEL_URL}?prescriberId=${prescriberId}`)
      .toPromise();
  }

  getById(modelId: string) {
    return this.http.get(`${environment.apiRoot}${EXAM_MODEL_URL}/${modelId}`).toPromise();
  }

  create(orientationModel: any) {
    return this.http.post(`${environment.apiRoot}${EXAM_MODEL_URL}`, orientationModel).toPromise();
  }

  update(orientationModel: any, modelId: string) {
    return this.http
      .put(`${environment.apiRoot}${EXAM_MODEL_URL}/${modelId}`, orientationModel)
      .toPromise();
  }

  deleteById(modelId: string, prescriberId = '') {
    const params = prescriberId ? { prescriberId } : {};
    return this.http
      .delete(`${environment.apiRoot}${EXAM_MODEL_URL}/${modelId}`, { params })
      .toPromise();
  }
}
