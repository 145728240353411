import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CepService } from '@app/modules/entry/services/cep.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { BrazilState } from '@app/shared/models';
import { markFormGroup } from '@app/utils';
import { CreditCardValidators } from 'angular-cc-library';

@Component({
  selector: 'app-subscription-step',
  templateUrl: './subscription-step.component.html',
  styleUrls: ['./subscription-step.component.scss', '../../modal-telemedicine-contract.component.scss']
})
export class SubscriptionStepComponent implements OnInit {
  @Output() formChange = new EventEmitter<any>();

  @ViewChild('numberInput')
  numberInputRef: ElementRef;

  states: BrazilState[] = BrazilianStates;

  private _form: FormGroup;
  private lastCep: string;

  constructor(private fb: FormBuilder, private cepService: CepService) {
    this._form = this.fb.group({
      customer: this.fb.group({
        name: ['', Validators.required],
        identity: [''],
        phone: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        address: this.fb.group({
          street: ['', Validators.required],
          number: ['', Validators.required],
          district: ['', Validators.required],
          zipCode: ['', Validators.required],
          cityName: ['', Validators.required],
          stateInitials: ['', Validators.required],
          countryName: ['', Validators.required]
        })
      }),
      creditCard: this.fb.group({
        holder: ['', Validators.required],
        cardNumber: ['', CreditCardValidators.validateCCNumber],
        expirationDate: ['', CreditCardValidators.validateExpDate],
        securityCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]]
      })
    });
  }

  ngOnInit() {
    this._form.valueChanges.subscribe(value => this.formChange.emit(value));
  }

  @Input()
  set form(form: any) {
    if (form) {
      this._form.setValue(form);
    }
  }

  get form() {
    return this._form;
  }

  get billingDate() {
    const now = new Date();
    now.setDate(now.getDate() + 16);
    return now;
  }

  @nativeAsync
  async consultCep(value: string) {
    const cep = value.replace(/[^\d]+/g, '');
    if (cep.length === 8 && this.lastCep !== cep) {
      this.lastCep = cep;
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        const customer = this._form.get('customer');
        customer.get('address').patchValue({
          street: data.logradouro,
          number: null,
          district: data.bairro,
          zipCode: data.cep,
          cityName: data.localidade,
          stateInitials: data.uf,
          countryName: 'Brasil'
        });
        if (data.logradouro) {
          this.numberInputRef.nativeElement.focus();
        }
      }
    }
  }

  get invalid() {
    markFormGroup(this._form);
    return this._form.invalid;
  }

  get errorMessage() {
    return 'Você precisa preencher todos os campos obrigatórios';
  }

  public goToNextCreditCardField(controlName: string, nextField: HTMLInputElement) {
    const ctrl = this._form.get('creditCard').get(controlName);
    if (ctrl && ctrl.valid) {
      nextField.focus();
    }
  }
}
