export default class DocumentUtil {
  static getAttestationsDays() {
    return [
      { value: 'UM', label: '1 (hum) dia' },
      { value: 'DOIS', label: '2 (dois) dias' },
      { value: 'TRES', label: '3 (três) dias' },
      { value: 'QUATRO', label: '4 (quatro) dias' },
      { value: 'CINCO', label: '5 (cinco) dias' },
      { value: 'SEIS', label: '6 (seis) dias' },
      { value: 'SETE', label: '7 (sete) dias' },
      { value: 'OITO', label: '8 (oito) dias' },
      { value: 'NOVE', label: '9 (nove) dias' },
      { value: 'DEZ', label: '10 (dez) dias' },
      { value: 'ONZE', label: '11 (onze) dias' },
      { value: 'DOZE', label: '12 (doze) dias' },
      { value: 'TREZE', label: '13 (treze) dias' },
      { value: 'QUATORZE', label: '14 (quatorze) dias' },
      { value: 'QUINZE', label: '15 (quinze) dias' },
      { value: 'TRINTA', label: '30 (trinta) dias' },
      { value: 'QUARENTA_CINCO', label: '45 (quarenta e cinco) dias' },
      { value: 'SESSENTA', label: '60 (sessenta) dias' },
      { value: 'NOVENTA', label: '90 (noventa) dias' },
      { value: 'CENTO_OITENTA', label: '180 (cento e oitenta) dias' }
    ];
  }

  static getTypesOfActivity() {
    return [
      { value: 'LABORAL', label: 'Laborais' },
      { value: 'ESCOLAR', label: 'Escolares' },
      { value: 'LABORAL_ESCOLAR', label: 'Laborais e Escolares' }
    ];
  }
}
