import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export function NoConcurrent() {
  return (target, propertyKey: string, descriptor: PropertyDescriptor) => {
    // save a reference to the original method this way we keep the values currently in the
    // descriptor and don't overwrite what another decorator might have done to the descriptor.
    if (descriptor === undefined) {
      descriptor = Object.getOwnPropertyDescriptor(target, propertyKey);
    }
    const originalMethod: any = descriptor.value;

    // editing the descriptor/value parameter
    descriptor.value = function() {
      const prop: any = target[propertyKey];
      const args: any = arguments;

      if (prop.$$noConcurrent) {
        return prop.$$noConcurrent;
      }

      return new Observable(subscriber => {
        const obs: Observable<any> = originalMethod.apply(this, args);
        prop.$$noConcurrent = obs;
        obs
          .pipe(
            catchError((err: Response | any) => {
              // console.log('no concurrent: received error');
              delete prop.$$noConcurrent;
              subscriber.error(err);
              subscriber.complete();
              return of();
            })
          )
          .subscribe(res => {
            delete prop.$$noConcurrent;
            subscriber.next(res);
            subscriber.complete();
          });
      });
    };

    // return edited descriptor as opposed to overwriting the descriptor
    return descriptor;
  };
}
