import { Component, ViewChild } from '@angular/core';
import { PdfService } from '@app/modules/document/services/pdf.service';
import { UnsignedPrescriptionModalComponent } from '@app/modules/document/pages/prescription/unsigned-prescription-modal.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-upload-pdf',
  templateUrl: './upload-pdf.component.html',
  styleUrls: ['./upload-pdf.component.scss']
})
export class UploadPdfComponent {
  constructor(private pdfService: PdfService, private router: ActivatedRoute) {}

  @ViewChild(UnsignedPrescriptionModalComponent, { static: true })
  unsignedPrescriptionModal: UnsignedPrescriptionModalComponent;

  uploadFile(event) {
    const fileList: FileList = event.target.files;
    this.router.params.subscribe(async params => {
      const code = params['code'];
      if (fileList.length > 0) {
        const file: File = fileList[0];
        await this.pdfService.postFilePdf(file, code);
        this.unsignedPrescriptionModal.createTplModal(code);
      }
    });
  }
}
