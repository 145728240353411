import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-text-box-form',
  templateUrl: './text-box-form.component.html',
  styleUrls: ['./text-box-form.component.scss']
})
export class TextBoxFormComponent implements OnInit, OnDestroy {
  @Input() id: number;
  @Input() disabled = false;
  @Input() form: FormGroup;
  @Input() text: string;

  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() onClickRemove = new EventEmitter();
  @Output() onChangeText = new EventEmitter<string[]>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form.addControl('text', this.fb.control(this.text, Validators.required));
    this.listemText();
    if (this.text) {
      this.onChangeText.emit(this.text.split('\n'));
    }
    this.formEmitter.emit(this.form);
  }

  ngOnDestroy(): void {
    this.form.removeControl('text');
    this.formEmitter.emit(this.form);
  }

  listemText() {
    this.form
      .get('text')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((text: string) => {
        if (text && text.trim().length > 0) {
          const value = text.split('\n');
          this.onChangeText.emit(value);
        } else {
          this.form.get('text').setValue(null);
        }
      });
  }
}
