import { Address } from './address';
import { Pendency } from './pendency';

export class Buyer {
  _id: string;
  name: string;
  cpf: string;
  telephone: string;
  cellphone: string;
  identity: string;
  address: Address;
  createdAt: Date;
  pendency: Pendency;
}
