import { Injectable } from '@angular/core';
import { AdminService } from '@app/modules/admin/admin.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { HealthProfessional, Patient, Pharmacy } from '@app/shared/models';
import { Administrator } from '@app/shared/models/admin';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(
    private healthProfessionalService: HealthProfessionalService,
    private patientService: PatientsService,
    private pharmacyService: PharmacyService,
    private adminService: AdminService
  ) {}

  register(
    registerType: string,
    data: any
  ): Observable<HealthProfessional | Patient | Pharmacy | Administrator> {
    data.originHost = window.location.origin;

    switch (registerType) {
      case 'doctor':
      case 'dentist':
      case 'veterinarian':
        return this.healthProfessionalService.save(data);
      case 'pharmacy':
        return this.pharmacyService.save(data);
      case 'admin':
        return this.adminService.save(data);
      default:
        return this.patientService.save(data);
    }
  }
}
