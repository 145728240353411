import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { HistoricComponent } from '@app/modules/historic/historic.component';
import { AuthGuard } from '@app/shared/guards/auth.guard';
import { IsVeterinarianResolver } from '@app/shared/resolvers/is-veterinarian.resolver';
import { environment } from '@env/environment';
import { MyDataComponent } from './modules/dashboard/my-data/my-data.component';
import { RecipeComponent } from './modules/pharmacy/recipe/recipe.component';
import { SystemUpdateComponent } from './modules/system-update/system-update.component';
import { AppVersionComponent } from './shared/components/AppVersion/AppVersion.component';
import { EntryGuard } from './shared/guards/entry.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('@app/modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'maintenance',
    component: SystemUpdateComponent
  },
  {
    path: 'p/:code',
    loadChildren: () =>
      import('@app/modules/public-document/public-document.module').then(m => m.PublicDocumentModule)
  },
  {
    path: 'entry',
    canActivate: [EntryGuard],
    loadChildren: () => import('@app/modules/entry/entry.module').then(m => m.EntryModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('@app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'patients',
    canActivate: [AuthGuard],
    loadChildren: () => import('@app/modules/patients/patients.module').then(m => m.PatientsModule)
  },
  // {
  //   path: 'personalized-medications',
  //   canActivate: [AuthGuard],
  //   loadChildren:
  //     '@app/modules/personalized-medications/personalized-medications.module#PersonalizedMedicationsModule'
  // },
  {
    path: 'doctor',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@app/modules/health-professional/health-professional.module').then(
        m => m.HealthProfessionalModule
      )
  },
  {
    path: 'dentist',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@app/modules/health-professional/health-professional.module').then(
        m => m.HealthProfessionalModule
      )
  },
  {
    path: 'prescriber',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@app/modules/health-professional/health-professional.module').then(
        m => m.HealthProfessionalModule
      )
  },
  {
    path: 'prescription',
    canActivate: [AuthGuard],
    loadChildren: () => import('@app/modules/document/document.module').then(m => m.DocumentModule)
  },
  {
    path: 'document',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@app/modules/document-v2/document-v2.module').then(m => m.DocumentV2Module),
    resolve: {
      isVeterinarian: IsVeterinarianResolver
    }
  },
  {
    path: 'recipe',
    loadChildren: () =>
      import('@app/modules/external-document/external-document.module').then(
        m => m.ExternalDocumentModule
      )
  },
  {
    path: 'recipe-v2',
    loadChildren: () =>
      import('@app/modules/external-document-v2/external-document-v2.module').then(
        m => m.ExternalDocumentV2Module
      )
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: MyDataComponent
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('@app/modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'historic',
    canActivate: [AuthGuard],
    component: HistoricComponent
  },
  {
    path: 'dispensation',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@app/modules/dispensation/dispensation.module').then(m => m.DispensationModule)
  },
  {
    path: 'pharmacy/recipe',
    canActivate: [AuthGuard],
    component: RecipeComponent
  },
  {
    path: 'pharmacist',
    canActivate: [AuthGuard],
    loadChildren: () => import('@app/modules/pharmacist/pharmacist.module').then(m => m.PharmacistModule)
  },
  {
    path: 'favorites',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@app/modules/favorite-models/favorite-models.module').then(m => m.FavoriteModelsModule),
    resolve: {
      isVeterinarian: IsVeterinarianResolver
    }
  },
  {
    path: 'pharmacy/filter',
    loadChildren: () =>
      import('@app/modules/public-pharmacy/public-pharmacy.module').then(m => m.PublicPharmacyModule)
  },
  {
    path: 'policies',
    loadChildren: () => import('@app/modules/policies/redirect.module').then(m => m.RedirectModule)
  },
  {
    path: 'conection-pharma',
    loadChildren: () =>
      import('@app/modules/conection-pharma/conection-pharma.module').then(m => m.ConectionPharmaModule)
  },
  {
    path: 'version',
    component: AppVersionComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.config.forEach(route => {
      if (environment.maintenance && route.path !== 'maintenance') {
        route.redirectTo = 'maintenance';
      }
    });
  }
}
