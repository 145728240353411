import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit, OnDestroy {
  medicament: any;

  private destroyed$ = new ReplaySubject<boolean>();

  constructor(private fb: FormBuilder, private ref: NzModalRef, private notification: AppToastService) {}

  ngOnInit() {
    console.log(this.medicament);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  cancel() {
    this.ref.close();
  }

  remove(medicament: any) {
    medicament.checked = false;
    this.ref.close();
  }
}
