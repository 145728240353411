import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CoreService } from '@app/core/services/core.service';
import { PetPatientService } from '@app/modules/patients/services/pet-patient.service';
import { DateUtils } from '@app/shared/data/DateUtils';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { Race } from '@app/shared/models/races';
import { Species } from '@app/shared/models/species';
import { markFormGroup } from '@app/utils';

@Component({
  selector: 'app-modal-pet-form',
  templateUrl: './modal-pet-form.component.html',
  styleUrls: ['./modal-pet-form.component.scss']
})
export class ModalPetFormComponent implements OnInit {
  @Output() submit = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private petPatientService: PetPatientService,
    private coreService: CoreService
  ) {
    this.onResize();
  }

  loading = false;
  species: Species[];
  races: Race[];

  labelAge = 'Idade';

  form = this.fb.group({
    _id: [null],
    name: ['', Validators.required],
    species: ['', Validators.required],
    sex: ['', Validators.required],
    race: ['', Validators.required],
    dateOfBirth: ['', Validators.required],
    isApproximateAge: [],
    approximateAgeLabel: [
      {
        value: '',
        disabled: true
      }
    ]
  });

  isMobile = false;

  @nativeAsync
  async ngOnInit() {
    this.species = await this.petPatientService.getSpecies().toPromise();
    this.form.get('dateOfBirth').valueChanges.subscribe(dateOfBirth => {
      if (dateOfBirth) {
        const diffBetweenDate = DateUtils.diffBetweenDate(new Date(), dateOfBirth);

        this.form.get('approximateAgeLabel').setValue(diffBetweenDate.result);
      }
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = this.coreService.isViewPortMobile();
  }

  @nativeAsync
  async setSpecies(title: any) {
    const species = this.species.find(s => s.title === title);
    await this.getRaces(species._id);
  }

  @nativeAsync
  async getRaces(speciesId: string) {
    this.form.get('race').setValue('');
    this.races = await this.petPatientService.getRaces(speciesId).toPromise();
  }

  submitForm() {
    markFormGroup(this.form);

    if (this.form.valid) {
      this.submit.emit(this.form.value);
    }
  }

  get currentDate() {
    const now = new Date();
    return this.formatDate(now);
  }

  private formatDate(now: Date) {
    const dd = String(now.getDate()).padStart(2, '0');
    const mm = String(now.getMonth() + 1).padStart(2, '0');
    const yyyy = now.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  checkApproximateAge(value: boolean) {
    if (value) {
      this.labelAge = 'Idade Aproximada';
    } else {
      this.labelAge = 'Idade';
    }
  }
}
