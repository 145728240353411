import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isCompleteName'
})
@Injectable()
export class IsCompleteNamePipe implements PipeTransform {
  transform(name: string): boolean {
    return !!/^[a-záàâãéèêíïóôõöúçñ']+\s[a-záàâãéèêíïóôõöúçñ ']+$/gi.exec(name);
  }
}
