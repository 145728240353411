import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ExamService } from '@app/modules/document/services/exam.service';
import { ColorSetting } from '@app/shared/models/color-setting';
import { ExamBase } from '@app/shared/models/ExamBase';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-data-exam-form',
  templateUrl: './data-exam-form.component.html',
  styleUrls: ['./data-exam-form.component.scss']
})
export class DataExamFormComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() isVeterinarian = false;
  @Input() colorSetting: ColorSetting;

  @Output() formEmitter = new EventEmitter<FormGroup>();
  @Output() onClickRemoveCardDataExam = new EventEmitter();

  inputSearch = new FormControl('');
  isLoading = false;

  examsFiltereds = [];

  constructor(
    private fb: FormBuilder,
    private examService: ExamService,
    private notification: AppToastService
  ) {}

  ngOnInit(): void {
    this.listenSearchInput();

    this.formEmitter.emit(this.form);
  }

  ngOnDestroy() {
    this.examList.removeAt(0);
    this.formEmitter.emit(this.form);
  }

  get examList(): FormArray {
    return <FormArray>this.form.get('examList');
  }

  get exams(): FormArray {
    return <FormArray>this.examList.at(0).get('exams');
  }

  listenSearchInput() {
    this.inputSearch.valueChanges
      .pipe(
        filter((text: string) => text && text.length >= 3),
        tap(() => {
          this.isLoading = true;
          this.examsFiltereds = [];
        }),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(text => this.examService.findAllByQuery(text, this.isVeterinarian))
      )
      .subscribe(
        exams => {
          if (exams.length > 0) {
            this.examsFiltereds = exams;
          } else {
            this.notification.notify('warning', 'Não foi encontado exames com nome informado', '');
          }

          this.isLoading = false;
        },
        () => {
          this.notification.notify('error', 'Ocorreu um erro ao buscar exames', '');
          this.examsFiltereds = [];
          this.isLoading = false;
        }
      );
  }

  addExam(exam: ExamBase) {
    const hasDuplicatedExam = this.hasDuplicatedExam(exam.id);
    if (hasDuplicatedExam) {
      this.notification.notify('warning', 'Exame já adicionado', '');
      this.resetInputSearch();
      return;
    }

    const examGroup = this.fb.group({
      id: [exam.id],
      name: [exam.name],
      tuss: [exam.tuss]
    });

    this.exams.push(examGroup);
    this.resetInputSearch();
  }

  resetInputSearch() {
    this.inputSearch = new FormControl('');
    this.listenSearchInput();
  }

  removeExam(index: number) {
    this.exams.removeAt(index);
  }

  private hasDuplicatedExam(idExam: string) {
    const exams = (<FormArray>this.form.get('examList')).at(0).get('exams').value || [];
    return exams.some(exam => exam.id === idExam);
  }
}
