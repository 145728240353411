import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { CoreModule } from '@app/core';
import { HttpInterceptorService } from '@app/core/interceptors/http-interceptor.service';
import { SystemUpdateComponent } from '@app/modules/system-update/system-update.component';
import { SharedModule } from '@app/shared';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NZ_I18N, NgZorroAntdModule, en_US } from 'ng-zorro-antd';
import { AvatarModule } from 'ngx-avatar';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { AppComponent } from './app.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { ModalDependentFormComponent } from './modules/dashboard/modal-dependent-form/modal-dependent-form.component';
import { ModalEditAddressDataComponent } from './modules/dashboard/modal-edit-address-data/modal-edit-address-data.component';
import { ModalEditPersonalDataComponent } from './modules/dashboard/modal-edit-personal-data/modal-edit-personal-data.component';
import { ModalEditPharmacyCovidComponent } from './modules/dashboard/modal-edit-pharmacy-covid/modal-edit-pharmacy-covid.component';
import { MyDataComponent } from './modules/dashboard/my-data/my-data.component';
import { DispensationModule } from './modules/dispensation/dispensation.module';
import { ModalAlertComponent } from './modules/dispensation/modal-alert/modal-alert.component';
import { ModalDispentionComponent } from './modules/dispensation/modal-dispention/modal-dispention.component';
import { EntryModule } from './modules/entry/entry.module';
import { HistoricModule } from './modules/historic/historic.module';
import { SelectPrescriptionComponent } from './modules/model/select-prescription/select-prescription.component';
import { RecipeComponent } from './modules/pharmacy/recipe/recipe.component';
import { UserComponent } from './modules/user/user.component';

registerLocaleData(localePt, 'pt-BR');

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    ModalAlertComponent,
    ModalDependentFormComponent,
    ModalDispentionComponent,
    ModalEditAddressDataComponent,
    ModalEditPersonalDataComponent,
    ModalEditPharmacyCovidComponent,
    MyDataComponent,
    RecipeComponent,
    SelectPrescriptionComponent,
    SystemUpdateComponent,
    UserComponent
  ],
  imports: [
    AppRoutingModule,
    AvatarModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    SharedModule,
    DashboardModule,
    DispensationModule,
    EntryModule,
    FormsModule,
    HistoricModule,
    HttpClientModule,
    JwtModule.forRoot({ config: { tokenGetter } }),
    ModalModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgxMaskModule.forRoot(),
    NgxPhotoEditorModule,
    NgZorroAntdModule
  ],
  providers: [
    BsModalRef,
    NgbActiveModal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
