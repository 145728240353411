import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { Patient } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { PatientRecord } from '@app/shared/models/patient-record';
import { Tag } from '@app/shared/models/tag';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { PatientRecordService } from '../../services/patient-record.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';

@Component({
  selector: 'app-patient-record',
  templateUrl: './patient-record.component.html',
  styleUrls: ['./patient-record.component.scss']
})
export class PatientRecordComponent implements OnInit {
  @Input() patient: Patient;
  @Input() customStyle: any;

  visible = false;
  patientRecords: PatientRecord[] = [];
  recordsToMerge: PatientRecord[] = [];
  mainTagId: string;
  distinctTags: Tag[] = [];
  mergeConfirmation: boolean;

  tags: Tag[] = [];
  filteredTags: Tag[] = [];
  record = '';
  selectedTag: Tag;
  merging = false;
  loading = true;
  mergingRecords = false;
  page: number;
  total: number;
  limit = 10;

  @ViewChild('recordInput') recordInput: ElementRef;

  constructor(
    private authService: AuthService,
    private patientRecordService: PatientRecordService,
    private notification: AppToastService
  ) {}

  @nativeAsync
  async ngOnInit() {
    if (this.patient) {
      const [tags] = await Promise.all([
        this.patientRecordService.getTags().toPromise(),
        this.requestPatientRecords(1)
      ]);
      this.tags = tags;
    } else {
      this.tags = await this.patientRecordService.getTags().toPromise();
    }
  }

  @nativeAsync
  private async requestPatientRecords(paginationIndex: number, reset = false) {
    try {
      this.loading = true;
      const { docs, total } = await this.patientRecordService
        .getRecords(this.patient._id, paginationIndex, this.limit)
        .toPromise();
      if (reset) {
        this.patientRecords = docs;
      } else {
        this.patientRecords = this.patientRecords.concat(docs);
      }
      this.page = paginationIndex;
      this.total = total;
    } catch (err) {
      console.error(err);
    }

    this.loading = false;
  }

  get user(): User {
    return this.authService.user();
  }

  get tagsWithChildren() {
    return this.tags.filter(t => t.children.length);
  }

  get tagsWithoutChildren() {
    return this.tags.filter(t => !t.children.length);
  }

  @nativeAsync
  async onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight &&
      this.patientRecords.length < this.total
    ) {
      await this.requestPatientRecords(this.page + 1);
    }
  }

  @nativeAsync
  async refreshList() {
    await this.requestPatientRecords(1, true);
  }

  editRecord(record: PatientRecord) {
    this.patientRecordService.updateRecord(record);
  }

  showCheckbox(show: boolean) {
    this.merging = show;
  }

  @nativeAsync
  async mergeRecords(mainTagId: string) {
    try {
      this.mergingRecords = true;
      const recordCreated = await this.patientRecordService
        .mergeRecords(this.recordsToMerge, mainTagId)
        .toPromise();
      this.mergingRecords = false;
      this.mergeConfirmation = false;
      this.showCheckbox(false);
      this.patientRecords.unshift(recordCreated);
      await this.refreshList();
    } catch (err) {
      this.mergingRecords = false;
      console.error(err);
    }
  }

  addNewRecordToMerge(data) {
    const { record, event } = data;
    if (event) {
      this.recordsToMerge.push(record);
    } else {
      const index = this.recordsToMerge.map(r => r.id).indexOf(record.id);
      this.recordsToMerge = this.recordsToMerge.splice(index, 1);
    }
  }

  changeMergeConfirmation() {
    this.distinctTags = this.recordsToMerge
      .map(r => r.tag)
      .filter(t => !!t)
      .reduce((acc, tag) => {
        if (!acc.find(t => t.id === tag.id)) {
          acc.push(tag);
        }
        return acc;
      }, []);

    if (this.distinctTags.length === 1) {
      this.mergeRecords(this.distinctTags[0].id);
    } else {
      this.mergeConfirmation = !this.mergeConfirmation;
    }
  }

  cancelMergeSelection() {
    this.showCheckbox(false);
  }

  cancelMerge() {
    this.mergeConfirmation = !this.mergeConfirmation;
  }

  onChangeTag(tag: Tag) {
    if (tag.parentId) {
      this.selectChild(tag.id);
    }
  }

  @nativeAsync
  async submitRecord() {
    try {
      const form = {
        patientId: this.patient._id,
        content: this.record,
        tag: this.selectedTag && this.selectedTag.id
      };
      this.loading = true;
      const recordCreated = await this.patientRecordService.submitRecord(form).toPromise();
      this.patientRecords.unshift(recordCreated);
    } catch (err) {
      console.error(err);
      this.notification.notify('error', 'Erro', 'Não foi possível salvar o registro');
    }
    this.reset();
  }

  extractDataFromRecord() {
    if (!this.record.includes(':')) {
      return { undefined, record: this.record };
    }
  }

  setSelectedTag(tag: Tag) {
    this.selectedTag = tag;
    this.record = `#${tag.name}: `;
  }

  @nativeAsync
  async selectChild(tagId: string) {
    try {
      const record = {
        patientId: this.patient._id,
        content: '',
        tag: tagId,
        editable: false
      };
      const recordCreated = await this.patientRecordService.submitRecord(record).toPromise();
      this.patientRecords.unshift(recordCreated);
    } catch (err) {
      console.error(err);
      this.notification.notify('error', 'Erro', 'Não foi possível salvar o registro');
    }
    this.reset();
  }

  private reset() {
    this.loading = false;
    this.record = '';
    this.selectedTag = undefined;
    this.recordInput.nativeElement.value = '';
  }
}
