import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColorSetting } from '@app/shared/models/color-setting';
import { typeOfDocuments } from '@app/shared/types/types';

@Component({
  selector: 'app-add-type-options',
  templateUrl: './add-type-options.component.html',
  styleUrls: ['./add-type-options.component.scss']
})
export class AddTypeOptionsComponent {
  @Output() onClickAddIndustrialized = new EventEmitter();
  @Output() onClickAddMedicamentFreeText = new EventEmitter();
  @Output() onClickAddDataExams = new EventEmitter();
  @Output() onClickAddTextBox = new EventEmitter();
  @Output() onClickonShowModelListFavoriteDocument = new EventEmitter();
  @Output() onClickShowCardCID = new EventEmitter<boolean>();
  @Output() onClickAddViewFormAttestation = new EventEmitter();
  @Output() onClickAddPrecriptionRenew = new EventEmitter();
  @Output() onClickAddInfusion = new EventEmitter();

  @Input() viewIndutrialized = false;
  @Input() viewMedicmanetFreeText = false;
  @Input() viewDataExams = false;
  @Input() viewTextBox = false;
  @Input() viewListFavoriteDocument = false;
  @Input() viewShowCardCID = false;
  @Input() viewFormAttestation = false;
  @Input() viewPrecriptionRenew = false;
  @Input() viewInfusion = false;
  @Input() colorSetting: ColorSetting;

  @Input() typeDocument: typeOfDocuments;

  hover = {};

  isVeterinarian = false;

  constructor(private activatedRoute: ActivatedRoute) {
    this.isVeterinarian = this.activatedRoute.snapshot.data['isVeterinarian'];
  }
}
