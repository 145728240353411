import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';

export interface AppState {
  isRennova: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class StateService {
  removeAppState() {
    localStorage.removeItem('state');
  }

  set data(appState: AppState) {
    const stateHash = Buffer.from(JSON.stringify(appState)).toString('base64');
    localStorage.setItem('state', stateHash);
  }

  get data(): AppState {
    const stateHash = localStorage.getItem('state');
    if (!stateHash) {
      return;
    }
    const state = Buffer.from(stateHash, 'base64').toString('ascii');
    return JSON.parse(state) as AppState;
  }
}
