import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Buyer } from '@app/shared/models/buyer';
import { environment } from '@env/environment';
import { Observable, throwError, pipe } from 'rxjs';

const BUYER_URL = 'buyer';

@Injectable({ providedIn: 'root' })
export class BuyerService {
  constructor(private http: HttpClient) {}

  public getBuyerByCpf(cpf: string): Observable<Buyer> {
    return this.http.get<Buyer>(`${environment.apiRoot}${BUYER_URL}/${cpf}`);
  }

  save(buyer: Buyer): Observable<Buyer> {
    return this.http.post<Buyer>(`${environment.apiRoot}${BUYER_URL}`, buyer);
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);

    return throwError(errorMessage);
  }
}
