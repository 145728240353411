import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { DataBigboostConsult } from '@app/shared/models/paciente';
import { AppToastService } from '@app/shared/services/app-toast.service';

@Component({
  selector: 'app-underage-patient-form',
  templateUrl: './underage-patient-form.component.html',
  styleUrls: ['./underage-patient-form.component.scss']
})
export class UnderagePatientFormComponent implements OnInit {
  @Input() mothersName: string;
  @Input() patient: DataBigboostConsult;
  @Input() hideCpf = false;
  @Output() formChange = new EventEmitter<FormGroup>();

  @ViewChild('repeatName', { static: true })
  repeatNameCheckbox: any;

  form = this.fb.group({
    name: ['', [Validators.required, ValidationService.nomeValidator]],
    fullname: ['', [Validators.required, ValidationService.nomeValidator]],
    sex: ['', Validators.required],
    dateOfBirth: [null, [Validators.required, UnderagePatientFormComponent.validateUnderAge]],
    membership: this.fb.group({
      mothersName: ['', Validators.required]
    }),
    pendency: [null],
    verifiedCpf: [false]
  });

  static validateUnderAge(control: FormControl): { [key: string]: boolean } | null {
    const birthdate = new Date(control.value);
    const diffDate = Date.now() - birthdate.getTime();
    const ageDate = new Date(diffDate);
    const years = Math.abs(ageDate.getUTCFullYear() - 1970);
    if (years >= 18) {
      return { isNotMinor: true };
    }

    return null;
  }

  constructor(private fb: FormBuilder, private notification: AppToastService) {}

  ngOnInit() {
    this.initForm();
    if (this.patient) {
      this.handleConsultCpf(this.patient);
    }
    this.formChange.emit(this.form);
  }

  private initForm() {
    if (!this.hideCpf) {
      this.form.addControl(
        'cpf',
        this.fb.control('', [Validators.required, ValidationService.cpfValidator])
      );
    }

    this.form.get('fullname').valueChanges.subscribe(fullname => {
      if (this.repeatNameCheckbox && this.repeatNameCheckbox.nzChecked) {
        if (!fullname) {
          this.repeatNameCheckbox.nzChecked = false;
          this.form.get('name').enable();
        }
        this.form.get('name').setValue(fullname);
      }
    });
  }

  private handleConsultCpf(consult: DataBigboostConsult) {
    if (consult.status === 1) {
      this.form.get('pendency').setValue(null);
      this.setForm(consult);
      this.disableForm();
    } else {
      this.form.get('pendency').setValue(consult);
      if (consult.erro) {
        this.notification.notify('warning', 'Aviso', consult.erro);
      }
    }
  }

  private setForm(consult: DataBigboostConsult) {
    this.form.get('name').setValue(consult.nome);
    this.form.get('fullname').setValue(consult.nome);
    this.form.get('cpf').setValue(consult.cpf);
    this.form.get('sex').setValue(consult.genero);
    this.form.get('membership.mothersName').setValue(consult.mae);
    const date = consult.nascimento.split('/');
    const dateOfBirth = new Date(`${date[1]}/${date[0]}/${date[2]}`);
    this.form.get('dateOfBirth').setValue(dateOfBirth.toISOString().substring(0, 10));
  }

  private disableForm() {
    this.form.get('fullname').disable();
    this.form.get('cpf').disable();
    this.form.get('sex').disable();
    this.form.get('membership.mothersName').disable();
    this.form.get('dateOfBirth').disable();
  }

  get currentDate() {
    const now = new Date();
    const dd = String(now.getDate()).padStart(2, '0');
    const mm = String(now.getMonth() + 1).padStart(2, '0');
    const yyyy = now.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  checkRepeatName(value: boolean) {
    if (value) {
      this.form.get('name').setValue(this.form.get('fullname').value);
      this.form.get('name').disable();
    } else {
      this.form.get('name').enable();
    }
  }

  checkMothersName(value: boolean) {
    if (value) {
      this.form.get('membership.mothersName').setValue(this.mothersName);
      this.form.get('membership.mothersName').disable();
    } else {
      this.form.get('membership.mothersName').enable();
    }
  }

  addResponsibleForm(responsibleForm: FormGroup) {
    this.form.addControl('responsible', responsibleForm);
  }
}
