import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { HealthProfessional, Patient } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { PetPatient } from '@app/shared/models/pet-patient';
import { ProfessionalType } from '@app/shared/models/professional-type.enum';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PatientsService } from '../../services/patients.service';
import { PetPatientService } from '../../services/pet-patient.service';
import { ModalEditPatientComponent } from '../modal-edit-patient/modal-edit-patient.component';
import { ModalEditPetComponent } from '../modal-edit-pet/modal-edit-pet.component';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss']
})
export class PatientDetailsComponent implements OnInit {
  @Output() close = new EventEmitter<boolean>();
  @Output() update = new EventEmitter<Patient | PetPatient>();

  tplModal: NzModalRef;

  @ViewChild('tplTitle', { static: true })
  tplTitle: TemplateRef<{}>;

  @ViewChild('tplContent', { static: true })
  tplContent: TemplateRef<{}>;

  @ViewChild('tplFooter', { static: true })
  tplFooter: TemplateRef<{}>;

  patient: Patient;
  pet: PetPatient;
  responsiblePets: PetPatient[] = [];
  prescriber: HealthProfessional;

  private patientsCache = new Map<string, boolean>();
  private petsCache = new Map<string, boolean>();
  patientRecordStyle = {
    recordsArea: { height: '25vh' },
    input: { height: '95px', 'border-radius': '12px' }
  };

  loading = false;

  constructor(
    private modalService: NzModalService,
    private bsModalService: BsModalService,
    private notification: AppToastService,
    private authService: AuthService,
    private patientsService: PatientsService,
    private petPatientService: PetPatientService,
    private healthProfessionalService: HealthProfessionalService
  ) {}

  ngOnInit() {}

  get user(): User {
    return this.authService.user();
  }

  get isVeterinarian(): boolean {
    return this.prescriber.professionalType === ProfessionalType.VETERINARIAN;
  }

  @nativeAsync
  async createTplModal(patient: any, prescriber: HealthProfessional) {
    if (prescriber.professionalType === ProfessionalType.VETERINARIAN) {
      this.pet = patient;
    } else {
      this.patient = patient;
    }

    this.prescriber = prescriber;

    this.tplModal = this.modalService.create({
      nzTitle: this.tplTitle,
      nzContent: this.tplContent,
      nzFooter: null,
      nzClosable: true,
      nzWidth: 828,
      nzMaskClosable: true,
      nzOnCancel: () => (this.patient = null),
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () => console.log('Click ok')
    });

    if (this.isVeterinarian) {
      this.responsiblePets = await this.petPatientService
        .getPetsByResponsibleCpf(this.pet.responsible.cpf)
        .toPromise();

      if (this.responsiblePets) {
        this.responsiblePets.forEach(({ _id: id }) => {
          this.hasPet(id);
        });
      }
    } else {
      if (this.patient.dependents) {
        this.patient.dependents.forEach(({ _id: id }) => {
          this.hasPatient(id);
        });
      }
    }
  }

  private hasPatient(id: string) {
    this.healthProfessionalService.getPatientsById(id, this.user._id).subscribe(
      p => this.patientsCache.set(id, true),
      err => {
        if (err.status === 404) {
          this.patientsCache.set(id, false);
        } else {
          console.error(err);
        }
      }
    );
  }

  private hasPet(id: string) {
    this.healthProfessionalService.getPetsById(id, this.user._id).subscribe(
      p => {
        this.petsCache.set(id, true);
      },
      err => {
        if (err.status === 404) {
          this.petsCache.set(id, false);
        } else {
          console.error(err);
        }
      }
    );
  }

  get hasPatientRecordFeature() {
    return this.user && this.user.features.find(f => f.name === 'Prontuário');
  }

  destroyTplModal(): void {
    this.tplModal.destroy();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.destroyTplModal();
  }

  get hasAccount() {
    return (
      this.patient && this.patient.user && !this.patient.user.activationToken && this.patient.user.email
    );
  }

  get popoverContent() {
    return this.hasAccount
      ? 'O(a) paciente já ativou a conta na plataforma! Apenas algumas informações poderão ser alteradas por você. ' +
          'Se precisar de ajuda, entre em contato com nosso atendimento em receitadigital.com'
      : 'Clique no lápis para alterar os dados do paciente. ' +
          'Esta ação só será possível se o paciente ainda não tiver ativado a conta na plataforma.';
  }

  getAddress() {
    let address = null;
    if (this.patient && this.patient.address) {
      address = this.patient.address;
    } else if (this.patient && this.patient.responsible.address) {
      address = this.patient.responsible.address;
    } else if (this.pet.responsible.address) {
      address = this.pet.responsible.address;
    }

    if (!address) {
      return '';
    }
    const addr = [address.street, address.number, address.complement, address.neighborhood, address.city]
      .filter(a => a)
      .join(', ');

    return addr + (address.uf ? (address.city ? '-' : ', ') + address.uf : '');
  }

  hasAddress() {
    return (
      (this.patient && (this.patient.address || this.patient.responsible.address)) ||
      (this.pet && this.pet.responsible.address)
    );
  }

  hasDependent(patient: Patient) {
    return this.patientsCache.get(patient._id);
  }

  hasPetDependent(pet: PetPatient) {
    return this.petsCache.get(pet._id);
  }

  addPatient(patient: Patient) {
    this.healthProfessionalService.addPatient(this.prescriber._id, patient._id).subscribe(_ => {
      this.hasPatient(patient._id);
      this.update.emit();
    });
  }

  removePatient(patient: Patient) {
    this.healthProfessionalService.removePatient(this.prescriber._id, patient._id).subscribe(_ => {
      this.hasPatient(patient._id);
      this.update.emit();
    });
  }

  showEditPet(pet: PetPatient, isResponsible = false) {
    const initialState = { pet, isVeterinarian: this.isVeterinarian };

    const modal = this.bsModalService.show(ModalEditPetComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false
    });
    modal.content.submit.subscribe(async (p: PetPatient) => {
      this.loading = true;
      const saved = await this.submitPetForm(p);
      this.patient = Object.assign(new Patient(), saved);
      this.loading = false;
    });
  }

  @nativeAsync
  private async submitPetForm(patient: PetPatient) {
    try {
      const saved = await this.petPatientService.update(patient._id, patient).toPromise();
      this.update.emit(saved);
      this.notification.notify('success', 'Sucesso', 'Paciente salvo com sucesso');
      return saved;
    } catch (err) {
      console.error(err);
      this.notification.notify('error', 'Erro', 'Erro ao salvar paciente');
      return null;
    }
  }

  showEditPatient(isResponsible = false) {
    let patient = this.patient || this.pet;
    if (isResponsible) {
      if (this.patient) {
        patient = this.patient.responsible;
      } else {
        patient = this.pet.responsible;
      }
    }

    const initialState = { patient, isVeterinarian: this.isVeterinarian };

    const classModal = this.isDependent && !isResponsible ? '' : 'modal-lg';

    const modal = this.bsModalService.show(ModalEditPatientComponent, {
      initialState,
      class: classModal,
      backdrop: 'static',
      keyboard: false
    });
    modal.content.submit.subscribe(async (p: Patient) => {
      this.loading = true;
      const saved = await this.submitForm(p);
      if (isResponsible) {
        if (this.patient) {
          this.patient.responsible = saved;
        } else {
          this.pet.responsible = saved;
        }
      } else {
        this.patient = saved;
      }
      this.loading = false;
    });
  }

  get isDependent() {
    return Boolean((this.patient && this.patient.responsible) || (this.pet && this.pet.responsible));
  }

  @nativeAsync
  async addPetPatient(pet: PetPatient) {
    try {
      await this.healthProfessionalService.addPetPatient(pet._id).toPromise();
      this.hasPet(pet._id);
      this.update.emit();
    } catch (err) {
      console.error(err);
    }
  }

  @nativeAsync
  private async submitForm(patient: Patient) {
    try {
      const saved = await this.patientsService.update(patient._id, patient).toPromise();
      this.update.emit(saved);
      this.notification.notify('success', 'Sucesso', 'Paciente salvo com sucesso');
      return saved;
    } catch (err) {
      console.error(err);
      this.notification.notify('error', 'Erro', 'Erro ao salvar paciente');
      return null;
    }
  }
}
