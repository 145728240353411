import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CrService } from '@app/modules/document/services/cr.service';
import { MedicationCr } from '@app/shared/models/medication-cr';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-input-search-medicaments',
  templateUrl: './input-search-medicaments.component.html',
  styleUrls: ['./input-search-medicaments.component.scss']
})
export class InputSearchMedicamentsComponent implements OnInit {
  @Output() onMedicamentSelected = new EventEmitter<MedicationCr>();

  @Input() set valueInputSearch(value: string) {
    this.inputSearch.setValue(value);
  }

  isLoading = false;
  medicaments: MedicationCr[] = [];
  inputSearch = new FormControl();

  medicamentSelected: MedicationCr;

  constructor(private crService: CrService, private notification: AppToastService) {}

  ngOnInit(): void {
    this.inputSearch.valueChanges
      .pipe(
        filter((text: string) => text && text.length >= 3),
        tap(() => {
          this.isLoading = true;
          this.medicaments = [];
        }),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(text => this.crService.searchMedicament(text))
      )
      .subscribe(medicaments => {
        if (medicaments.length > 0) {
          this.medicaments = medicaments;
        } else {
          this.notification.notify(
            'warning',
            'Não foi encontrado medicamento com nome ou princípio ativo informado!',
            ''
          );
        }

        this.isLoading = false;
      });
  }

  selectEvent(medication: MedicationCr) {
    if (medication) {
      this.medicamentSelected = medication;
      this.onMedicamentSelected.next(medication);
    }
  }
}
